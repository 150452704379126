const suggestedActionsData = [
  "Normal sinus Rhythm",
  "Sinus Bradycardia",
  "Sinus Tachycardia",
  "Sinus Arrhythmia",
  "Supraventricular tachycardia",
  "Atrial Fibrillation",
  "Atrial Flutter",
  "First degree AV block",
  "Mobitz type 1 AV Block",
  "Mobitz type II AV block",
  "Third degree complete block",
  "Ventricular Tachycardia",
  "PAC",
  "VPC",
  "Junctional Rhythms",
  "Ventricular couplets",
  "Anterolateral MI",
  "Anteroseptal MI",
  "Antero- apical MI",
  "Anterior wall MI",
  "Inferior MI",
  "Infero lateral MI",
  "Infero septal MI",
  "Lateral Wall MI",
  "LBBB",
  "RBBB",
  "Ischemia",
  "RVH",
  "WPW syndrome",
];

export default suggestedActionsData;
