const stemiTextData = [
    "Left Bundle Branch Block (LBBB)",
    "Right Bundle Branch Block (RBBB)",
    "Anterior Wall Myocardial Infarction (MI)",
    "Antero-septal Myocardial Infarction (MI)",
    "Antero-apical Myocardial Infarction (MI)",
    "Inferior Wall Myocardial Infarction (MI)",
    "Infero-lateral Wall Myocardial Infarction (MI)",
    "Lateral Wall Myocardial Infarction (MI) Detected",
    "Ischemia",
    "Left Ventricular Hypertrophy(LVH)",
    "ST-T Abnormality"
]

export default stemiTextData;