import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { getDeviceMonthlyCount } from 'api/device';

const BarChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await getDeviceMonthlyCount();
      setData(response?.data||[]);
    }
    fetchData();
  }, []);

  const devices = data.map(item => item.name);
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
  const series = devices.map(device => {
    return {
      name: device,
      data: data.find(item => item.name === device)?.data ?? Array(12).fill(0)
    };
  });

  const options = {
    chart: {
      type: 'bar',
      height: 400,
      stacked: true,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: months,
      title: {
        text: 'Month'
      },
    },
    yaxis: {
      title: {
        text: 'Total Tests'
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      offsetY: 40,
    },
    grid: {
      show: false  
    }
  };

  return (
    <div>
      <Chart options={options} series={series} type="bar" height={400} />
    </div>
  );
};

export default BarChart;
