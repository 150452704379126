

export const notes = {
    "default1": "ECG Interpreted by ECG Expert is the primary diagnosis of cardiovascular health. The Spandan ECG experts interprets the ECG reports based on the Theories and principles of Electrocardiography. CoS- relate the interpretation with symptoms and do consult your physician for a detailed diagnosis. This is Electronically authenticated report, hence doesn't require signature. Software calculated values to be verified manually.",
    "default": "Medical advice asked and accepted on the internet (without physically examining the patient) is at patient’s own risk. Visit a doctor in case of emergency."
};

export const signatures_offline = [
    {   
        name: "Spandan Expert",
        value: "default.png",
    },
    { 
        name: "Dr. Christianez Ratna",
        value: "dr-christianez-ratna.png",
        default: true,
    }
];

export const signatures_live = [
    {   
        name: "Spandan Expert",
        value: "default.png",
        default: true,
    },
    { 
        name: "Dr. Christianez Ratna",
        value: "dr-christianez-ratna.png"
    }
];
export const BASE_URL = `${process.env.REACT_APP_API_KEY}`

