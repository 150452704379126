import { Icon, Button } from "@mui/material";
import { primary46 } from "../utils/colors";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

export default function PagingControl({ totalPages, pageNum, setPageNum }) {
  const styles = {
    container: {
      // marginTop: 8,
      // marginBottom: 8,
    },
    inlineFlex: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    pageInfo: {
      padding: 4,
      color: primary46,
      fontSize: 14,
    }
  }
  return (
    <div style={styles.container}>
      <div style={styles.inlineFlex}>
        <Button icononly onClick={() => setPageNum(pageNum - 1)} disabled={pageNum === 0} startIcon={<ArrowCircleLeftIcon/>}>
        </Button>
        <div style={styles.pageInfo}>
          Page: {pageNum + 1}/{totalPages}
        </div>
        <Button icononly  onClick={() => setPageNum(pageNum + 1)} disabled={pageNum + 1 === totalPages } endIcon={<ArrowCircleRightIcon/>}>
        </Button>
      </div>
    </div>
  );
}