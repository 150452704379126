import { Helmet } from 'react-helmet-async';
// @mui
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// Others
import DropZone from 'layouts/select-pdf/selectPdfLayout';

// ----------------------------------------------------------------------
export default function BlogPage() {
  return (
    <>
      <Helmet>
        <title>{ `Dashboard: Blog | ${ process.env.REACT_APP_PORTAL_NAME }` }</title>
      </Helmet>
      <Container>
        <Grid container spacing={3}>
         <DropZone/>
        </Grid>
      </Container>
    </>
  );
}
