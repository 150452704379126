
// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import {Card, Grid, Typography, Chip} from "@mui/material";

function PatientList(props) {
  const renderProfiles = props.profiles.map((jsonData, index) => (
    <Grid key={jsonData["id"]} component="li" display="flex" alignItems="center" py={1} mb={1} onClick={(e, option) => props.handlePatientListClick(e,index)}>
      {/* <Grid mr={2}>
        <SoftAvatar src={image} alt="something here" variant="rounded" shadow="md" />
      </Grid> */} 
      <Grid
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <Typography variant="button" fontWeight="medium">
          {jsonData["FirstName"]}
        </Typography>
        <Typography variant="caption" color="text">
          {jsonData.Age}
        </Typography>
      </Grid>
      <Grid ml="auto">
        <Typography >
        <Chip {...(jsonData.Status === "completed" ? 
          { label: jsonData["Status"], color: "success" } : { label: jsonData["Status"]})} size="small" />
        </Typography>
      </Grid>
    </Grid>
  ));

  return (
    <div>
      <Typography variant="h6" fontWeight="medium" textTransform="capitalize">
        {props.title}
      </Typography>
      <div style={{ height: "100vh", overflow: "auto" }}>
        <Card>
          <Grid p={2}>
            <Grid component="ul" display="flex" flexDirection="column" p={0} m={0}>
              {renderProfiles}
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

// Typechecking props for the PatientList
PatientList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PatientList;
