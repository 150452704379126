
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchInvoiceData, fetchClientPdf } from 'api/users';
import CircularProgress from '@mui/material/CircularProgress';
import useResponsive from '../hooks/useResponsive';
import { Helmet } from 'react-helmet-async';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { downloadFromURI } from 'utils/Utils';

import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableHead,
    TableContainer,
    TablePagination,
    Box
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';
import selectPdf from 'layouts/select-pdf';

export default function InvoiceHistory() {
    const navigate = useNavigate();

    const location = useLocation();
    const { data } = location.state || {};
    const [row, setrow] = useState([]);
    const [loading, setLoading] = useState(true);
    const isLg = useResponsive('up', 'md');
  
    useEffect(() => {
        fetchData();
    }, []);


    const fetchData = async () => {
        
        const response = await fetchInvoiceData({
            business_id: data?.api_key
        });
        if (response?.status == 200) {
            setLoading(false)
            setrow(response?.data)
        }
    };

    const fetchPdf = (invoiceUrl,invoiceNumber) => async (e) => {
        e.preventDefault();
        const response = await fetchClientPdf({
            invoiceUrl: invoiceUrl
        })
        downloadFromURI(`data:application/pdf;base64,${response.data}`, `${invoiceNumber}.pdf`)
    }

    return (
        <>
            <Helmet>
                <title> Invoice History</title>
            </Helmet>

            <Container sx={{ marginTop: (isLg ? 0 : 1) }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography variant="h4" gutterBottom>
                        Invoice History
                    </Typography>
                    <Button variant="contained" sx={{ width: '100px' }} onClick={() => navigate(`${process.env.REACT_APP_HOMEPAGE}dashboard/finance`)} ><ArrowBackIcon sx={{width:'18px'}} />Back</Button>
                </Stack>

                <TableContainer component={Paper}>
                    <Table sx={{ maxWidth: '100%', margin: 'auto' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Invoice Number</TableCell>
                                <TableCell align="left">Generated On</TableCell>
                                <TableCell align="right">Billing Period</TableCell>
                                <TableCell align="right">Amount</TableCell>
                                <TableCell align="right">PDF</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        <CircularProgress />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                row?.length > 0 ? row.map((data, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell align="left">{data?.invoice_number}</TableCell>
                                        <TableCell align="left" component="th" scope="row">
                                            {data?.generated_on}
                                        </TableCell>
                                        <TableCell align="right">
                                            {`${dayjs(data?.billing_period?.start_date).format('DD/MM/YYYY')} - ${dayjs(data?.billing_period?.end_date).format('DD/MM/YYYY')}`}
                                        </TableCell>
                                        <TableCell align="right">{data?.amount}</TableCell>
                                        <TableCell align="right">
                                            <a href="#" onClick={fetchPdf(data?.invoice_url,data?.invoice_number)}>
                                                View PDF
                                            </a>
                                        </TableCell>
                                    </TableRow>
                                )) : (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            No data
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Container>

        </>
    );

}






