import Draggable from "react-draggable";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useState, useEffect, useRef } from "react";
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Icon from "@mui/material/Icon";
import { cleanBorder, errorColor, goodColor, primary45 } from "../utils/colors";

export default function DraggableText({ onEnd, onSet, onCancel, initialText }) {
  const [text, setText] = useState("Text");
  const inputRef = useRef(null);

  useEffect(() => {
    if (initialText) {
      setText(initialText)
    } else {
      inputRef.current.focus();
      inputRef.current.select()
    }
  }, [])

  const styles = {
    container: {
      position: "absolute",
      zIndex: 100000,
      border: `2px solid ${primary45}`,
    },
    controls: {
      position: "absolute",
      right: 2,
      display: "inline-block",
      // backgroundColor: primary45,
      // borderRadius: 4,
    },
    smallButton: {
      display: "inline-block",
      cursor: "pointer",
      padding: 4,
    },
    input: {
      border: 0,
      fontSize: 14,
      padding: 10,
      backgroundColor: 'rgba(0,0,0,0)',
      cursor: 'move'
    }
  };
  return (
    <Draggable onStop={onEnd}>
      <div style={styles.container}>
        <div style={styles.controls}>
          <Button variant="gradient" size="small" color="info" icononly onClick={() => onSet(text)}>
            <Icon>check</Icon>
          </Button>
          &nbsp;
          <Button variant="gradient" size="small" color="error" icononly onClick={onCancel}>
            <Icon>close</Icon>
          </Button>
          {/* <div style={styles.smallButton} onClick={() => onSet(text)}>
            <FaCheck color={goodColor} />
          </div> */}
          {/* <div style={styles.smallButton} onClick={onCancel}>
            <FaTimes color={errorColor} />
          </div> */}
        </div>
        <input
          ref={inputRef}
          style={styles.input}
          value={text}
          placeholder={'Text'}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
    </Draggable>
  );
}
