import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import Label from 'components/label/Label';
// @mui
import {
  Card,
  Box,
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableFooter,
  Tooltip,
  Link,
  Button,
  Divider,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemText,
  ListItemIcon

} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { useTheme } from '@mui/material/styles';

// components
import Scrollbar from '../components/scrollbar';

import { styled } from '@mui/system';
import { useSnackbar } from '../components/snackbar';

import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { Add, Close, Edit } from '@mui/icons-material';
import { getExisitingPlanUsers, getSubscriptionPlan } from 'api/users';
import { copyToClipboard } from 'utils/Utils';
import InvoiceDetails from './InvoiceDetails';
import InvoiceHistory from './InvoiceHistory';
import { useNavigate } from 'react-router-dom';
import { CardActionArea, CardActions } from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)(() => ({
  maxWidth: 150, // percentage also works
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const style = {
  py: 0,
  width: '100%',
  maxWidth: 360,
  borderRadius: 2,
  border: '1px solid',
  borderColor: 'divider',
  backgroundColor: 'background.paper',
};

export default function SubscriptionPlans() {

  const [subscriptionPlan, setSubscriptionPlan] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [planUserCounts, setPlanUserCounts] = useState({});

  useEffect(() => {


    fetchSubscriptionPlan();


  }, [])

  const fetchSubscriptionPlan = async () => {
  
    const response = await getSubscriptionPlan({});
    const response2 = await getExisitingPlanUsers({ status:'active', type:'' });
    
    setSubscriptionPlan(response?.data || [])
    const counts = (response2.data).reduce((acc, user) => {
      acc[user.plan_name] = (acc[user.plan_name] || 0) + 1;
      return acc;
    }, {});

    setPlanUserCounts(counts);
    setSubscriptionPlan(response?.data || [])
    setLoading(false);
   
  }

  const handleCardClick = async (row) => {

    setSelectedPlan(row);
    setIsDialogOpen(true);

  }

  const handleDialogClose = async () => {
    setIsDialogOpen(false);
    setSelectedPlan(null);
  }


  return (<>

    <Helmet>
      <title>Subscription Plans</title>
    </Helmet>
    <Container>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Subscription Plans
        </Typography>

      </Box>
      
      <Box>
      {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) :(
     
      <Box>
        {subscriptionPlan.length ? (

          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', gap: 4 }}>
            {subscriptionPlan?.map((row, index) => (
              <Card sx={{ maxWidth: 345 ,display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} onClick={() => handleCardClick(row)}>
                <React.Fragment key={index}>
                  <CardActionArea>
                    <CardContent>
                      <Stack  direction="row" justifyContent="space-between" alignItems="center">
                      <Typography gutterBottom variant="h5" component="div">
                        {row?.plan_name}
                      </Typography>
                      <Typography variant="subtitle1" color="green">
                            Active Users: {planUserCounts[row?.plan_name] || 0}
                          </Typography>
                          </Stack>
                      <Typography variant="body2" color="text.secondary">
                        {row?.plan_feature}
                      </Typography>
                     
                    </CardContent>
                    
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" onClick={() => { handleCardClick(row) }} >
                      View Details
                    </Button>
                  </CardActions>
                </React.Fragment>

              </Card>))} </Box>) : "No subscription plan"}
      </Box>)}
      </Box>

    </Container>

    <Dialog open={isDialogOpen} onClose={handleDialogClose} maxWidth="sm" fullWidth sx={{ '& .MuiDialog-paper': { height: '80vh' } }}>
      {selectedPlan && (
        <>


          <Stack direction="column" justifyContent="space-between" alignItems="start" paddingLeft='24px' >


            <Typography variant="h4" component="div" sx={{ paddingTop: '20px' }}>
              {selectedPlan.plan_name}
            </Typography>
            <Typography variant="h6" component="div" sx={{ color: selectedPlan.color }}>
              ₹{selectedPlan.plan_charges}/month
            </Typography>
          </Stack>
          <DialogContent sx={{ '& > *:not(:last-child)': { paddingTop: '8px' } }}>


            <Typography variant="subtitle1">
              <strong> Free Trial Days: </strong><span style={{ color: 'grey' }}> {selectedPlan.free_trial_days}</span>
            </Typography>
            <Divider />
            <Typography variant="subtitle1">
              <strong>Interpretation Price: </strong><span style={{ color: 'grey' }}> {selectedPlan.interpretation_price}</span>
            </Typography>
            <Divider />
            <Typography variant="subtitle1">
              <strong>Free Interpretation Requests: </strong><span style={{ color: 'grey' }}> {selectedPlan.free_interpretation_requests}</span>
            </Typography>
            <Divider />
            <Typography variant="subtitle1">
              <strong>Plan Id: </strong><span style={{ color: 'grey' }}> {selectedPlan.plan_id}</span>
            </Typography>
            <Divider />
            <Typography variant="subtitle1">
              <strong>Plan Identifier: </strong><span style={{ color: 'grey' }}> {selectedPlan.identifier}</span>
            </Typography>
            <Divider />
            <Typography variant="subtitle1">
              <strong>Plan Features: </strong>
            </Typography>

            <List>
              {selectedPlan.features.map((feature, index) => (
                <ListItem key={index} style={{ padding: '0' }}>
                  <ListItemIcon sx={{ minWidth: '30px', paddingLeft: '10px' }}>
                    <FiberManualRecordIcon sx={{ fontSize: 10 }} />
                  </ListItemIcon>
                  <ListItemText primary={feature} style={{ marginLeft: 0 }} />
                </ListItem>
              ))}
            </List>






          </DialogContent>
        </>
      )}
      <DialogActions>
        <Button onClick={handleDialogClose}>Close</Button>
      </DialogActions>
    </Dialog>


  </>)
}
