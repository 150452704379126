import { useMemo } from 'react';
// utils
import { poster, endpoints } from '../utils/axios';

export async function userLogin(payload) {
  const URL = endpoints.loginProcess.usersLogin;
  return await poster([URL, payload]);
}
export async function userVerify(payload) {
  const URL = endpoints.loginProcess.otpVerify;
  return await poster([URL, payload]);
}
export async function generateFcmToken(payload) {
  const URL = endpoints.loginProcess.generateFcmToken;
  return await poster([URL, payload]);
}

export async function deleteFcmToken(payload) {
  const URL = endpoints.loginProcess.deleteFcmToken;
  return await poster([URL, payload]);
}

// export async function tester(payload){

//     const URL=endpoints.loginProcess.tester;
//     return await poster([{url:URL,body:payload},{}])

//     }
