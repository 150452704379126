// utils
import { fetcher, endpoints, poster, patcher } from '../utils/axios';

export async function verifyToken(payload) {
    const URL = endpoints.loginProcess.verifyToken;
    return await poster([URL, payload])
}

export async function getB2Busers(payload) {
    const URL = endpoints.user.getB2Busers;
    // return await fetcher([URL, payload])
    return await fetcher([URL + `${""}`])
}

export async function removeB2BUser(payload) {
    const URL = endpoints.user.getB2Busers;
    return await fetcher([URL, payload])
}

export async function getB2BAssociatedUser(payload) {
    const URL = endpoints.user.getB2BAssociatedUser;
    return await fetcher([URL, { params: payload }])
}

export async function addB2BAssociatedUser(payload) {
    const URL = endpoints.user.addB2BAssociatedUser;
    return await poster([URL, payload])
}

export async function removeB2BAssociatedUser(payload) {
    const URL = endpoints.user.removeB2BAssociatedUser;
    return await poster([URL, payload])
}

export async function saveB2BProfile(payload) {
    const URL = endpoints.user.saveB2BProfile
    // return await poster([{URL, body: {companyProfileData : payload} }])
    return await poster([URL, payload])
}

export async function getInternalUsers(payload) {
    const URL = endpoints.user.getInternalUsers
    // return await poster([{URL, body: {companyProfileData : payload} }])
    return await fetcher([URL, { params: payload }])
}

export async function saveInternalUsers(payload) {
    const URL = endpoints.user.saveInternalUsers
    // return await poster([{URL, body: {companyProfileData : payload} }])
    return await poster([URL, payload])
}

export async function removeInternalUsers(payload) {
    const URL = endpoints.user.removeInternalUsers
    // return await poster([{URL, body: {companyProfileData : payload} }])
    return await poster([URL, payload])
}

export async function updateUserProfile(payload) {
    const URL = endpoints.user.updateUserProfile
    // return await poster([{URL, body: {companyProfileData : payload} }])
    return await poster([URL, payload])
}
export async function getUserOrderStatus(payload){
    const URL=endpoints.user.getUserOrder
    return await fetcher([URL, { params: payload }])
}

export async function getInvoicePdf(payload) {
    const URL = endpoints.user.getInvoicePdf
    return await poster([URL, payload])
}

export async function saveInvoiceDetails(payload) {
    const URL = endpoints.user.saveInvoiceDetails
    return await poster([URL, payload])
}

export async function fetchInvoiceData(payload) {
    const URL = endpoints.user.fetchInvoiceData
    return await poster([URL, payload])
}

export async function toCheckInvoiceNumber(payload) {
    const URL = endpoints.user.toCheckInvoiceNumber
    return await poster([URL, payload])
} 

export async function fetchClientPdf(payload) {
    const URL = endpoints.user.fetchClientPdf
    return await poster([URL, payload])
} 

export async function addB2BAdmin(payload){
    const URL = endpoints.user.addB2BAdmin
    return await poster([URL, payload])
}
export async function removeB2BAdminUser(payload){
    const URL =endpoints.user.removeb2badminusers
    return await poster([URL, payload])
}
export async function getB2Badmindata(payload){
    const URL =endpoints.user.getB2Badmindata
    return await fetcher([URL, { params: payload }])
}

export async function getAppOnboardedUsers(payload){
    const URL =endpoints.user.getAppOnboardedUsers
    return await fetcher([URL, { params: payload }])
}

export async function getGuestUsers(payload){
    const URL =endpoints.user.getGuestUsers
    return await fetcher([URL, { params: payload }])
}

export async function getSubscriptionPlan(payload){
    const URL =endpoints.user.getSubscriptionPlan
    return await fetcher([URL, { params: payload }])

}

export async function getMyClinics(payload) {
    const URL = endpoints.user.getMyClinics;
    return await fetcher([URL + `${""}`])
}

export async function getExisitingPlanUsers(payload) {
    const URL = endpoints.user.getExisitingPlanUsers;
    return await fetcher([URL, { params: payload }])
}

export async function getB2BAssociatedUserCount(payload) {
    const URL = endpoints.user.getB2BAssociatedUserCount;
    return await fetcher([URL, { params: payload }])
}





