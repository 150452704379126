import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import Label from 'components/label/Label';
// @mui
import {
  Card,
  Box,
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableFooter,
  Tooltip,
  Link,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Divider
} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { useTheme } from '@mui/material/styles';

// components
import Scrollbar from '../components/scrollbar';

import { styled } from '@mui/system';
import {useSnackbar} from '../components/snackbar';

import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { Add, Close, Edit } from '@mui/icons-material'
import { getInternalUsers, removeInternalUsers } from 'api/users';
import { copyToClipboard } from 'utils/Utils';
import UserProfile from './UserProfile';
import { useAuthDispatch } from 'components/AuthStateProvider';

//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)(() => ({
  maxWidth: 150, // percentage also works
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export default function ManageInternalUsers() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar(); 
  const isSmall = useResponsive('', 'xs');
  const isLg = useResponsive('up', 'md');
  const { account } = useAuthDispatch();

  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [deleteData, setDeleteData] = useState(null);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getInternalUsers();
      setLoading(false);
      if (response?.success) {
        setData(response?.data || []);
      } else {
        enqueueSnackbar(response.message, { variant: 'error' })
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Unable to fetch B2B users.", { variant: 'error' })
    }
  };

  const onDeleteDataClick = (data) => async (e) => {
    try {
      if (deleteData) {
        setLoading(true) 
        const response = await removeInternalUsers({ phone_number: data?.phone_number })
        if (response.success === true && response.status == 200) {
          setDeleteData(null);
          setLoading(false);
          setData(prevData => [ ...prevData.filter(item => item?.phone_number !== data?.phone_number)]);
          enqueueSnackbar(response.message || "User deleted successfully.", { variant: 'success' })
        } else if (response.success === false) {
          setDeleteData(null);
          setLoading(false);
          enqueueSnackbar(response.message || "Unable to delete the user.", { variant: 'error' })
        }
      } else {
        enqueueSnackbar("Please select user to delete.", { variant: 'error' })
      }
    } catch (e) {
        console.log(e)
        enqueueSnackbar(e.message || "Unable to delete the user.", { variant: 'error' })
    }
  }

  const handleSavedEvent = (success, error) => {
    if (success) {
      setSelectedData(null);
      fetchData();
    }
  }

  const handleCheckClick = (checked, row) => {
    if (checked) setSelected((prevIds) => [...prevIds, row?.phone_number])
    else setSelected((prevIds) => prevIds.filter((existingId) => existingId !== row?.phone_number));
  };

  const onCopyClick = (text) => (e) => {
    copyToClipboard(text);
    enqueueSnackbar('Copied to clipboard.', { variant: 'success' })
  };


  return (
    <>
      <Helmet>
        <title> Manage user | Sunfox Experts</title>
      </Helmet>

      <Container sx={{ marginTop: (isLg ? 0 : 1) }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Manage user
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" color='warning' onClick={() => { setSelectedData({ user_type: 'admin' }); }}>
              <Add sx={{ mr: 1 }} />
                Add Admin
            </Button>
            <Button variant="contained" onClick={() => { setSelectedData({ user_type: 'expert' }); }}>
              <Add sx={{ mr: 1 }} />
                Add Expert
            </Button> 
            <Button variant="contained" onClick={() => { setSelectedData({ user_type: ['managementteam', 'businessteam', 'marketingteam', 'r&dteam'] }); }}>
              <Add sx={{ mr: 1 }} />
                Add Others
            </Button> 
          </Stack>
        </Stack>
        <Card>
          <Box>          
            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Scrollbar>
                  {/* <TableContainer sx={{ minWidth: 800 }}> */}
                  <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ minWidth: 100 }}>User Type</TableCell>
                            <TableCell sx={{ minWidth: 150 }}>Name</TableCell>
                            <TableCell sx={{ minWidth: 100 }}>Phone No.</TableCell>
                            <TableCell sx={{ minWidth: 100 }}>Email Id</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                    {data.length ? (
                      <TableBody>
                        {data?.map((row, index) => {
                          return (
                            <TableRow hover key={index} tabIndex={-1}>
                            <TableCell>
                              <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                                {row?.user_type}
                              </OverflowTypography>
                            </TableCell>
                              <TableCell>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Tooltip title={row?.name}>
                                    <OverflowTypography variant="subtitle2" noWrap>
                                      {row?.name}
                                    </OverflowTypography>
                                  </Tooltip>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                                  {row?.phone_number}
                                </OverflowTypography>
                              </TableCell>
                              <TableCell>
                                <OverflowTypography variant="subtitle2" noWrap>
                                    {row?.email}
                                </OverflowTypography>
                              </TableCell>
                              <TableCell>
                                  <Stack direction="row"  spacing={2}>
                                    <Tooltip title="Edit" arrow>
                                        <LoadingButton
                                            onClick={ () => setSelectedData(row) }
                                            loading={false}
                                            variant="contained"
                                            disabled={ selectedData?.phone_number === row?.phone_number }
                                            >
                                            {/* <Edit /> */}
                                            Edit
                                        </LoadingButton>
                                    </Tooltip>

                                    <Tooltip title="Edit" arrow>
                                        <LoadingButton
                                            onClick={ () => setDeleteData(row) }
                                            loading={false}
                                            variant="contained"
                                            color='error'
                                            disabled={ selectedData?.phone_number === row?.phone_number }
                                            >
                                            Delete
                                        </LoadingButton>
                                    </Tooltip>
                                  </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <TableFooter>
                        <TableRow>
                          <TableCell style={{ width: '100%' }} align="center" colSpan={6} v>
                            <Typography mt={2}>No user found.</Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                  {/* </TableContainer> */}
                </Scrollbar>
              )}
            </Box>
          </Box>
        </Card>
      </Container>
      { selectedData && <Dialog
        fullWidth
        maxWidth="md"
        scroll='body'
        disableEscapeKeyDown={() => setSelectedData(null)}
        open={selectedData ? true : false }
        onClose={() => setSelectedData(null)}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{selectedData?.phone_number ? `Update ${selectedData?.user_type}'s details` : `Add new ${Array.isArray(selectedData?.user_type) ? 'user' : selectedData?.user_type }` }</span>
          <Label><Link 
            onClick={() => setSelectedData(null)}
            component="button" 
            color='black' 
            underline='none'
            variant="button">
            Close
          </Link></Label>
        </DialogTitle>
        <UserProfile 
          data={selectedData} 
          userType={ selectedData?.user_type }
          isAdmin={ account?.userType === 'superuser' || account?.userType === 'admin' } 
          isEditing={ selectedData?.phone_number ? true : false }
          onSubmitDome={ handleSavedEvent } />
      </Dialog> }

      { deleteData && <Dialog
        fullWidth
        maxWidth="xs"
        scroll='body'
        disableEscapeKeyDown={()=> setDeleteData(null)}
        open={deleteData ? true : false}
        onClose={()=> setDeleteData(null)}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Confirmation
        </DialogTitle>
        <Box sx={{ px: 3, pb: 4 }}>Do you want to delete user <b><u>{deleteData?.name}</u></b>?</Box>
        <Divider />
        <DialogActions>
          <Stack direction='row' spacing={4} sx={{ px: 2, py: 1 }}>
            <Button variant="outlined" color="error" onClick={ () => setDeleteData(null) }>
              Cancel
            </Button>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              onClick={ onDeleteDataClick(deleteData) }
            >
              Confirm
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog> }
    </>
  );
}
