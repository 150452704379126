import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import Chart from 'react-apexcharts';
import BarChart from './BarChart';
import { getMonthlyDataOrPercentages,getMonthlyTestsAndTheirCount } from 'api/device';


const ProgressChart = () => {
  const [pieChartData, setPieChartData] = useState([]);
  const [chartData, setChartData] = useState({ series: [], options: {} });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMonthlyDataOrPercentages({ getPercentages: true });
        setPieChartData(response?.data?.map(data => ({ name: data.name, y: parseFloat(data.percentage.replace('%', '')) })));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchTestData = async () => {
      try {
        const response = await getMonthlyTestsAndTheirCount();
        const testData = response?.data?.map(data => ({ name: data.name, count: data.count }));
        const totalTests1 = testData.reduce((acc, curr) => acc + curr.count, 0);

        const radialData = {
          series: testData.map(data => parseFloat((data.count / totalTests1 * 100).toFixed(1))),
          options: {
            chart: {
              height: 350,
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                hollow: {
                  size: '40%'
                },
                dataLabels: {
                  show: true,
                  total: {
                    show: true,
                    label: 'Total Tests',
                    formatter: function (w) {
                      return totalTests1;
                    }
                  },
                },
                track: {
                  show: false
                },
              }
            },
            labels: testData.map(data => data.name),
            legend: {
              show: true,
              position: 'bottom',
            }
          }
        };

        setChartData(radialData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTestData();
  }, []);

  const chartOptions = {
    labels: pieChartData.map(data => data.name),
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 10
        },
        donut: {
          size: '65%'
        }
      }
    },
   
    chart: {
      toolbar: {
        show: false
      },
      height: 350 
    }
  };

   
  return (
    <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
        <Card variant="outlined" >
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Total Tests / Month
            </Typography>
            <BarChart />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card variant="outlined" style={{ height: '400px' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Total Test-Percentage / Device
            </Typography>
            <Chart
              options={chartOptions}
              series={pieChartData.map(data => data.y)}
              type="donut"
              width="100%"
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card variant="outlined" style={{ height: '400px' }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Total Lead-Tests
            </Typography>
            <Chart
              options={chartData.options}
              series={chartData.series}
              type="radialBar"
              width="100%"
            />
          </CardContent>
        </Card>
      </Grid>
    
    </Grid>
  );
};

export default ProgressChart;
