import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { initDB } from "react-indexed-db-hook";
import { DBConfig } from "DBConfig";
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { SnackbarProvider } from './components/snackbar'
import { AuthStateProvider, useAuthDispatch } from './components/AuthStateProvider'

// ----------------------------------------------------------------------

initDB(DBConfig);

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <HelmetProvider>
      <AuthStateProvider>
        <BrowserRouter>
          <ThemeProvider>
            <SnackbarProvider>
              <ScrollToTop />
              <StyledChart />
                <Router />
            </SnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
        </AuthStateProvider>
      </HelmetProvider>
    </LocalizationProvider>
  );
}
