import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Typography, Stack, Card, Container, CircularProgress } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid';
import { useSnackbar } from '../components/snackbar';
import { getB2BAssociatedUserCount } from 'api/users';
import { AssociatedUserListToolbar } from 'sections/@dashboard/user';


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: '#F4F6F8',
        color: '#637381',
        fontWeight: 'bold',
    },
    '& .MuiDataGrid-cell': {
        borderBottom: 'none',
    },
    '& .MuiDataGrid-row': {
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
    },
}));


AssociatedUserListToolbar.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    handleStartDateChange: PropTypes.func,
    isSmall: PropTypes.bool,
};

export default function AssociateUsersCount() {
    const { enqueueSnackbar } = useSnackbar();
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterName, setFilterName] = useState('');

    const TABLE_COLUMNS = [
        { field: 'name', headerName: 'Name', minWidth: 150, flex: 1 },
        { field: 'count', headerName: 'Test Count', flex: 1 },
    ];

    const handleDateChange = (date, type) => {
        if ((type === 'start' && date.isAfter(endDate)) || (type === 'end' && date.isBefore(startDate))) {
            enqueueSnackbar(`${type === 'start' ? 'Start' : 'End'} date must be ${type === 'start' ? 'before or equal to' : 'after or equal to'} the other date.`, { variant: 'error' });
            return;
        }
        type === 'start' ? setStartDate(date) : setEndDate(date);
        fetchData(type === 'start' ? date : startDate, type === 'end' ? date : endDate);
    };

    const fetchData = async (start, end) => {
        setLoading(true);
        const response = await getB2BAssociatedUserCount({
            startDate: dayjs(start).format('DD MMM, YYYY'),
            endDate: dayjs(end).format('DD MMM, YYYY'),
        });
        setLoading(false);

        if (response?.success) {
            const formattedData = Object.entries(response.data).map(([id, { name, count }]) => ({ id, name, count }));
            setData(formattedData || []);
        } else {
            enqueueSnackbar(response.message, { variant: 'error' });
            setData([]);
        }
    };

    useEffect(() => {
        fetchData(startDate, endDate);
    }, [startDate, endDate]);

    const filteredData = useMemo(() => data.filter(({ name }) => name.toLowerCase().includes(filterName.toLowerCase())), [data, filterName]);

    const dataGridHeight = Math.max(filteredData.length * 52 + 60, 300);

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ justifyContent: "space-between", gap: 1, padding: "0 20px" }} direction="row" spacing={3}>
                <GridToolbarQuickFilter />
                <Stack direction="row" spacing={3} justifyContent="space-between">
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarExport />
                </Stack>
            </GridToolbarContainer>
        );
    }

    return (
        <>
            <Helmet>
                <title>Test(s) Taken by Associated Users | YourApp</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignContent="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4">Test(s) Taken by Associated Users</Typography>
                </Stack>
                <Box>
                    <Card>
                        <AssociatedUserListToolbar
                            startDate={startDate}
                            endDate={endDate}
                            handleStartDateChange={handleDateChange}
                            isSmall={false}
                            total={ data?.reduce((totalTest, { count }) => totalTest + count, 0) }
                        />
                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Box sx={{ height: dataGridHeight, width: '100%' }}>
                                <StyledDataGrid
                                    rows={filteredData}
                                    columns={TABLE_COLUMNS}
                                    getRowId={(row) => row.id}
                                    components={{ Toolbar: CustomToolbar }}
                                    pageSize={25}
                                    localeText={{ noRowsLabel: 'No associated users count found.' }}
                                    initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
                                    pageSizeOptions={[5, 10, 15, 25, 50]}
                                />
                            </Box>
                        )}
                    </Card>
                </Box>
            </Container>
        </>
    );
}
