import * as React from "react";
import { useState, useEffect } from "react";
// @mui material components
import { Grid, Button, Card, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
// Others
import CustomInputLabel from "components/custom-input-label";
import customStyle from "customstyle.module.css";
import Dayjs from "dayjs";
// Data
import { signatures_offline as signatures } from "client-config";
import suggestedActionsData from "layouts/manual-Interpretation/data/SuggestedActions";
// import arryhthmiaData from "layouts/manual-Interpretation/data/arryhthmiaData";
// import stemiData from "layouts/manual-Interpretation/data/stemiData";
// import arrhythmiaTextData from "layouts/manual-Interpretation/data/arrhythmiaTextData";
import stemiTextData from "layouts/manual-Interpretation/data/stemiTextData";
// import Typography from "theme/overrides/Typography";

function ReportInputForm({ formKey, inputData, handleReportInputFormSubmit }) {
  const [formInput, setFormInputs] = useState(inputData);
  const [arrhythmiaSuggetions, setArrhythmiaSuggetions] = useState();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  useEffect(() => {
    document.getElementById("report-input-form").reset();
    setFormInputs(inputData);
  }, [inputData]);
  //Handle all input change event of <Form> element here
  const handleChange = (event) => {
    let interval = "";
    const name = event.target.name;
    const value = event.target.value;
    setFormInputs((values) => ({ ...values, [name]: value }));
    if (name.includes("Box")) {
      interval = value !== "" ? (parseFloat(value) * 40).toFixed(0) : "";
      setFormInputs((values) => ({ ...values, [name.replaceAll("Box", "")]: interval }));
    }
    if (name === "RRIntervalBox") {
      setFormInputs((values) => ({
        ...values,
        ["HeartRate"]: interval !== "" ? (60000 / parseFloat(interval)).toFixed(0) : "",
        ["QTC"]: calculateQTC(formInput["QTInterval"], interval),
      }));
    }
    if (name === "QTIntervalBox") {
      setFormInputs((values) => ({
        ...values,
        ["QTC"]: calculateQTC(formInput["QTInterval"], formInput["RRInterval"]),
      }));
    }
    if (name === "TotalScore") {
      suggetionsFromStemi({ TotalScore: value });
    }
  };

  function calculateQTC(qt, rr) {
    if (qt && rr) {
      return parseInt((parseFloat(qt) / 1000 / Math.sqrt(parseFloat(rr) / 1000)) * 1000);
    } else {
      return "";
    }
  }

  const handleFindingsMultiSelect = (event, selectedOptions, reason) => {
    let result = null;
    if (reason === "clear") {
      suggestedActions(null, true);
    } else if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find((option) => option.value === "select-all")) {
        result = items.filter((el) => el.value !== "select-all");
      } else {
        result = selectedOptions;
      }
      suggestedActions(result);
    }
  };

  function suggestedActions(selectedValues, isRemove) {
    let sugg = formInput["SuggestedActions"] || "";
    let resultText = "";
    //Remove All prv selected text
    for (var value of suggestedActionsData) {
      sugg = sugg.replaceAll(value + "\n", "");
    }
    if ((selectedValues || []).length)
      selectedValues.map((item) => {
        if (!sugg.match(item)) resultText += `${item}\n`;
      });
    if (resultText !== "") {
      sugg += resultText;
    }
    setFormInputs((values) => ({
      ...values,
      ["SuggestedActions"]: sugg,
      Findings: selectedValues,
    }));
  }

  const handleArrhymiaChange = (event, selectedOptions, reason) => {
    comonAutoCompleteChange(event, selectedOptions, reason, "Arrhymia");
  };

  const handleStemiChange = (event, selectedOptions, reason) => {
    commonAutoCompleteChange(event, selectedOptions, reason, "Stemi");
  };

  function commonAutoCompleteChange(event, selectedOptions, reason, elKey) {
    let result = null;
    if (reason === "clear") {
      if (elKey === "Arrhymia") {
        suggetionsFromArrhymia();
      } else if (elKey === "Stemi") {
        suggetionsFromStemi();
      }
    } else if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find((option) => option.value === "select-all")) {
        //handleToggleSelectAll();
        // let result = [];
        result = items.filter((el) => el.value !== "select-all");
        // onChange(result);
      } else {
        //handleToggleOption(selectedOptions);
        result = selectedOptions;
        // onChange(selectedOptions);
      }
      const resultKey = {};
      result.map((item) => {
        resultKey[item.title] = true;
      });
      if (elKey === "Arrhymia") {
        suggetionsFromArrhymia(resultKey);
      } else if (elKey === "Stemi") {
        suggetionsFromStemi(resultKey);
      }
    }
  }

  function suggetionsFromArrhymia(resultKey) {
    let resultText = "";
    var sugg = formInput["SuggestedActions"] || "";
    if (resultKey) {
      //1. Atrial fibrillation
      if (
        (resultKey["pWaveAbsent"] == true &&
          resultKey["rrVariabilityThresholdgreater25"] == true) ||
        resultKey["interpWaveRategreater250"] == true
      ) {
        resultText += "Atrial Fibrillation\n";
      }
      //2. Atrial Flutter
      if (resultKey["intraprategreater250_350"] == true) {
        resultText += "Atrial Flutter\n";
      }
      //3. Atrial Tachycardia
      // Check for atrial tachycardia based on the combined criteria
      if (
        resultKey["heartRateGreaterThan100"] == true &&
        resultKey["pWaveDurationBetween150And250"] == true &&
        resultKey["narrowQRS"] == true
      ) {
        resultText += "Atrial Tachycardia\n";
      }
      //4. Junctional Rhythm
      // Check for junctional rhythm based on the combined
      //criteria
      if (
        resultKey["pWaveAbsent"] == true &&
        resultKey["heartRateGreaterThan60"] == true &&
        resultKey["rrVariabilityLessThan10(rrVariability)"] == true &&
        resultKey["narrowQRS"] == true
      ) {
        resultText += "Junctional Rhythm\n";
      }
      //5. High Degree AV block
      // Check for high-degree AV block based on the combined criteria
      if (
        resultKey["prolongedPRI"] == true &&
        resultKey["rrVariabilityGreaterThan20(rrVariability)"] == true
      ) {
        resultText += "High-Degree AV Block\n";
      }
      //6. AV block
      // Check for AV block based on the combined criteria
      if (resultKey["prolongedPRI"] == true && resultKey["rrVariabilityLessThan20"] == true) {
        resultText += "AV Block\n";
      }
      //7. WPW syndrome(pre - excitation)
      // Check for Wolff-Parkinson-White (WPW) syndrome based on the combined
      //criteria
      if (resultKey["shortenedPRInterval"] && resultKey["lurredRWave"] && resultKey["wideQRS"]) {
        resultText += "Wolff-Parkinson-White (WPW) Syndrome\n";
      }
      //8. SuperaVenticular Ectopics
      // Check for superaventricular ectopics based on the combined criteria
      if (resultKey["rrVariabilityGreaterThan25"] == true && resultKey["narrowQRS"] == true) {
        resultText += "Superaventricular Ectopics\n";
      }
      //9. Ventricular Ectopics
      // Check for ventricular ectopics based on the combined criteria
      if (resultKey["rrVariabilityGreaterThan25"] == true && resultKey["wideQRS"] == true) {
        resultText += "Ventricular Ectopics\n";
      }
      //10.Superaventricular Tachycardia
      // Check for Supraventricular Tachycardia (SVT) based on the combined
      //criteria
      if (
        resultKey["heartRateGreaterThan100"] &&
        resultKey["absentPWave"] &&
        resultKey["narrowQRS"]
      ) {
        resultText += "Supraventricular Tachycardia\n";
      }
      //11.Ventricular tachycardia
      // Check for Ventricular Tachycardia based on the combined criteria
      if (
        resultKey["heartRateGreaterThan100"] &&
        resultKey["absentPWave"] &&
        resultKey["wideQRS"]
      ) {
        resultText += "Ventricular Tachycardia\n";
      }
      //12.Ventricular premature Complex
      // Check for Ventricular Premature Contraction (VPC) based on the combined
      //criteria
      if (
        resultKey["superimposedPWave"] &&
        resultKey["stDiscordant"] &&
        resultKey["rrVariabilityGreaterThan25"] &&
        resultKey["wideQRS"]
      ) {
        resultText += "Ventricular Premature Contraction (VPC) Detected\n";
      }
      //13.Sinus Bradycardia
      // Check for Sinus Bradycardia based on the criteria
      if (resultKey["heartRateLessThan60"]) {
        resultText += "Sinus Bradycardia\n";
      }
      //14.Sinus Tachycarida
      // Check for Sinus Bradycardia based on the criteria
      if (resultKey["heartRategGreaterThan60"]) {
        resultText += "Sinus Tachycardia\n";
      }
    } else {
      resultText = "";
    }
    //Remove All prv selected text
    for (var value of arrhythmiaSuggetions) {
      sugg = sugg.replaceAll(value + "\n", "");
    }
    if (resultText !== "") {
      sugg += resultText;
    }
    setFormInputs((values) => ({ ...values, ["SuggestedActions"]: sugg }));
  }

  function suggetionsFromStemi(resultKey) {
    let resultText = "";
    var sugg = formInput["SuggestedActions"] || "";
    if (resultKey) {
      //1. Left bundle Branch block
      // Check for Left Bundle Branch Block (LBBB) based on the combined criteria
      if (resultKey["wideQRS"] && resultKey["dominantSV1"] && resultKey["notchedMShapeRWaveV5V6"]) {
        resultText += "Left Bundle Branch Block (LBBB)\n";
      }
      //2. Right Branch Bundle Block
      // Check for Right Bundle Branch Block (RBBB) based on the combined criteria
      if (resultKey["rBySRatioGreaterThan1InV1"] && resultKey["wideQRS"]) {
        resultText += "Right Bundle Branch Block (RBBB)\n";
      }
      //3. Anterior Wall MI
      // Check for Anterior Wall Myocardial Infarction (MI) based on the combined criteria
      if (
        resultKey["stElevationOrDepressionInV1"] &&
        resultKey["stElevationOrDepressionInV2"] &&
        resultKey["stElevationOrDepressionInV3"]
      ) {
        resultText += "Anterior Wall Myocardial Infarction (MI)\n";
      }
      //4. Antero - septal MI
      // Check for Antero-septal Myocardial Infarction (MI) based on the combined criteria
      if (
        resultKey["stElevationOrDepressionInV1"] &&
        resultKey["stElevationOrDepressionInV2"] &&
        resultKey["stElevationOrDepressionInV3"] &&
        resultKey["stElevationOrDepressionInV4"] &&
        resultKey["stElevationOrDepressionInV5"]
      ) {
        resultText += "Antero-septal Myocardial Infarction (MI)\n";
      }
      //5. Antero - Apical MI
      // Check for Antero-apical Myocardial Infarction (MI) based on the combined criteria
      if (
        resultKey["stElevationOrDepressionInLeadI"] &&
        resultKey["stElevationOrDepressionInLeadAVL"] &&
        resultKey["stElevationOrDepressionInV2"] &&
        resultKey["stElevationOrDepressionInV3"] &&
        resultKey["stElevationOrDepressionInV4"]
      ) {
        resultText += "Antero-apical Myocardial Infarction (MI)\n";
      }
      //6. Inferior wall MI
      // Check for Inferior Wall Myocardial Infarction (MI) based on the combined criteria
      if (
        resultKey["stElevationOrDepressionInLeadII"] &&
        resultKey["stElevationOrDepressionInLeadIII"] &&
        resultKey["stElevationOrDepressionInLeadAVF"]
      ) {
        resultText += "Inferior Wall Myocardial Infarction (MI)\n";
      }
      //7. Inferolateral MI
      // Check for Infero - lateral Wall Myocardial Infarction(MI) based on the combined criteria
      if (
        resultKey["stElevationOrDepressionInLeadII"] &&
        resultKey["stElevationOrDepressionInLeadIII && stElevationOrDepressionInLeadAVF"] &&
        resultKey["stElevationOrDepressionInLeadI"] &&
        resultKey["§stElevationOrDepressionInLeadAVL"]
      ) {
        resultText += "Infero-lateral Wall Myocardial Infarction (MI)\n";
      }

      //8. Lateral MI
      // Check for Lateral Wall Myocardial Infarction (MI) based on the combined criteria
      if (
        resultKey["stElevationOrDepressionInLeadI"] &&
        resultKey["stElevationOrDepressionInLeadAVL"] &&
        resultKey["stElevationOrDepressionInV5"] &&
        resultKey["stElevationOrDepressionInV6"]
      ) {
        resultText += "Lateral Wall Myocardial Infarction (MI) Detected\n";
      }
      //9. Ischemia
      // Check for ischemia based on the criteria
      if (resultKey["stSegmentDepression"] || resultKey["tWaveInversion"]) {
        resultText += "Ischemia\n";
      }
      //10.Left Ventricular Hypertrophy
      // Define the threshold for LVH diagnosis
      //int lvhThreshold = 5; //
      //Check for LVH based on the total score
      if (resultKey["TotalScore"] > 5) {
        resultText += "Left Ventricular Hypertrophy(LVH)\n";
      }
      //11.ST abnormality
      // Check for ST-T abnormality based on the criteria
      if (resultKey["stSegmentElevation"]) {
        resultText += "ST-T Abnormality\n";
      }
    } else {
      resultText = "";
    }
    //Remove All prv selected text
    for (var value of stemiTextData) {
      sugg = sugg.replaceAll(value + "\n", "");
    }
    if (resultText !== "") {
      sugg += resultText;
    }
    setFormInputs((values) => ({ ...values, ["SuggestedActions"]: sugg }));
  }

  return (
    <form
      id="report-input-form"
      onSubmit={(e, option) =>
        handleReportInputFormSubmit(e, {
          ...formInput,
          DateOfReceivingECG: formInput["DateOfReceivingECG"] || Dayjs().format("DD/MM/YYYY"),
          TimeOfECGInterpretation: formInput["TimeOfECGInterpretation"] || Dayjs().format("hh:mm A"),
          SignatureOf: formInput["SignatureOf"] || ((signatures || []).length ? (signatures.find(item => item['default']) || {})["value"] : null),
          // ReportType: formInput["ReportType"] || "normal",
        })
      }
    >
      <Grid container spacing={2} style={{ position: "relative" }} margin={0} width="100%">
        {formInput["CompletedAt"] ? (
          <CustomInputLabel>
            Report generated at:{" "}
            <b>{Dayjs(formInput["CompletedAt"]).format("DD MMM, YYYY hh:mm A")}</b>
          </CustomInputLabel>
        ) : null}
        <Card className={customStyle.dotedBorder}>
          <Grid container style={{ padding: "15px" }} spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <DatePicker
                  key={`receive-date-${formKey}`}
                  label="Date of Receiving ECG"
                  name="DateOfReceivingECG"
                  value={
                    formInput["DateOfReceivingECG"]
                      ? Dayjs(formInput["DateOfReceivingECG"], "DD/MM/YYYY")
                      : Dayjs()
                  }
                  format="DD/MM/YYYY"
                  closeOnSelect={true}
                  onAccept={(event) =>
                    handleChange({
                      target: {
                        name: "DateOfReceivingECG",
                        value: Dayjs(event).format("DD/MM/YYYY"),
                      },
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DesktopTimePicker
                  key={`receive-time-${formKey}`}
                  label="Time of Receiving ECG"
                  openTo="hours"
                  size="small"
                  value={
                    formInput["TimeOfReceivingECG"]
                      ? Dayjs(formInput["TimeOfReceivingECG"], "hh:mm A")
                      : ""
                  }
                  onAccept={(event) =>
                    handleChange({
                      target: { name: "TimeOfReceivingECG", value: Dayjs(event).format("hh:mm A") },
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DesktopTimePicker
                  key={`gen-time-${formKey}`}
                  label="Time of ECG Interpretation"
                  openTo="hours"
                  value={
                    formInput["TimeOfECGInterpretation"]
                      ? Dayjs(formInput["TimeOfECGInterpretation"], "hh:mm A")
                      : Dayjs()
                  }
                  onAccept={(event) =>
                    handleChange({
                      target: {
                        name: "TimeOfECGInterpretation",
                        value: Dayjs(event).format("hh:mm A"),
                      },
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined"
                fullWidth
                label="First Name"
                name="FirstName"
                value={formInput.FirstName || ""}
                onChange={handleChange}
                size="small" />
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined"
                fullWidth
                label="Last Name"
                name="LastName"
                value={formInput.LastName || ""}
                onChange={handleChange}
                size="small" />
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined"
                fullWidth
                label="Age"
                name="Age"
                value={formInput.Age}
                onChange={handleChange}
                size="small" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth
                select
                name="Gender"
                label="Gender"
                value={formInput.Gender || "Select Gender"}
                SelectProps={{
                  native: true,
                }}
                onChange={handleChange}
                size="small"
              >
                <option key="Male" value="Male">Male</option>
                <option key="Female" value="Female">Female</option>
                <option key="Other" value="Other">Other</option>
              </TextField>

            </Grid>
          </Grid>
        </Card>

        <Card className={customStyle.dotedBorder}>
          <Grid container style={{ padding: "15px" }} spacing={2}>
            <Grid item xs={6}>
              <TextField fullWidth
                select
                name="AnySymptom"
                label="Any Symptoms"
                value={formInput["AnySymptom"] || "Choose Anyone"}
                SelectProps={{
                  native: true,
                }}
                onChange={handleChange}
                size="small"
              >
                <option key="Choose Anyone" value="Choose Anyone" selected disabled>Choose Anyone</option>
                <option key="Chest Pain" value="Chest Pain" >Chest Pain</option>
                <option key="Shortness of Breath" value="Shortness of Breath" >Shortness of Breath</option>
                <option key="Any Other" value="Any Other" >Any Other</option>
                <option key="No Symptoms" value="No Symptoms" >No Symptoms</option>

              </TextField>

            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth
                select
                name="NatureOfECG"
                label="Nature Of ECG"
                value={formInput["NatureOfECG"] || "Choose Anyone"}
                SelectProps={{
                  native: true,
                }}
                onChange={handleChange}
                size="small"
              >
                <option key="Choose Anyone" value="Choose Anyone" selected disabled>Choose Anyone</option>
                <option key="Normal ECG" value="Normal ECG" >Normal ECG</option>
                <option key="Abnormal ECG" value="Abnormal ECG" >Abnormal ECG</option>
                <option key="Critical ECG" value="Critical ECG" >Critical ECG</option>

              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth
                select
                name="ClassificationOfAbnormality"
                label="Classification of Abnormality"
                value={formInput["ClassificationOfAbnormality"] || "Choose Anyone"}
                SelectProps={{
                  native: true,
                }}
                onChange={handleChange}
                size="small"
              >
                <option key="Choose Anyone" value="Choose Anyone" selected disabled>Choose Anyone</option>
                <option key="Arrhythmia" value="Arrhythmia" >Arrhythmia</option>
                <option key="Ischemia" value="Ischemia" >Ischemia</option>
                <option key="Myocardial Infraction" value="Myocardial Infraction" >Myocardial Infraction</option>
                <option key="ST-T Changes" value="ST-T Changes" >ST-T Changes</option>
                <option key="J Point Elevation" value="J Point Elevation" >J Point Elevation</option>
                <option key="RBBB" value="RBBB" >RBBB</option>
                <option key="LBBB" value="LBBB" >LBBB</option>
                <option key="None" value="None" >None</option>

              </TextField>

            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined"
                fullWidth
                label="Total Score"
                name="TotalScore"
                value={formInput["TotalScore"]}
                onChange={handleChange}
                size="small" />
            </Grid>
          </Grid>
        </Card>
        <Card className={customStyle.dotedBorder}>
          <Grid container style={{ padding: "15px" }} spacing={2}>
            <Grid item xs={12}>

              <Autocomplete
                multiple
                id={`choose-findings-${formKey}`}
                onChange={handleFindingsMultiSelect}
                name="Findings"
                value={formInput["Findings"] || []}
                options={suggestedActionsData}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => {
                  return <TextField {...params} name="ArrhythmiaKey" label="Select Finding(s) from List" placeholder="Choose Multiple" />;
                }}
              />

            </Grid>
            <Grid item xs={12}>
              <TextField variant="outlined"
                name="SuggestedActions"
                label="Suggested Actions"
                value={formInput["SuggestedActions"] || ""}
                onChange={handleChange}
                placeholder="Type your suggested action"
                multiline
                fullWidth
                rows={2}
                size="small" />

            </Grid>
            {/* Start PR Interval element */}
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="PR Interval Box"
                name="PRIntervalBox"
                placeholder="Eg: 4.2"
                value={formInput["PRIntervalBox"] || ""}
                onChange={handleChange}
                size="small"
              />

            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                X 40 =
              </Typography>


            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="PR Interval"
                name="PRInterval"
                inputProps={{ tabIndex: "-1" }}
                readOnly={true}
                placeholder="PR Interval"
                value={formInput["PRInterval"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                ms
              </Typography>
            </Grid>
            {/* End Start PR Interval element */}

            {/* Start QRS Interval element */}
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="QRS Interval Box"
                name="QRSIntervalBox"
                placeholder="Eg: 4.2"
                value={formInput["QRSIntervalBox"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                X 40 =
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="QRS Interval"
                name="QRSInterval"
                inputProps={{ tabIndex: "-1" }}
                readOnly={true}
                placeholder="QRS Interval"
                value={formInput["QRSInterval"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                ms
              </Typography>
            </Grid>
            {/* End QRS Interval element */}

            {/* Start QT Interval element */}
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="QT Interval Box"
                name="QTIntervalBox"
                placeholder="Eg: 4.2"
                value={formInput["QTIntervalBox"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                X 40 =
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="QT Interval"
                name="QTInterval"
                inputProps={{ tabIndex: "-1" }}
                readOnly={true}
                placeholder="QT Interval"
                value={formInput["QTInterval"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                ms
              </Typography>
            </Grid>
            {/* End QT Interval element */}

            {/* Start ST Interval element */}
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="ST Interval Box"
                name="STIntervalBox"
                placeholder="Eg: 4.2"
                value={formInput["STIntervalBox"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                X 40 =
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="ST Interval"
                name="STInterval"
                inputProps={{ tabIndex: "-1" }}
                readOnly={true}
                placeholder="ST Interval"
                value={formInput["STInterval"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                ms
              </Typography>
            </Grid>
            {/* End ST Interval element */}

            {/* Start RR Interval element */}
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="RR Interval Box"
                name="RRIntervalBox"
                placeholder="Eg: 4.2"
                value={formInput["RRIntervalBox"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                X 40 =
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="RR Interval"
                name="RRInterval"
                inputProps={{ tabIndex: "-1" }}
                readOnly={true}
                placeholder="RR Interval"
                value={formInput["RRInterval"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                ms
              </Typography>
            </Grid>
          </Grid>
        </Card>
        {/* End RR Interval element */}
        <Card xs={12} className={customStyle.dotedBorder}>
          <Grid container style={{ padding: "15px" }} spacing={2}>
            <Grid item xs={6}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                Heart Rate = <b>{formInput["HeartRate"] || ""}</b>
                <br /> <em>[HR = 60000 / RR (ms)]</em>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth
                select
                name="HeartRateAxis"
                label="Heart Rate Axis"
                value={formInput["HeartRateAxis"] || "Choose Axis"}
                SelectProps={{
                  native: true,
                }}
                onChange={handleChange}
                size="small"
              >
                <option key="Choose Anyone" value="Choose Anyone" selected disabled>Choose Anyone</option>
                <option key="Normal" value="Normal" >Normal</option>
                <option key="Left Axis Deviation" value="Left Axis Deviation" >Left Axis Deviation</option>
                <option key="Right Axis Deviation" value="Right Axis Deviation" >Right Axis Deviation</option>
                <option key="Extreme Axis" value="Extreme Axis" >Extreme Axis</option>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                QTc = QT / √RR (ms) = <b>{formInput["QTC"] || ""}</b>
              </Typography>

            </Grid>
          </Grid>
        </Card>

        <Card xs={12} className={customStyle.dotedBorder}>
          <Grid container style={{ padding: "15px" }} spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <CustomInputLabel>Select signature</CustomInputLabel>
                <Select
                  name="SignatureOf"
                  value={formInput["SignatureOf"] || ((signatures || []).find(item => item["default"]) || {})["value"]}
                  onChange={handleChange}
                  fullWidth
                  className={[customStyle.onFocus, "selectInputStyle"]}
                >
                  {(signatures || []).length ? signatures.map((item, index) =>
                    <MenuItem key={`sign-${index}`} value={item.value} selected={item["default"] || false}>{item.name}</MenuItem>
                  ) : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <FormControl>
                  <CustomInputLabel>Select report type (for segregation)</CustomInputLabel>
                  <RadioGroup row name="ReportType" aria-labelledby="demo-row-radio-buttons-group-label" onChange={handleChange} value={formInput["ReportType"] || ""}>
                    <FormControlLabel value="normal" control={<Radio />} label="Normal report" />
                    <FormControlLabel value="abnormal" control={<Radio />} label="Abnormal report" />
                    <FormControlLabel value="retake" control={<Radio />} label="Retake" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <div className={customStyle.centerBtn}>
        <Button variant="contained" type="submit" color="info">
          Save & Generate Report
        </Button>
      </div>
    </form>
  );
}

export default ReportInputForm;
//Backup element for future use so please don't remove below lines
{/* <Grid pt={ 2 } xs={12}>
          <FormControl fullWidth>
            <CustomInputLabel>Arrhythmia</CustomInputLabel>
            <Autocomplete
              multiple
              id={`choose-arrhythmia-${formKey}` }
              className={customStyle.onFocus}
              onChange={handleArrhymiaChange}
              name="ArrhythmiaKey"
              options={arryhthmiaData}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props} data-key='ArrhythmiaKey'>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              renderInput={(params) => {
                return (<TextField {...params} name='ArrhythmiaKey' placeholder="Choose Multiple" />)
              }}
            />
          </FormControl>
        </Grid> 
        <Grid pt={ 2 } xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id={`choose-stemi-${formKey}` }
              className={customStyle.onFocus}
              onChange={handleStemiChange}
              name="StemiKey"
              data-key='StemiKey'
              options={stemiData}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props} data-key='StemiKey'>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              renderInput={(params) => {
                return (<TextField {...params} name='StemiKey' data-key='StemiKey' placeholder="Choose Multiple" />)
              }}
            />
          </FormControl>
        </Grid> */}