import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Snackbar, Alert, Skeleton } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { getInterpreterDashboard, getDashboardTrends, getDashboardGroupChart } from 'api/dashboard';
import { useSnackbar } from '../components/snackbar';
// ----------------------------------------------------------------------

export default function DashboardSuperUserPage() {
  const { enqueueSnackbar } = useSnackbar(); 
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [trendsLoading, setTrendsLoading] = useState(true);
  const [pieLoading, setPieLoading] = useState(true);
  const [allCount, setAllCount] = useState({ todayCompleted: 0, todayRequested: 0, totalCompleted: 0, totalPending: 0 });
  const [trends, setTrends] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const { todayCompleted, todayRequested, totalCompleted, totalPending } = allCount;

  useEffect(() => {
    fetchData();
    fetchTrendsData();
    fetchPieChartData();
  }, []);
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getInterpreterDashboard({});
      setLoading(false);
      if (response && response.success) {
        if (response.data) {
          const dt = response.data;
          setAllCount(dt);
        }
      } else {
        if (response && response.message) {
          enqueueSnackbar(response.message, { variant: 'error' })

        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchTrendsData = async () => {
    try {
      const response = await getDashboardTrends({});
      setTrendsLoading(false);
      if (response && response.success) {
        setTrends(response?.data || []);
      } 
    } catch (error) {
      setTrendsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchPieChartData = async () => {
    try {
      const response = await getDashboardGroupChart({});
      setPieLoading(false);
      if (response && response.success) {
        if (response.data) {
          const mapGroupChartData = [];
          for (const key in response?.data) {
            mapGroupChartData.push({ label: key, value: response?.data[key] })
          }
          setGroupData(mapGroupChartData);
        }
      } 
    } catch (error) {
      setPieLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      <Helmet>
        <title>{ `Dashboard | ${ process.env.REACT_APP_PORTAL_NAME }` }</title>
      </Helmet>

      <Container maxWidth="xl">
        {/* <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography> */}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Today's reports" total={todayRequested || 0} loading={loading} icon={'solar:documents-bold-duotone'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Today's interpreted" total={todayCompleted || 0} loading={loading} color="info" icon={'healthicons:i-documents-accepted'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Completed in last 30 day's" total={totalCompleted || 0} loading={loading} color="warning" icon={'uiw:document'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Pending in last 30 day's" total={totalPending || 0} loading={loading} color="error" icon={'arcticons:onlyoffice-documents'} />
          </Grid>

          
          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Monthly trends"
              // subheader="(+43%) than previous day"
              loading={ trendsLoading }
              chartLabels={ trends?.map(item => item?.month) }
              chartData={[
                {
                  name: 'Reports',
                  type: 'area',
                  fill: 'gradient',
                  data: trends?.map(item => item?.requested ),
                },
                {
                  name: 'Interpreted',
                  type: 'line',
                  fill: 'solid',
                  data: trends?.map(item => item?.completed ),
                },
                {
                  name: 'Pending',
                  type: 'column',
                  fill: 'gradient',
                  data: trends?.map(item => item?.pending ),
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Expert-wise Interpreted"
              loading={ pieLoading }
              chartData={ groupData }
              chartColors={[
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            /> 
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `${process.env.REACT_APP_HOMEPAGE}assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
          
        </Grid>
      </Container>
    </>
  );
}
