import dayjs from "dayjs";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { blobToURL, fileToBlob } from "../utils/Utils";
import { notes, signatures } from "../client-config";
import { uploadFile } from "../utils/FirebaseStorage";

export const generateReport = async (pdf, reportData, isStore) => {
  try {
    const pdfDoc = await PDFDocument.load(pdf);
    const pdfPages = pdfDoc.getPages();
    // const firstPage = pdfPages[0];
    const chartPage = pdfPages[1];

    const WIDTH = 595,
      HEIGHT = 842;
    if (chartPage) {
      const { width, height } = chartPage.getSize();
      if (width < WIDTH && height < HEIGHT) {
        chartPage.setSize(WIDTH, HEIGHT);
        chartPage.scaleContent(2.83, 2.83);
      }
    }

    // firstPage.setSize(WIDTH, HEIGHT);
    // const { width, height } = firstPage.getSize();

    pdfDoc.removePage(0);

    const headerUrl = "/images/report-header.png";
    const footerUrl = "/images/report-footer.png";
    const headerImage = await blobToURL(await fetch(headerUrl).then((res) => res.blob()));
    const footerImage = await blobToURL(await fetch(footerUrl).then((res) => res.blob()));

    const newPdfDoc = await createReportPDF(
      reportData,
      { width: WIDTH, height: HEIGHT },
      { headerImage, footerImage }
    );
    const reportDoc = await PDFDocument.load(newPdfDoc);

    const [existingPage] = await reportDoc.copyPages(pdfDoc, [1]);
    reportDoc.insertPage(1, existingPage);

    const secondPage = reportDoc.getPage(1);
    const reportHeaderImage = await reportDoc.embedPng(headerImage);
    if (reportData?.ReportFormat === "pro") {
      const { width, height } = chartPage.getSize();
      let nameX = 0, nameY = 0, nameWidth = 0, nameHeight = 0;
      if (reportData?.NamePosition?.transform?.length) {
        nameX = reportData?.NamePosition?.transform[4]
        nameY = reportData?.NamePosition?.transform[5]
        nameWidth = reportData?.NamePosition?.width
        nameHeight = reportData?.NamePosition?.height
      }
      let dateX = 0, dateY = 0, dateWidth = 0, dateHeight = 0;
      if (reportData?.DatePosition?.transform?.length) {
        dateX = reportData?.DatePosition?.transform[4]
        dateY = reportData?.DatePosition?.transform[5]
        dateWidth = reportData?.DatePosition?.width
        dateHeight = reportData?.DatePosition?.height
      }
      // Hide report time
      secondPage.drawRectangle({
        x: dateX + dateWidth - (reportData?.Clinic ? 38 : 58),
        y: dateY - 2,
        width: 100,
        height: dateHeight + 2,
        color: rgb(1, 1, 1),
      });
      // Hide Height and Weight
      secondPage.drawRectangle({
        x: (reportData?.Clinic ? (nameX + nameWidth + 150) : (nameX + nameWidth + 200)),
        y: height - (reportData?.Clinic ? 110 : 100),
        width: reportData?.Clinic ? 400 : 160,
        height: 30,
        color: rgb(1, 1, 1),
      });
      secondPage.drawRectangle({
        x: width - 100,
        y: height - (reportData?.Clinic ? 135 : 140),
        width: width,
        height: 22,
        color: rgb(1, 1, 1),
      });
      
    } else if (!reportData?.ReportFormat) {
      secondPage.drawRectangle({
        x: 20,
        y: HEIGHT - 160,
        width: WIDTH - 40,
        height: 80,
        color: rgb(1, 1, 1),
      });
      secondPage.drawImage(reportHeaderImage, {
        x: 0,
        y: HEIGHT - 80,
        width: WIDTH,
        height: 80,
      });
      secondPage.drawText("Report", {
        x: 28,
        y: HEIGHT - 130,
        size: 28,
        // color: rgb(1, 1, 1),
      });
    }

    const pdfBytes = await reportDoc.save();
    const blob = new Blob([new Uint8Array(pdfBytes)]);
    return { reportPdf: blob };
  } catch (e) {
    console.error("Error while generating report: ");
    console.error(e);
  }
};

export const generateLink = async (reportId, fileBlob, reportType) => {
  if (!reportId || !fileBlob) return { error: "Invalid report." };
  const fileName = `${reportId}${reportType ? "_" + reportType : ""}.pdf`;
  const uploadResult = await uploadFile(fileBlob, fileName);
  return { url: uploadResult["url"] || null, error: uploadResult["error"] || null };
};

const createReportPDF = async (reportData, { width, height }, { headerImage, footerImage }) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const docDefinition = {
    pageSize: { width: width, height: height }, // or page size name
    pageMargins: [0, 80, 0, 100], // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    header: {
      image: headerImage,
      height: 80,
      width: width,
    },
    footer: function (currentPage, pageCount) {
      return [
        {
          text: `Page 1 of 2`,
          alignment: "right",
          fontSize: 11,
          bold: true,
          margin: [0, 0, 30, 2],
        },
        {
          image: footerImage,
          height: 85,
          width: width,
        },
      ];
    },
    content: [
      setPatientReportInfo(reportData),
      ...setInterpretationReport(reportData),
      setECGParameters(reportData),
      ...setFindings(reportData),
      setNote(),
      await setSignature(reportData),
    ],
    styles: {
      heading: {
        bold: true,
        fontSize: 16,
        lineHeight: 1.8,
      },
      interpretationParams: {
        lineHeight: 2,
        fontSize: 10,
        color: "#514f4f",
      },
      findingsHeading: {
        fontSize: 12,
        color: "#514f4f",
        bold: true,
        margin: [50, 5, 50, 0],
      },
      section: {
        lineHeight: 1.5,
        fontSize: 10,
        color: "#504e4e",
        // fillColor: "#f5f7f7"
      },
      pageMargin: {
        margin: [100, 0, 100, 0],
      },
      tableExample: {
        fontSize: 10,
        bold: true,
        margin: [50, 5, 50, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 14,
        color: "black",
        margin: [0, 5],
      },
      tableTextCell: {
        fontSize: 10,
        margin: [0, 5],
        alignment: "center",
        bold: true,
      },
      tableValueCell: {
        fontSize: 10,
        margin: [0, 5],
        alignment: "center",
        bold: true,
      },
    },
    defaulStyle: {
      fontSize: 10,
      lineHeight: 1,
    },
  };
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  return new Promise((resolve) => {
    pdfDocGenerator.getBase64((data) => resolve(data));
  });
};

function setPatientReportInfo(reportData) {
  return {
    style: "section",
    table: {
      widths: ["8%", "10%", "1%", "*", "auto", "1%", "auto", "8%"],
      body: [
        [
          {
            colSpan: 8,
            text: " ",
            lineHeight: 1,
          },
          {},
          {},
          {},
          {},
          {},
          {},
          {},
        ],
        [
          {
            text: " ",
          },
          {
            text: "Name",
            bold: true,
          },
          {
            text: ":",
            bold: true,
          },
          {
            text: reportData["Name"] || "-",
            lineHeight: 1,
          },
          {
            text: "Date of Receiving ECG",
            bold: true,
          },
          {
            text: ":",
            bold: true,
          },
          {
            text: reportData["DateOfReceivingECG"] || "-",
          },
          {
            text: " ",
          },
        ],
        [
          {
            text: " ",
          },
          {
            text: "Age",
            bold: true,
          },
          {
            text: ":",
            bold: true,
          },
          {
            text: reportData["Age"] || "-",
          },
          {
            text: "Time of Receiving ECG",
            bold: true,
          },
          {
            text: ":",
            bold: true,
          },
          {
            text: reportData["TimeOfReceivingECG"] || "-",
            alignment: "left",
          },
          {
            text: " ",
          },
        ],
        [
          {
            text: " ",
            lineHeight: 1,
          },
          {
            text: "Gender",
            bold: true,
          },
          {
            text: ":",
            bold: true,
          },
          {
            text: reportData["Gender"] || "-",
          },
          {
            text: "Time of ECG Interpretation",
            bold: true,
          },
          {
            text: ":",
            bold: true,
          },
          {
            text: reportData["TimeOfECGInterpretation"] || "-",
          },
          {
            text: " ",
          },
        ],
        reportData["ReportId"] ? [
          {
            text: " ",
            lineHeight: 1,
          },
          {
            text: "Report Id",
            bold: true,
            lineHeight: 1,
          },
          {
            text: ":",
            bold: true,
            lineHeight: 1,
          },
          {
            text: reportData["ReportId"] || "-",
            lineHeight: 1,
          },
          {
            colSpan: 4,
            text: " ",
            lineHeight: 1,
          },
        ] : [
          {
            colSpan: 8,
            text: "",
            lineHeight: 1,
          }
        ],
      ],
    },
    layout: "noBorders",
  };
}

function setInterpretationReport(reportData) {
  return [
    {
      margin: [50, 10, 50, 0],
      text: "Interpretation Report",
      style: "heading",
    },
    {
      margin: [50, 0],
      style: "interpretationParams",
      table: {
        widths: ["75%", "5%", "*"],
        body: [
          [
            {
              ul: [
                {
                  text: "Were there any symptoms?",
                  bold: true,
                },
              ],
            },
            {
              text: "-",
              bold: true,
            },
            {
              text: reportData["AnySymptom"] || " NA ",
              color: "black",
              bold: true,
            },
          ],
          [
            {
              ul: [
                {
                  text: "Interpretation and the Nature of ECG Traces?",
                  bold: true,
                },
              ],
            },
            {
              text: "-",
              bold: true,
            },
            {
              text: reportData["NatureOfECG"] || " NA ",
              color: "black",
              bold: true,
            },
          ],
          [
            {
              ul: [
                {
                  text: "Interpretation for Classification of Abnormality?",
                  bold: true,
                },
              ],
            },
            {
              text: "-",
              bold: true,
            },
            {
              text: reportData["ClassificationOfAbnormality"] || " NA ",
              color: "black",
              bold: true,
            },
          ],
        ],
      },
      layout: "noBorders",
    },
  ];
}

function setECGParameters(reportData) {
  return {
    style: "tableExample",
    table: {
      widths: [120, 80, "*", 80, "*"],
      body: [
        [
          {
            rowSpan: 4,
            margin: [0, 40],
            text: [{ text: "MEASUREMENT" }, { text: "\n(ECG Parameter)" }],
            style: "tableTextCell",
          },
          {
            text: [{ text: "PR" }, { text: " (ms)", fontSize: 8 }],
            style: "tableTextCell",
          },
          { text: reportData["PRInterval"] || "-", style: "tableValueCell" },
          {
            text: [{ text: "QT" }, { text: " (ms)", fontSize: 8 }],
            style: "tableTextCell",
          },
          { text: reportData["QTInterval"] || "-", style: "tableValueCell" },
        ],
        [
          {},
          {
            text: [{ text: "ST" }, { text: " (ms)", fontSize: 8 }],
            style: "tableTextCell",
          },
          { text: reportData["STInterval"] || "-", style: "tableValueCell" },
          {
            text: [{ text: "QTc" }, { text: " (ms)", fontSize: 8 }],
            style: "tableTextCell",
          },
          { text: reportData["QTC"] || "-", style: "tableValueCell" },
        ],
        [
          {},
          {
            text: [{ text: "R-R" }, { text: " (ms)", fontSize: 8 }],
            style: "tableTextCell",
          },
          { text: reportData["RRInterval"] || "-", style: "tableValueCell" },
          {
            text: [{ text: "QRS" }, { text: " (ms)", fontSize: 8 }],
            style: "tableTextCell",
          },
          { text: reportData["QRSInterval"] || "-", style: "tableValueCell" },
        ],
        [
          {},
          {
            text: [{ text: "HR" }, { text: " (BPM)", fontSize: 8 }],
            style: "tableTextCell",
          },
          { text: reportData["HeartRate"] || "-", style: "tableValueCell" },
          {
            text: [{ text: "Axis" }],
            style: "tableTextCell",
          },
          { text: reportData["HeartRateAxis"] || "-", style: "tableValueCell" },
        ],
      ],
    },
  };
}

function setFindings(reportData) {
  return reportData["SuggestedActions"]
    ? [
        { text: "Findings and Suggested Actions (if any) –", style: "findingsHeading" },
        {
          style: "tableExample",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: reportData["SuggestedActions"] || "",
                  margin: [10, 5],
                  lineHeight: 1.2,
                },
              ],
            ],
          },
        },
      ]
    : [];
}

function setNote(notesOf) {
  const heading = "Disclaimer - ";
  const content = notes[notesOf || "default"];
  return {
    margin: [50, 0, 50, 10],
    text: heading + content,
    fontSize: 8,
    bold: true,
  };
}

async function setSignature(reportData) {
  const sign = {};
  if (reportData["SignatureOf"]) {
    const signature = await blobToURL(
      await (await fetch(`/images/${reportData["SignatureOf"]}`)).blob()
    );
    sign["image"] = signature;
  } else sign["text"] = "";
  return {
    margin: [50, 10, 50, 0],
    columns: [
      {
        width: 150,
        ...sign,
      },
      { text: "" },
      {
        margin: [0, 30, 0, 0],
        text: [
          { text: "Not for Medico Legal Purpose", fontSize: 9, bold: true },
          { text: "\n\nPowered by: Sunfox Technologies Private Limited", fontSize: 9, bold: true },
        ],
      },
    ],
  };
}

function setBackgaround() {
  return {
    background: function (currentPage, pageSize) {
      if (currentPage === 1) {
        return {
          image: "imageURI",
          width: 300,
          opacity: 0.5,
          absolutePosition: { x: 250, y: 400 },
        };
      } else return null;
    },
  };
}
