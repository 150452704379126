export const getAllData = async (db) => {
    return new Promise((resolve, reject) => {
        db.getAll().then(
            (reportsFromDB) => resolve({ data: reportsFromDB }),
            (error) => reject({ error: error })
        );
    });
};

export const getDataById = async (db, id) => {
    return new Promise((resolve, reject) => {
        db.getByID(id).then(
            (personFromDB) => {
                resolve(personFromDB)
            }
        );
    });
};

export const getDataByIndex = (db, indexName, key) => {
    return new Promise((resolve, reject) => {
        db.getByIndex(indexName, key).then(
            (reportFromDB) => resolve({ data: reportFromDB }),
            (error) => resolve({ error: error })
        );
    });
};

export const getAllByCursor = async (db) => {
    return new Promise((resolve, reject) => {
      const items = [];
      db.openCursor((event) => {
        const cursor = event.target.result;
        if (cursor) {
          items.push(cursor.value);
          cursor.continue();
        } else {
          resolve({ data: items });
        }
      }, IDBKeyRange.bound("A", "F"));
    });
};

export const addNewData = async (db, data) => {
    return new Promise((resolve, reject) => {
        db.add({ ...data, ImportedAt: new Date(), Status: "pending" }).then(
            (result) => {
                resolve({ data: result })
            }, (error) => {
                resolve({ error: error })
            }
        );
    });
};

export const updateData = async (db, data) => {
    return new Promise((resolve, reject) => {
        db.update({ ...data }).then(
            (event) => resolve({ data: true }),
            (error) => resolve({ error: error })
        );
    });
};

export const deleteById = async (db, id) => {
    return new Promise((resolve, reject) => {
        db.deleteRecord(id).then(
            (event) => resolve({ data: true }), 
            (error) => resolve({ error: error })
        );
    });
};

export const clearAll = async (db) => {
    return new Promise((resolve, reject) => {
      db.clear().then(
        () => resolve({ data: true }),
        (error) => reject({ error: error })
      );
    });
};

const DB = {
    getDataById,
    getAllData,
    getDataByIndex,
    addNewData,
    updateData,
    deleteById,
    getAllByCursor,
    clearAll,
};

export default DB;