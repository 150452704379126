import { Helmet } from 'react-helmet-async';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { getUserOrderStatus } from 'api/users';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import useResponsive from '../hooks/useResponsive';

import Label from 'components/label/Label';
// @mui
import {
    Container,
    Stack,
    List,
    ListItem,
    Card,
    CardContent,
    Chip,
    ListItemText,
    Typography,
    Divider,
    useTheme,
    Box,
    Badge,
    Popover,
    MenuItem,
    IconButton
} from '@mui/material';
import { useSnackbar } from '../components/snackbar';

export default function UserOrder() {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const isSmall = useResponsive('', 'xs');
    const isLg = useResponsive('up', 'md');
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getUserOrderStatus();
                setData(response.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Helmet>
                <title>Paid Requests | Sunfox Experts</title>
            </Helmet>

            <Container sx={{ marginTop: (isLg ? 0 : 1) }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom>
                        Paid Requests
                    </Typography>
                </Stack>

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {data && data.length > 0 ? (
                            <List sx={{
                                width: '100%',
                                bgcolor: null,
                                marginBottom: '10px'
                            }}>
                                {data.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <ListItem alignItems="flex-start" sx={{ border: 'none' }}>
                                            <Card sx={{
                                                width: '100%',
                                                bgcolor: null,
                                                flexDirection: 'row',
                                            }}>
                                                <CardContent>
                                                    {/* <Badge
                                                        color="primary"
                                                        sx={{ display: 'flex', justifyContent: 'flex-end', float: 'right', cursor: 'pointer' }}
                                                        onClick={handleClick}
                                                    >
                                                        <MoreVertIcon color="action" />
                                                    </Badge>
                                                    <Popover
                                                        id={id}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        PaperProps={{ elevation: 1 }}
                                                    >
                                                        <MenuItem onClick={handleClose}>Edit</MenuItem>
                                                        <MenuItem onClick={handleClose}>Download</MenuItem>
                                                        <MenuItem onClick={handleClose}>Share</MenuItem>
                                                    </Popover> */}
                                                    <Typography component="div">
                                                        <Typography variant="body2" color="text.secondary" component="div">
                                                            <Typography variant="body2"><strong>Date:</strong> {item.created_at_formatted}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                <strong>Phone Number:</strong> {item.phone_number}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                <strong>Status:</strong> <Label color='success' ><Typography>{item.order_status}</Typography></Label>
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                <strong>Payment Id:</strong> {item.payment_id}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                <strong>Reports Count: </strong>{item.reports.length}
                                                                <Chip
                                                                    label={`Amount: ${item?.charges}`}
                                                                    color="success"
                                                                    size='medium'
                                                                    variant="filled"
                                                                    sx={{ display: 'flex', justifyContent: 'flex-end', float: 'right', color: 'white' }}
                                                                />
                                                            </Typography>
                                                        </Typography>
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </ListItem>
                                    </React.Fragment>
                                ))}
                            </List>
                        ) : (
                            <Typography variant="body1" color="text.secondary">
                                No data to show.
                            </Typography>
                        )}
                    </>
                )}

            </Container>
        </>
    );
}
