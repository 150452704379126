import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export const uploadFileAsync = (file, callBack) => {
  if (file) {
    const storageRef = ref(storage, `interpretations/${file.name}`);
    try {
      const uploadTask = uploadBytes(storageRef, file);

      uploadTask.on("state_changed", async (snapshot) => {
        // Track upload progress
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        if (progress === 100) {
          const url = await getDownloadURL(uploadTask.metadata.ref);
            return callBack(null, url, progress);
        } else {
            callBack(null, null, progress);
        }
      });
    } catch (error) {
        return callBack(error);
    }
  } else {
    return callBack({ message: "Invalid file." });
  }
};

export const uploadFile = async (blob, fileName) => {
  if (blob) {
    const file = new File([blob], fileName);
    const storageRef = ref(storage, `interpretations/${file.name}`);
    try {
      const uploadTask = await uploadBytes(storageRef, file);

      // Get the download URL after successful upload
      const url = await getDownloadURL(uploadTask.metadata.ref);
      return { url };
    } catch (error) {
        return { error };
    }
  } else {
    return { error: { msg: "Invalid file." } };
  }
};
