
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import Label from 'components/label/Label';

// @mui
import {
  Card,
  Box,
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableFooter,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
  Avatar,
  IconButton

} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import Scrollbar from '../components/scrollbar';
import { styled } from '@mui/system';
import { useSnackbar } from '../components/snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { getMyClinics } from 'api/users';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)(() => ({
  maxWidth: 150, // percentage also works
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export default function MyClinics() {
  // const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isSmall = useResponsive('', 'xs');
  const isLg = useResponsive('up', 'md');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);



  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getMyClinics();
      setLoading(false);
      if (response?.success) {
        setData(response?.data || []);
      } else {
        enqueueSnackbar(response?.message, { variant: 'error' });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Unable to fetch B2B users.", { variant: 'error' });
    }
  };



  return (
    <>
      <Helmet>
        <title>Business Clients | Sunfox Experts</title>
      </Helmet>

      <Container sx={{ marginTop: isLg ? 0 : 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            My Clinics
          </Typography>

        </Stack>
        <Card>
          <Box>
            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Scrollbar>
                  <Table>
                    <TableHead>
                      <TableRow>

                        <TableCell sx={{ minWidth: 150 }}>Clinic Name</TableCell>
                        <TableCell sx={{ minWidth: 100 }}>Phone No.</TableCell>
                        <TableCell sx={{ minWidth: 100 }}> Address</TableCell>


                      </TableRow>
                    </TableHead>
                    {data.length ? (
                      <TableBody>
                        {data?.map((row, index) => (
                          <TableRow hover key={index} tabIndex={-1} style={{ cursor: 'pointer' }}>

                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={3}>
                                <Avatar alt="Remy Sharp" src={row?.clinicLogo} />
                                <Tooltip title={row?.name}>
                                  <OverflowTypography variant="subtitle2" noWrap>
                                    {row?.clinicName}
                                  </OverflowTypography>
                                </Tooltip>
                              </Stack>
                            </TableCell>
                           

                            <TableCell>
                              <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                                {row?.userPhoneNumber}
                                <Tooltip title="Copy">
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      navigator.clipboard.writeText(row?.userPhoneNumber);
                                    }}
                                  >
                                    <ContentCopyIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </OverflowTypography>
                            </TableCell>

                            <TableCell>
                              <OverflowTypography variant="subtitle2" noWrap>
                                {row?.clinicAddress}
                              </OverflowTypography>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableFooter>
                        <TableRow>
                          <TableCell style={{ width: '100%' }} align="center" colSpan={6}>
                            <Typography mt={2}>No user found.</Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </Scrollbar>
              )}
            </Box>
          </Box>
        </Card>
      </Container>


    </>
  );
}
