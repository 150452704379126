import { useMemo } from 'react';
// utils
import { fetcher, endpoints, poster, patcher } from '../utils/axios';

export async function getInterpreterDashboard(payload){
    const URL = endpoints.dashboard;
    return await fetcher([URL,{params:payload}])
}

export async function getDashboardTrends(payload){
    const URL = endpoints.trends;
    return await fetcher([URL,{params:payload}])
}

export async function getDashboardGroupChart(payload){
    const URL = endpoints.groupChart;
    return await fetcher([URL,{params:payload}])
}