//DBConfig.js
export const DBConfig = {
  name: "InterpretationDB",
  version: 1,
  objectStoresMeta: [
    {
      store: "report",
      storeConfig: { keyPath: "id", autoIncrement: false },
      storeSchema: [
          { name: "id", keypath: "id", options: { unique: true } },
          { name: "PatientId", keypath: "PatientId", options: { unique: false } },
          { name: "ReportId", keypath: "ReportId", options: { unique: false } },
          { name: "Date", keypath: "Date", options: { unique: false } },
          { name: "DatePosition", keypath: "DatePosition", options: { unique: false } },
          { name: "Name", keypath: "Name", options: { unique: false } },
          { name: "NamePosition", keypath: "NamePosition", options: { unique: false } },
          { name: "FirstName", keypath: "FirstName", options: { unique: false } },
          { name: "LastName", keypath: "LastName", options: { unique: false } },
          { name: "Age", keypath: "Age", options: { unique: false } },
          { name: "Gender", keypath: "Gender", options: { unique: false } },
          { name: "Mobile", keypath: "Mobile", options: { unique: false } },
          { name: "Height", keypath: "Height", options: { unique: false } },
          { name: "Weight", keypath: "Weight", options: { unique: false } },
          { name: "fileName", keypath: "fileName", options: { unique: false } },
          { name: "fileSize", keypath: "fileSize", options: { unique: false } },
          { name: "ImportedAt", keypath: "ImportedAt", options: { unique: false } },
          { name: "CompletedAt", keypath: "CompletedAt", options: { unique: false } },
          { name: "ReportType", keypath: "ReportType", options: { unique: false } },
          { name: "ReportFormat", keypath: "ReportFormat", options: { unique: false } },
          { name: "Status", keypath: "Status", options: { unique: false } }
      ]
    }
  ]
};