import * as React from "react";
import { useState, useEffect, useCallback } from "react";
// @mui material components
import { Grid, Button, Card, Typography, Tooltip, Alert, List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Label from 'components/label/Label';
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import axios from "axios";
// Others
import CustomInputLabel from "components/custom-input-label";
import customStyle from "customstyle.module.css";
import dayjs from "dayjs";

// Data
import { signatures_live as signatures } from "client-config";
import suggestedActionsData from "layouts/manual-Interpretation/data/SuggestedActions";
// import arryhthmiaData from "layouts/manual-Interpretation/data/arryhthmiaData";
// import stemiData from "layouts/manual-Interpretation/data/stemiData";
// import arrhythmiaTextData from "layouts/manual-Interpretation/data/arrhythmiaTextData";
import stemiTextData from "layouts/manual-Interpretation/data/stemiTextData";
import {useSnackbar} from 'components/snackbar'

// import Typography from "theme/overrides/Typography";

function CustomReportInputForm({ formKey, displayData, inputData, handleReportInputFormSubmit }) {
  const { enqueueSnackbar } = useSnackbar(); 
  const [formInput, setFormInputs] = useState(inputData);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [arrhythmiaSuggetions, setArrhythmiaSuggetions] = useState();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  useEffect(() => {
    document.getElementById("report-input-form");
    setPdfLoading(false)
  }, [inputData]);


  useEffect(() => {
    // Check if there are any changes in form values
    const isFormChanged = Object.keys(formInput).some(
      key => formInput[key] !== inputData[key]
    );
    setIsFormChanged(isFormChanged);
    if (isFormChanged) console.log("Form changed")
  }, [formInput]);

  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = function(event) {
        resolve(event.target.result);
      };

      reader.onerror = function(event) {
        reject(event.target.error);
      };

      reader.readAsDataURL(file);
    });
  }

  const handleChange = async (event) => {
    let interval = "";
    const name = event.target.name;
    const value = event.target.value;
    setFormInputs((values) => ({ ...values, [name]: value }));
    setPdfLoading(false)
    if (name.includes("_box")) {
      interval = value !== "" ? (parseFloat(value) * 40).toFixed(0) : "";
      setFormInputs((values) => ({ ...values, [name.replaceAll("_box", "")]: interval }));
    }
    if (name === "rr_interval_box") {
      setFormInputs((values) => ({
        ...values,
        ["heart_rate"]: interval !== "" ? (60000 / parseFloat(interval)).toFixed(0) : "",
        ["qtc"]: calculateQTC(formInput["qt_interval"], interval),
      }));
    }
    if (name === "qt_interval_box") {
      setFormInputs((values) => ({
        ...values,
        ["qtc"]: calculateQTC(formInput["qt_interval"], formInput["rr_interval"]),
      }));
    }
    if (name === "total_score") {
      suggetionsFromStemi({ total_score: value });
    }

    if (name === "interpreted_report_file") {
      const file = event.target.files[0];

      try {
        const base64String = await readFileAsync(file);
        setFormInputs((values) => ({
          ...values,
          ["interpreted_report_file"]: base64String,
          ["interpreted_report_file_metadata"]: {
            name: file?.name,
            size: file?.size,
            last_modified_at: file?.lastModified,
            type: file?.type
          },
        }));
      } catch (error) {
        console.error('Error reading file:', error);
      }
    }
  };

  function calculateQTC(qt, rr) {
    if (qt && rr) {
      return parseInt((parseFloat(qt) / 1000 / Math.sqrt(parseFloat(rr) / 1000)) * 1000);
    } else {
      return "";
    }
  }

  const handleFindingsMultiSelect = (event, selectedOptions, reason) => {
    let result = null;
    if (reason === "clear") {
      suggestedActions(null, true);
    } else if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find((option) => option.value === "select-all")) {
        result = items.filter((el) => el.value !== "select-all");
      } else {
        result = selectedOptions;
      }
      suggestedActions(result);
    }
  };
  function suggestedActions(selectedValues, isRemove) {
    let sugg = formInput["suggested_actions"] || "";
    let resultText = "";
    //Remove All prv selected text
    const newLine = "\n";
    for (var value of suggestedActionsData) {
      sugg = sugg.replaceAll(`${value}${newLine}`, "");
    }
    if ((selectedValues || []).length)
      selectedValues.map((item) => {
        if (!sugg.match(item)) resultText += `${item}${newLine}`;
      });
    if (resultText !== "") {
      sugg += resultText;
    }
    setFormInputs((values) => ({
      ...values,
      ["suggested_actions"]: sugg,
      findings: selectedValues,
    }));
  }

  const handleArrhymiaChange = (event, selectedOptions, reason) => {
    comonAutoCompleteChange(event, selectedOptions, reason, "Arrhymia");
  };

  const handleStemiChange = (event, selectedOptions, reason) => {
    commonAutoCompleteChange(event, selectedOptions, reason, "Stemi");
  };

  function commonAutoCompleteChange(event, selectedOptions, reason, elKey) {
    let result = null;
    if (reason === "clear") {
      if (elKey === "Arrhymia") {
        suggetionsFromArrhymia();
      } else if (elKey === "Stemi") {
        suggetionsFromStemi();
      }
    } else if (reason === "selectOption" || reason === "removeOption") {
      if (selectedOptions.find((option) => option.value === "select-all")) {
        //handleToggleSelectAll();
        // let result = [];
        result = items.filter((el) => el.value !== "select-all");
        // onChange(result);
      } else {
        //handleToggleOption(selectedOptions);
        result = selectedOptions;
        // onChange(selectedOptions);
      }
      const resultKey = {};
      result.map((item) => {
        resultKey[item.title] = true;
      });
      if (elKey === "Arrhymia") {
        suggetionsFromArrhymia(resultKey);
      } else if (elKey === "Stemi") {
        suggetionsFromStemi(resultKey);
      }
    }
  }

  function suggetionsFromArrhymia(resultKey) {
    let resultText = "";
    var sugg = formInput["suggested_actions"] || "";
    if (resultKey) {
      //1. Atrial fibrillation
      if (
        (resultKey["pWaveAbsent"] == true &&
          resultKey["rrVariabilityThresholdgreater25"] == true) ||
        resultKey["interpWaveRategreater250"] == true
      ) {
        resultText += "\n Atrial Fibrillation";
      }
      //2. Atrial Flutter
      if (resultKey["intraprategreater250_350"] == true) {
        resultText += "\n Atrial Flutter";
      }
      //3. Atrial Tachycardia
      // Check for atrial tachycardia based on the combined criteria
      if (
        resultKey["heartRateGreaterThan100"] == true &&
        resultKey["pWaveDurationBetween150And250"] == true &&
        resultKey["narrowQRS"] == true
      ) {
        resultText += "\n Atrial Tachycardia";
      }
      //4. Junctional Rhythm
      // Check for junctional rhythm based on the combined
      //criteria
      if (
        resultKey["pWaveAbsent"] == true &&
        resultKey["heartRateGreaterThan60"] == true &&
        resultKey["rrVariabilityLessThan10(rrVariability)"] == true &&
        resultKey["narrowQRS"] == true
      ) {
        resultText += "\n Junctional Rhythm";
      }
      //5. High Degree AV block
      // Check for high-degree AV block based on the combined criteria
      if (
        resultKey["prolongedPRI"] == true &&
        resultKey["rrVariabilityGreaterThan20(rrVariability)"] == true
      ) {
        resultText += "\n High-Degree AV Block";
      }
      //6. AV block
      // Check for AV block based on the combined criteria
      if (resultKey["prolongedPRI"] == true && resultKey["rrVariabilityLessThan20"] == true) {
        resultText += "\n AV Block";
      }
      //7. WPW syndrome(pre - excitation)
      // Check for Wolff-Parkinson-White (WPW) syndrome based on the combined
      //criteria
      if (resultKey["shortenedPRInterval"] && resultKey["lurredRWave"] && resultKey["wideQRS"]) {
        resultText += "\n Wolff-Parkinson-White (WPW) Syndrome";
      }
      //8. SuperaVenticular Ectopics
      // Check for superaventricular ectopics based on the combined criteria
      if (resultKey["rrVariabilityGreaterThan25"] == true && resultKey["narrowQRS"] == true) {
        resultText += "\n Superaventricular Ectopics";
      }
      //9. Ventricular Ectopics
      // Check for ventricular ectopics based on the combined criteria
      if (resultKey["rrVariabilityGreaterThan25"] == true && resultKey["wideQRS"] == true) {
        resultText += "\n Ventricular Ectopics";
      }
      //10.Superaventricular Tachycardia
      // Check for Supraventricular Tachycardia (SVT) based on the combined
      //criteria
      if (
        resultKey["heartRateGreaterThan100"] &&
        resultKey["absentPWave"] &&
        resultKey["narrowQRS"]
      ) {
        resultText += "\n Supraventricular Tachycardia";
      }
      //11.Ventricular tachycardia
      // Check for Ventricular Tachycardia based on the combined criteria
      if (
        resultKey["heartRateGreaterThan100"] &&
        resultKey["absentPWave"] &&
        resultKey["wideQRS"]
      ) {
        resultText += "\n Ventricular Tachycardia";
      }
      //12.Ventricular premature Complex
      // Check for Ventricular Premature Contraction (VPC) based on the combined
      //criteria
      if (
        resultKey["superimposedPWave"] &&
        resultKey["stDiscordant"] &&
        resultKey["rrVariabilityGreaterThan25"] &&
        resultKey["wideQRS"]
      ) {
        resultText += "\n Ventricular Premature Contraction (VPC) Detected";
      }
      //13.Sinus Bradycardia
      // Check for Sinus Bradycardia based on the criteria
      if (resultKey["heartRateLessThan60"]) {
        resultText += "\n Sinus Bradycardia";
      }
      //14.Sinus Tachycarida
      // Check for Sinus Bradycardia based on the criteria
      if (resultKey["heartRategGreaterThan60"]) {
        resultText += "\n Sinus Tachycardia";
      }
    } else {
      resultText = "";
    }
    //Remove All prv selected text
    for (var value of arrhythmiaSuggetions) {
      sugg = sugg.replaceAll("\n " + value, "");
    }
    if (resultText !== "") {
      sugg += resultText;
    }
    setFormInputs((values) => ({ ...values, ["suggested_actions"]: sugg }));
  }

  function suggetionsFromStemi(resultKey) {
    let resultText = "";
    var sugg = formInput["suggested_actions"] || "";
    if (resultKey) {
      //1. Left bundle Branch block
      // Check for Left Bundle Branch Block (LBBB) based on the combined criteria
      if (resultKey["wideQRS"] && resultKey["dominantSV1"] && resultKey["notchedMShapeRWaveV5V6"]) {
        resultText += "\n Left Bundle Branch Block (LBBB)";
      }
      //2. Right Branch Bundle Block
      // Check for Right Bundle Branch Block (RBBB) based on the combined criteria
      if (resultKey["rBySRatioGreaterThan1InV1"] && resultKey["wideQRS"]) {
        resultText += "\n Right Bundle Branch Block (RBBB)";
      }
      //3. Anterior Wall MI
      // Check for Anterior Wall Myocardial Infarction (MI) based on the combined criteria
      if (
        resultKey["stElevationOrDepressionInV1"] &&
        resultKey["stElevationOrDepressionInV2"] &&
        resultKey["stElevationOrDepressionInV3"]
      ) {
        resultText += "\n Anterior Wall Myocardial Infarction (MI)";
      }
      //4. Antero - septal MI
      // Check for Antero-septal Myocardial Infarction (MI) based on the combined criteria
      if (
        resultKey["stElevationOrDepressionInV1"] &&
        resultKey["stElevationOrDepressionInV2"] &&
        resultKey["stElevationOrDepressionInV3"] &&
        resultKey["stElevationOrDepressionInV4"] &&
        resultKey["stElevationOrDepressionInV5"]
      ) {
        resultText += "\n Antero-septal Myocardial Infarction (MI)";
      }
      //5. Antero - Apical MI
      // Check for Antero-apical Myocardial Infarction (MI) based on the combined criteria
      if (
        resultKey["stElevationOrDepressionInLeadI"] &&
        resultKey["stElevationOrDepressionInLeadAVL"] &&
        resultKey["stElevationOrDepressionInV2"] &&
        resultKey["stElevationOrDepressionInV3"] &&
        resultKey["stElevationOrDepressionInV4"]
      ) {
        resultText += "\n Antero-apical Myocardial Infarction (MI)";
      }
      //6. Inferior wall MI
      // Check for Inferior Wall Myocardial Infarction (MI) based on the combined criteria
      if (
        resultKey["stElevationOrDepressionInLeadII"] &&
        resultKey["stElevationOrDepressionInLeadIII"] &&
        resultKey["stElevationOrDepressionInLeadAVF"]
      ) {
        resultText += "\n Inferior Wall Myocardial Infarction (MI)";
      }
      //7. Inferolateral MI
      // Check for Infero - lateral Wall Myocardial Infarction(MI) based on the combined criteria
      if (
        resultKey["stElevationOrDepressionInLeadII"] &&
        resultKey["stElevationOrDepressionInLeadIII && stElevationOrDepressionInLeadAVF"] &&
        resultKey["stElevationOrDepressionInLeadI"] &&
        resultKey["§stElevationOrDepressionInLeadAVL"]
      ) {
        resultText += "\n Infero-lateral Wall Myocardial Infarction (MI)";
      }

      //8. Lateral MI
      // Check for Lateral Wall Myocardial Infarction (MI) based on the combined criteria
      if (
        resultKey["stElevationOrDepressionInLeadI"] &&
        resultKey["stElevationOrDepressionInLeadAVL"] &&
        resultKey["stElevationOrDepressionInV5"] &&
        resultKey["stElevationOrDepressionInV6"]
      ) {
        resultText += "\n Lateral Wall Myocardial Infarction (MI) Detected";
      }
      //9. Ischemia
      // Check for ischemia based on the criteria
      if (resultKey["stSegmentDepression"] || resultKey["tWaveInversion"]) {
        resultText += "\n Ischemia";
      }
      //10.Left Ventricular Hypertrophy
      // Define the threshold for LVH diagnosis
      //int lvhThreshold = 5; //
      //Check for LVH based on the total score
      if (resultKey["total_score"] > 5) {
        resultText += "\n Left Ventricular Hypertrophy(LVH)";
      }
      //11.ST abnormality
      // Check for ST-T abnormality based on the criteria
      if (resultKey["stSegmentElevation"]) {
        resultText += "\n ST-T Abnormality";
      }
    } else {
      resultText = "";
    }
    //Remove All prv selected text
    for (var value of stemiTextData) {
      sugg = sugg.replaceAll("\n " + value, "");
    }
    if (resultText !== "") {
      sugg += resultText;
    }
    setFormInputs((values) => ({ ...values, ["suggested_actions"]: sugg }));
  }

  function onGenerateReportClick(e) {
    e.preventDefault();
    if (isFormChanged) {
      setFormInputs({
        ...formInput,
        date_of_receiving_ecg: formInput?.date_of_receiving_ecg || dayjs(parseInt(formInput?.requested_at)).format("DD/MM/YYYY"),
        time_of_receiving_ecg: formInput?.time_of_receiving_ecg || dayjs(parseInt(formInput?.requested_at)).format("hh:mm A"),
        time_of_ecg_interpretation: formInput?.time_of_ecg_interpretation || dayjs().format("hh:mm A"),
        signature_of: formInput?.signature_of || ((signatures || []).length ? (signatures.find(item => item['default']) || {})["value"] : null),
        report_status: formInput["report_status"] || "normal"
      });
      setIsDialogOpen(true);
    } else {
      enqueueSnackbar("There is no update to save.", { variant: 'info' })
    }
  }

  async function onConfirmSubmitClick (e) {
    setPdfLoading(true);
    await handleReportInputFormSubmit(e, {
      ...formInput,
      setPdfLoading,
      date_of_receiving_ecg: formInput?.date_of_receiving_ecg || dayjs(parseInt(formInput?.requested_at)).format("DD/MM/YYYY"),
      time_of_receiving_ecg: formInput?.time_of_receiving_ecg || dayjs(parseInt(formInput?.requested_at)).format("hh:mm A"),
      time_of_ecg_interpretation: formInput?.time_of_ecg_interpretation || dayjs().format("hh:mm A"),
      signature_of: formInput?.signature_of || ((signatures || []).length ? (signatures.find(item => item['default']) || {})["value"] : null),
      report_status: formInput["report_status"] || "normal"
    })
    setPdfLoading(false);
    setIsDialogOpen(false);
  }

  const onCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  });

  return (
    <form
      id="report-input-form"
      onSubmit={ onGenerateReportClick }
    >
      <Grid container spacing={2} style={{ position: "relative" }} margin={0} width="100%">
        {/* <div style={{ padding: '15px', position: 'sticky', top: 0, zIndex: 99999, width: '100%' }}> */}
          {formInput?.completed_at ? (
            <Label color='success' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 10px' }}>
              <CustomInputLabel>
                Report generated at:{" "}
                <b>{dayjs(parseInt(formInput?.completed_at)).format("DD MMM, YYYY hh:mm A")}</b>
              </CustomInputLabel>
            </Label>
          ) : 
          <Label color='info' sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '0 10px' }}>
            <CustomInputLabel>
              Report taken at:{" "}
              <b>{dayjs(parseInt(displayData?.report_timestamp)).format("DD MMM, YYYY hh:mm A")}</b>
            </CustomInputLabel>
          </Label>
          }
        {/* </div> */}
        <Card className={customStyle.dotedBorder}>
          <Grid container style={{ padding: "15px" }} spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <DatePicker
                  key={`receive-date-${formKey}`}
                  label="Date of Receiving ECG"
                  name="date_of_receiving_ecg"
                  maxDate={ dayjs() }
                  value={
                    formInput?.date_of_receiving_ecg
                      ? dayjs(formInput?.date_of_receiving_ecg, "DD/MM/YYYY")
                      : dayjs(parseInt(formInput?.requested_at))
                  }
                  format="DD/MM/YYYY"
                  closeOnSelect={true}
                  onAccept={(event) =>
                    handleChange({
                      target: {
                        name: "date_of_receiving_ecg",
                        value: dayjs(event).format("DD/MM/YYYY"),
                      },
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DesktopTimePicker
                  key={`receive-time-${formKey}`}
                  label="Time of Receiving ECG"
                  openTo="hours"
                  size="small"
                  value={
                    formInput?.time_of_receiving_ecg
                      ? dayjs(formInput?.time_of_receiving_ecg, "hh:mm A")
                      : dayjs(parseInt(formInput?.requested_at))
                  }
                  onAccept={(event) =>
                    handleChange({
                      target: { name: "time_of_receiving_ecg", value: dayjs(event).format("hh:mm A") },
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DesktopTimePicker
                  key={`gen-time-${formKey}`}
                  label="Time of ECG Interpretation"
                  openTo="hours"
                  value={
                    formInput?.time_of_ecg_interpretation
                      ? dayjs(formInput?.time_of_ecg_interpretation, "hh:mm A")
                      : dayjs()
                  }
                  onAccept={(event) =>
                    handleChange({
                      target: {
                        name: "time_Of_ecg_interpretation",
                        value: dayjs(event).format("hh:mm A"),
                      },
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined"
                fullWidth
                label="First Name"
                name="first_name"
                value={formInput?.first_name || ""}
                onChange={handleChange}
                size="small" />
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined"
                fullWidth
                label="Last Name"
                name="last_name"
                value={formInput?.last_name || ""}
                onChange={handleChange}
                size="small" />
            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined"
                fullWidth
                label="Age"
                name="age"
                value={formInput?.age}
                onChange={handleChange}
                size="small" />
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth
                select
                name="gender"
                label="Gender"
                value={formInput?.gender || "-"}
                SelectProps={{
                  native: true,
                }}
                onChange={handleChange}
                size="small"
              >
                <option key="-" value="-" selected disabled>Select</option>
                <option key="male" value="male" >Male</option>
                <option key="female" value="female" >Female</option>
                <option key="other" value="other" >Other</option>
              </TextField>
              {/* <TextField variant="outlined"
                fullWidth
                label="Gender"
                name="gender"
                value={formInput?.gender || ""}
                onChange={handleChange}
                size="small" /> */}
            </Grid>
            {/* <Grid item xs={6}>
              <TextField fullWidth
                select
                name="gender"
                label="gender"
                value={formInput?.gender || "Select Gender"}
                SelectProps={{
                  native: true,
                }}
                onChange={handleChange}
                size="small"
              >
                <option key="male" value="male">Male</option>
                <option key="female" value="female">Female</option>
                <option key="other" value="other">Other</option>
              </TextField>

            </Grid> */}
          </Grid>
        </Card>

        <Card className={customStyle.dotedBorder}>
          <Grid container style={{ padding: "15px" }} spacing={2}>
            <Grid item xs={6}>
              <TextField fullWidth
                select
                name="any_symptom"
                label="Any Symptoms"
                value={formInput["any_symptom"] || "Choose Anyone"}
                SelectProps={{
                  native: true,
                }}
                onChange={handleChange}
                size="small"
              >
                <option key="Choose Anyone" value="Choose Anyone" selected disabled>Choose Anyone</option>
                <option key="Chest Pain" value="Chest Pain" >Chest Pain</option>
                <option key="Shortness of Breath" value="Shortness of Breath" >Shortness of Breath</option>
                <option key="Any Other" value="Any Other" >Any Other</option>
                <option key="No Symptoms" value="No Symptoms" >No Symptoms</option>

              </TextField>

            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth
                select
                name="nature_of_ecg"
                label="Nature Of ECG"
                value={formInput["nature_of_ecg"] || "Choose Anyone"}
                SelectProps={{
                  native: true,
                }}
                onChange={handleChange}
                size="small"
              >
                <option key="Choose Anyone" value="Choose Anyone" selected disabled>Choose Anyone</option>
                <option key="Normal ECG" value="Normal ECG" >Normal ECG</option>
                <option key="Abnormal ECG" value="Abnormal ECG" >Abnormal ECG</option>
                <option key="Critical ECG" value="Critical ECG" >Critical ECG</option>

              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField fullWidth
                select
                name="classification_of_abnormality"
                label="Classification of Abnormality"
                value={formInput["classification_of_abnormality"] || "Choose Anyone"}
                SelectProps={{
                  native: true,
                }}
                onChange={handleChange}
                size="small"
              >
                <option key="Choose Anyone" value="Choose Anyone" selected disabled>Choose Anyone</option>
                <option key="Arrhythmia" value="Arrhythmia" >Arrhythmia</option>
                <option key="Ischemia" value="Ischemia" >Ischemia</option>
                <option key="Myocardial Infraction" value="Myocardial Infraction" >Myocardial Infraction</option>
                <option key="ST-T Changes" value="ST-T Changes" >ST-T Changes</option>
                <option key="J Point Elevation" value="J Point Elevation" >J Point Elevation</option>
                <option key="RBBB" value="RBBB" >RBBB</option>
                <option key="LBBB" value="LBBB" >LBBB</option>
                <option key="None" value="None" >None</option>

              </TextField>

            </Grid>
            <Grid item xs={6}>
              <TextField variant="outlined"
                fullWidth
                label="Total Score"
                name="total_score"
                value={formInput["total_score"]}
                onChange={handleChange}
                size="small" />
            </Grid>
          </Grid>
        </Card>
        <Card className={customStyle.dotedBorder}>
          <Grid container style={{ padding: "15px" }} spacing={2}>
            <Grid item xs={12}>

              <Autocomplete
                multiple
                id={`choose-findings-${formKey}`}
                onChange={handleFindingsMultiSelect}
                name="findings"
                value={formInput["findings"] || []}
                options={suggestedActionsData}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => {
                  return <TextField {...params} name="ArrhythmiaKey" label="Select Finding(s) from List" placeholder="Choose Multiple" />;
                }}
              />

            </Grid>
            <Grid item xs={12}>
              <TextField variant="outlined"
                name="suggested_actions"
                label="Suggested Actions"
                value={formInput["suggested_actions"] || ""}
                onChange={handleChange}
                placeholder="Type your suggested action"
                multiline
                fullWidth
                rows={2}
                size="small" />

            </Grid>
            {/* Start PR Interval element */}
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="PR Interval Box"
                name="pr_interval_box"
                placeholder="Eg: 4.2"
                value={formInput["pr_interval_box"] || ""}
                onChange={handleChange}
                size="small"
              />

            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                X 40 =
              </Typography>


            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="PR Interval"
                name="pr_interval"
                inputProps={{ tabIndex: "-1" }}
                readOnly={true}
                placeholder="PR Interval"
                value={formInput["pr_interval"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                ms
              </Typography>
            </Grid>
            {/* End Start PR Interval element */}

            {/* Start QRS Interval element */}
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="QRS Interval Box"
                name="qrs_interval_box"
                placeholder="Eg: 4.2"
                value={formInput["qrs_interval_box"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                X 40 =
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="QRS Interval"
                name="qrs_interval"
                inputProps={{ tabIndex: "-1" }}
                readOnly={true}
                placeholder="QRS Interval"
                value={formInput["qrs_interval"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                ms
              </Typography>
            </Grid>
            {/* End QRS Interval element */}

            {/* Start QT Interval element */}
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="QT Interval Box"
                name="qt_interval_box"
                placeholder="Eg: 4.2"
                value={formInput["qt_interval_box"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                X 40 =
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="QT Interval"
                name="qt_interval"
                inputProps={{ tabIndex: "-1" }}
                readOnly={true}
                placeholder="QTInterval"
                value={formInput["qt_interval"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                ms
              </Typography>
            </Grid>
            {/* End QT Interval element */}

            {/* Start ST Interval element */}
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="ST Interval Box"
                name="st_interval_box"
                placeholder="Eg: 4.2"
                value={formInput["st_interval_box"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                X 40 =
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="ST Interval"
                name="st_interval"
                inputProps={{ tabIndex: "-1" }}
                readOnly={true}
                placeholder="ST Interval"
                value={formInput["st_interval"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                ms
              </Typography>
            </Grid>
            {/* End ST Interval element */}

            {/* Start RR Interval element */}
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="RR Interval Box"
                name="rr_interval_box"
                placeholder="Eg: 4.2"
                value={formInput["rr_interval_box"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                X 40 =
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField variant="outlined"
                label="RR Interval"
                name="rr_interval"
                inputProps={{ tabIndex: "-1" }}
                readOnly={true}
                placeholder="RR Interval"
                value={formInput["rr_interval"] || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <Typography mt={1} variant="caption" display="block" gutterBottom>
                ms
              </Typography>
            </Grid>
          </Grid>
        </Card>
        {/* End RR Interval element */}

        <Card className={customStyle.dotedBorder} sx={{ width: '100%' }}>
          <Grid container style={{ padding: "15px" }} spacing={2}>
            <Grid item xs={6}>
              <Typography mt={1} variant="caption" gutterBottom>
                Heart Rate = <b>{formInput["heart_rate"] || ""}</b>
                <br /> <em>[HR = 60000 / RR (ms)]</em>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField display="block" fullWidth
                select
                name="heart_rate_axis"
                label="Heart Rate Axis"
                value={formInput["heart_rate_axis"] || "Choose Axis"}
                SelectProps={{
                  native: true,
                }}
                onChange={handleChange}
                size="small"
              >
                <option key="Choose Axis" value="Choose Axis" selected disabled>Choose Anyone</option>
                <option key="Normal" value="Normal" >Normal</option>
                <option key="Left Axis Deviation" value="Left Axis Deviation" >Left Axis Deviation</option>
                <option key="Right Axis Deviation" value="Right Axis Deviation" >Right Axis Deviation</option>
                <option key="Extreme Axis" value="Extreme Axis" >Extreme Axis</option>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Typography mt={1} variant="caption" sx={{ width: '100%' }} gutterBottom>
                QTc = QT / √RR (ms) = <b>{formInput["qtc"] || ""}</b>
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card xs={12} className={customStyle.dotedBorder}>
          <Grid container style={{ padding: "15px" }} spacing={2}>
            { inputData?.interpreted_report_file_metadata?.name ? <Grid item xs={12} textAlign="center">
              <Alert severity="success" sx={{ whiteSpace: 'break-word', wordBreak: 'break-all' }}>
                  <Tooltip title={ inputData?.interpreted_report_file_metadata?.name }>
                    <Typography component="div">
                        Uploaded report: 
                        <b> { inputData?.interpreted_report_file_metadata?.name }</b>
                    </Typography>
                  </Tooltip>
              </Alert>
            </Grid> : null }
            <Grid item xs={12}>
              <TextField
                  name="interpreted_report_file"
                  type="file"
                  label="Upload interpreted report (optional)"
                  fullWidth
                  helperText={ `Note: ${ inputData?.interpreted_report_file_metadata?.name ? "Select to upload the updated report (if any)." : "If already interpreted then upload the report pdf." }` }
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: '.pdf' }}
                  onChange={ handleChange }
              />
            </Grid>
            <Grid item xs={12} sx={{ marginLeft: "15px", borderBottom: '1px dotted #000' }}></Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <CustomInputLabel>Select signature</CustomInputLabel>
                <Select
                  name="signature_of"
                  value={formInput["signature_of"] || ((signatures || []).find(item => item["default"]) || {})["value"]}
                  onChange={handleChange}
                  fullWidth
                  className={[customStyle.onFocus, "selectInputStyle"]}
                >
                  {(signatures || []).length ? signatures.map((item, index) =>
                    <MenuItem key={`sign-${index}`} value={item.value} selected={item["default"] || false}>{item.name}</MenuItem>
                  ) : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex" }}>
                <FormControl>
                  <CustomInputLabel>Select report type (for segregation)</CustomInputLabel>
                  <RadioGroup row name="report_status" aria-labelledby="demo-row-radio-buttons-group-label" onChange={handleChange} value={formInput["report_status"] || "normal"}>
                    <FormControlLabel value="normal" control={<Radio />} label="Normal report" />
                    <FormControlLabel value="abnormal" control={<Radio />} label="Abnormal report" />
                    <FormControlLabel value="critical" control={<Radio />} label="Critical report" />
                    <FormControlLabel value="retake" control={<Radio />} label="Retake" />
                  </RadioGroup>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <div className={customStyle.centerBtn}>
        <Button variant="contained" type="submit" color="info" disabled={pdfLoading ? true : false}>
        {pdfLoading ? 'Loading....' : 'Save & Generate Report'}
        </Button>
      </div>
      <Dialog open={isDialogOpen} onClose={onCloseDialog} disableScrollLock={false}>
        <DialogTitle>Confirm Save & Generate Report</DialogTitle>
        <DialogContent>
            <Typography>Before generating the report, please review the following details to ensure accuracy:</Typography>
            <List>
              <ListItem key={0}>
                <b>• Signature:</b><ListItemText sx={{ pl: 2 }} primary={ `${ signatures.find(sign => sign?.value === formInput?.signature_of)?.name || 'Not selected' }` } />
              </ListItem>
              <ListItem key={1}>
                <b>• Report Status: </b><ListItemText sx={{ pl: 2 }} primary={ `${ formInput?.report_status || 'Not selected' }` } />
              </ListItem>
          </List>
          <Typography variant="body1">
            Please confirm the details above are correct before proceeding.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3, gap: 3 }}>
          <Button onClick={ onCloseDialog } color="primary" variant="outlined">
            Cancel
          </Button>
          
          <Button onClick={ onConfirmSubmitClick } color="primary" variant="contained" disabled={pdfLoading ? true : false} autoFocus >
          {pdfLoading ? 'Saving...' : 'Confirm' }
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default CustomReportInputForm;
//Backup element for future use so please don't remove below lines
{/* <Grid pt={ 2 } xs={12}>
          <FormControl fullWidth>
            <CustomInputLabel>Arrhythmia</CustomInputLabel>
            <Autocomplete
              multiple
              id={`choose-arrhythmia-${formKey}` }
              className={customStyle.onFocus}
              onChange={handleArrhymiaChange}
              name="ArrhythmiaKey"
              options={arryhthmiaData}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props} data-key='ArrhythmiaKey'>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              renderInput={(params) => {
                return (<TextField {...params} name='ArrhythmiaKey' placeholder="Choose Multiple" />)
              }}
            />
          </FormControl>
        </Grid> 
        <Grid pt={ 2 } xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              multiple
              id={`choose-stemi-${formKey}` }
              className={customStyle.onFocus}
              onChange={handleStemiChange}
              name="StemiKey"
              data-key='StemiKey'
              options={stemiData}
              disableCloseOnSelect
              getOptionLabel={(option) => option.title}
              renderOption={(props, option, { selected }) => (
                <li {...props} data-key='StemiKey'>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              renderInput={(params) => {
                return (<TextField {...params} name='StemiKey' data-key='StemiKey' placeholder="Choose Multiple" />)
              }}
            />
          </FormControl>
        </Grid> */}