// src/firebaseMessaging.js
import { messaging } from './firebase';
import { getToken, onMessage, getMessaging } from 'firebase/messaging';

const requestNotificationPermission = async () => {
  try {
    // Get the messaging instance from Firebase
    const messaging = await getMessaging();

    // Requesting permission using the Notification API
    const permission = await Notification.requestPermission();
    if (!messaging) {
      console.log('[generateToken]:: Messaging not available');
      return;
    }
    if (permission !== 'granted') {
      console.log('[generateToken]:: Permission not granted');
      return;
    }
    if (!navigator.serviceWorker) {
      console.log('[generateToken]:: Service worker not available');
      return;
    }
    // Get the active service worker registration
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;
    if (serviceWorkerRegistration.active?.state === 'activated') {
      // Get the token using Firebase messaging
      const fcmToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        serviceWorkerRegistration
      });

      // Log the generated token
      console.log('Token generated.');
      return fcmToken;
    }
  } catch (error) {
    console.error('Error requesting notification permission:', error);
  }
};

const handleForegroundMessages = () => {
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // Customize the UI to handle the incoming message
    });
  };

export { requestNotificationPermission, handleForegroundMessages };
