
// utils
import { fetcher, endpoints,patcher} from '../utils/axios';

export async function getAllDevices(payload){
    const URL = endpoints.device.getDevice;
    return await fetcher([URL, {params:payload}] )
}

export async function getDeviceReports(payload){
    const URL = endpoints.device.getReports+`${payload.device_id}`;
    return await fetcher([URL, {}] )
}

export async function downloadReportInJson(payload){
    const URL = endpoints.device.report;
    return await fetcher([URL, {params:payload}] )
}

export async function disableOrEnableDevice(payload){
    const URL = endpoints.device.disableEnable +`${payload.device_id}`;
    return await patcher([ URL , payload])
}

export async function getMonthlyDataOrPercentages(payload){
    const URL = endpoints.testdata.getDataPercentage;
    return await fetcher([URL, {params:payload}] )
}

export async function getDeviceMonthlyCount(payload){
    const URL = endpoints.testdata.getMonthlyData;
    return await fetcher([URL, {params:payload}] )
}
export async function getMonthlyTestsAndTheirCount(payload){
    const URL = endpoints.testdata.getMonthlyData;
    return await fetcher([URL, {params:payload}] )
}