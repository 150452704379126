import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import { Grid, Toolbar, Box, Tooltip, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: theme.spacing(2, 1, 1),
}));

AssociatedUserListToolbar.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  handleStartDateChange: PropTypes.func,
  isSmall: PropTypes.bool,
};

export default function AssociatedUserListToolbar({
  startDate,
  endDate,
  handleStartDateChange,
  isSmall,
}) {
  const hasSelectedItems = false;

  return (
    <StyledRoot
      sx={{
        ...(hasSelectedItems && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {hasSelectedItems ? (
        <Tooltip title="Clear">
          <ClearIcon
            sx={{ cursor: 'pointer' }}
            // Define your onClick logic here
            onClick={(e) => console.log("Clear action")}
          />
        </Tooltip>
      ) : (
        <Box sx={{ width: '100%' }}>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginX: '20px',
              flexDirection: isSmall ? 'column-reverse' : 'row',
              gap: isSmall ? '1rem' : '20px', // Increase spacing when not in small mode
            }}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={1} sx={{ width: '100%' }}>
                {/* Add your search bar component here */}
                <Grid item xs={12}>
                  {/* Example search bar */}
                  <input type="text" placeholder="Search..." style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Stack direction="row" spacing={1}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: '150px' }}
                      label="Start Date"
                      value={startDate}
                      format="DD/MM/YYYY"
                      onChange={(date) => handleStartDateChange(date, 'start')}
                    />
                    {endDate && (
                      <DatePicker
                        sx={{ width: '150px' }}
                        label="End Date"
                        value={endDate}
                        format="DD/MM/YYYY"
                        onChange={(date) => handleStartDateChange(date, 'end')}
                      />
                    )}
                  </LocalizationProvider>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </StyledRoot>
  );
}
