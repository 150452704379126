import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, MenuItem, Container, Stack, CircularProgress, FormControl, InputLabel, Typography } from '@mui/material';
import Select from '@mui/material/Select';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { getB2Busers } from 'api/users';
import { getInterpreterDashboard, getDashboardTrends, getDashboardGroupChart } from 'api/dashboard';
import { useSnackbar } from '../components/snackbar';
// ----------------------------------------------------------------------

export default function ViewB2BDashboard() {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [trendsLoading, setTrendsLoading] = useState(true);
  const [pieLoading, setPieLoading] = useState(true);
  const [allCount, setAllCount] = useState({ todayCompleted: 0, todayRequested: 0, totalCompleted: 0, totalPending: 0 });
  const [trends, setTrends] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [dataFetched, setDataFetched] = useState(false);
  const { todayReports, todayCompleted, todayRequested, totalCompleted, totalPending, totalReports } = allCount;

  useEffect(() => {
    if (!dataFetched) {
      getB2BData();
    }
  }, [dataFetched]);

  const handleB2BUserChange = (event) => {
    const business_id = event.target.value;
    setSelectedClient(business_id);
  };

  const getB2BData = async () => {
    try {
      const response = await getB2Busers();
      console.log("B@B",response)
      setLoading(false);
      if (response?.success) {
        setData(response?.data || []);
        const defaultClient = response.data.length > 0 ? response.data[0].api_key : '';
        setSelectedClient(defaultClient);
        setDataFetched(true);
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to fetch B2B users.", { variant: 'error' });
    }
  };

  useEffect(() => {
    if (selectedClient) {
      fetchData(selectedClient);
      fetchTrendsData(selectedClient);
      fetchPieChartData(selectedClient);
    }
  }, [selectedClient]);

  const fetchData = async (business_id) => {
    try {
      setLoading(true); // Set loading to true when fetching new data
      const response = await getInterpreterDashboard({ business_id });
      setLoading(false);
      if (response && response.success) {
        setAllCount(response.data || { todayCompleted: 0, todayRequested: 0, totalCompleted: 0, totalPending: 0 });
      } else {
        enqueueSnackbar(response.message || "Error fetching data", { variant: 'error' });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchTrendsData = async (business_id) => {
    try {
      setTrendsLoading(true); // Set loading to true when fetching new data
      const response = await getDashboardTrends({ business_id });
      setTrendsLoading(false);
      if (response && response.success) {
        setTrends(response?.data || []);
      }
    } catch (error) {
      setTrendsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchPieChartData = async (business_id) => {
    try {
      setPieLoading(true); // Set loading to true when fetching new data
      const response = await getDashboardGroupChart({ business_id });
      setPieLoading(false);
      if (response && response.success) {
        if (response.data) {
          const mapGroupChartData = [];
          for (const key in response?.data) {
            mapGroupChartData.push({ label: key, value: response?.data[key] })
          }
          setGroupData(mapGroupChartData);
        }
      }
    } catch (error) {
      setPieLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Dashboard | ${process.env.REACT_APP_PORTAL_NAME}`}</title>
      </Helmet>
      <Container maxWidth="xl">
        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
          <InputLabel id="demo-select-small-label">B2B Client</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            label="B2B Client"
            value={selectedClient}
            onChange={handleB2BUserChange}
          >
            {data.map((client) => (
              <MenuItem key={client.api_key} value={client.api_key}>
                { `${client.business_name} (${client.name})` }
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedClient ? (
        <Grid container spacing={3} pt={2}>
          <Grid item xs={12} sm={4} md={2}>
            <AppWidgetSummary title="Total tests" total={totalReports || 0} loading={loading} icon={'arcticons:onlyoffice-documents'} />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <AppWidgetSummary title="Today's total tests" total={todayReports || 0} loading={loading} color="info" icon={'uiw:document'} />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <AppWidgetSummary title="Today's requested" total={todayRequested || 0} loading={loading} icon={'solar:documents-bold-duotone'} />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <AppWidgetSummary title="Today's received" total={todayCompleted || 0} loading={loading} color="info" icon={'healthicons:i-documents-accepted'} />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <AppWidgetSummary title="Received in last 30 days" total={totalCompleted || 0} loading={loading} color="warning" icon={'uiw:document'} />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <AppWidgetSummary title="Pending in last 30 days" total={totalPending || 0} loading={loading} color="error" icon={'arcticons:onlyoffice-documents'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Monthly Trends"
              // subheader="(+43%) than previous day"
              loading={trendsLoading}
              chartLabels={trends?.map(item => item?.month)}
              chartData={[
                {
                  name: 'Requested',
                  type: 'area',
                  fill: 'gradient',
                  data: trends?.map(item => item?.requested),
                },
                {
                  name: 'Received',
                  type: 'line',
                  fill: 'solid',
                  data: trends?.map(item => item?.completed),
                },
                {
                  name: 'Pending',
                  type: 'column',
                  fill: 'gradient',
                  data: trends?.map(item => (item?.requested - item?.completed)),
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Report Status"
              loading={pieLoading}
              chartData={groupData}
              chartColors={[
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>
        </Grid>):(<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
      <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
        <Typography>Please Select A Client</Typography>
      </Stack>
    </div>)}
      </Container>

    </>
  );
}
