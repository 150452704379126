export default function DevicetestData() {
    const data = [
        {
            "channelDeviceIds": 'SPPR-DN01-2409080134',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080162',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080134',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080170',
            "channel": "FBA",
        },
        
        {
            "channelDeviceIds": 'SPNE-DN01-2401160356',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401270037',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160278',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080162',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080150',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160195',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401270051',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160396',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080180',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080142',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160230',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160078',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160042',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080119',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080160',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160154',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160262',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160338',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080145',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080174',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160320',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401270042',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160286',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080145',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080125',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160162',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401270039',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401270070',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080190',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080158',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160047',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401270035',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160165',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPPR-DN01-2409080159',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160233',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401270050',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401270034',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160022',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401270052',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401270071',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160302',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401270036',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160196',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160276',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160119',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160202',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160131',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160397',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160088',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160358',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160339',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160312',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160398',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160053',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160284',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160370',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160018',
            "channel": "FBA",
        },
        {
            "channelDeviceIds": 'SPNE-DN01-2401160035',
            "channel": "FBA",
        },
       
        
    ];
    

    return data;
}
