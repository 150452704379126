import PropTypes from 'prop-types';
import ClearIcon from '@mui/icons-material/Clear';
import { styled } from '@mui/material/styles';
import { Grid, Toolbar, Box, Tooltip, Stack, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 1, 1),
}));

AssociatedUserListToolbar.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  handleStartDateChange: PropTypes.func,
  isSmall: PropTypes.bool,
};

export default function AssociatedUserListToolbar({
  startDate,
  endDate,
  handleStartDateChange,
  isSmall,
  total
}) {
  return (
    <StyledRoot>
      <Box sx={{ width: '100%' }}>
        <Grid
          sx={{
            alignItems: 'center',
          }}
        >
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box>
                <Typography>Total test(s) performed: <b>{ total }</b></Typography>
              </Box>
              <Stack direction="row" spacing={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '150px' }}
                    label="Start Date"
                    value={startDate}
                    format="DD/MM/YYYY"
                    onChange={(date) => handleStartDateChange(date, 'start')}
                  />
                  {endDate && (
                    <DatePicker
                      sx={{ width: '150px' }}
                      label="End Date"
                      value={endDate}
                      format="DD/MM/YYYY"
                      onChange={(date) => handleStartDateChange(date, 'end')}
                    />
                  )}
                </LocalizationProvider>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </StyledRoot>
  );
}
