// @mui material components
import { Grid, Card, ButtonGroup, Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from '@mui/icons-material/Download';
import ShareIcon from '@mui/icons-material/Share';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

// Interpretation dashboards components
import dayjs from "dayjs";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useIndexedDB } from "react-indexed-db-hook";
import {
  getAllData,
  getDataById,
  addNewData,
  updateData,
  deleteById,
  getAllByCursor,
} from "hooks/IndexDB";
import { blobToURL } from "utils/Utils";
import PDFExtractorLib from "hooks/PDFExtractor";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument, rgb } from "pdf-lib";
import { useEffect, useRef, useState } from "react";
import { Panel, PanelGroup } from "react-resizable-panels";
import PanelResizeHandle from "hooks/ResizeHandler";
import { generateReport, generateLink } from "hooks/GenerateReportPDF";
import PagingControl from "components/PagingControl";
import DraggableText from "components/DraggableText";
import panelStyles from "panel-style.module.css";
import { useNavigate, useLocation } from "react-router-dom";

// Data
import PatientList from "examples/Lists/PatientList";
import ReportInputForm from "layouts/report-input-form";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Interpretation() {
  const navigate = useNavigate();
  // edit pdf
  const storeDB = useIndexedDB("report");
  const [allFilesData, setAllFilesData] = useState([]);
  const [allFilesBlob, setAllFilesBlob] = useState([]);
  const [allFilesUrl, setAllFilesUrl] = useState([]);
  const [renderedFileIndex, setRenderedFile] = useState(0);
  const [pdf, setPdf] = useState(null);
  const [position, setPosition] = useState(null);
  const [textInputVisible, setTextInputVisible] = useState(false);
  const [importError, setImportError] = useState("");
  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageDetails, setPageDetails] = useState(null);
  const documentRef = useRef(null);
  const [renderAllPages, setRenderAllPages] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    (async () => loadpdf())();
  }, []);

  const styles = {
    dropContainer: {},
    container: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
    },
    sigBlock: {
      display: "inline-block",
    },
    documentBlock: {
      maxWidth: 800,
      margin: "0px",
      marginTop: 5,
      // border: "1px solid #999",
      display: "flex",
      width: "100%",
      justifyContent: "center",
      height: "90vh",
      overflow: "auto",
    },
    controls: {
      backgroundColor: "#ffff",
      // maxWidth: 800,
      margin: "10px",
      display: "flex",
      justifyContent: "space-between",
      position: "sticky",
      zIndex: 2,
      width: "100%"
    }
  };

  const loadpdf = async () => {
    if (!location || !location.state) {
      return setImportError("Please import/select any report(s) for interpretation.");
    }
    const allFiles = [];
    for (const file of location.state) {
      const URL = await blobToURL(file);
      const data = await PDFExtractorLib(pdfjs, URL, 1);
      if (data && data?.FirstName && (data?.date || data?.Date)) {
        allFiles.push(URL);
        data.id = `${data.FirstName.replace(/\s/g, "_")}_${dayjs(data.date || data.Date).valueOf()}`;
        data.Name = `${data.FirstName}${data.LastName ? " " + data.LastName : ""}`;
        const reportData = { fileName: file.name, fileSize: file.size, ...data, Status: "pending" };
        const isReportExist = await getDataById(storeDB, data["id"]);
        if (!isReportExist) {
          const addedResult = await addNewData(storeDB, reportData);
          if (addedResult && addedResult.data) {
            setAllFilesData((prevFiles) => {
              return [...prevFiles, reportData];
            });
          }
        } else {
          setAllFilesData((prevFiles) => {
            return [...prevFiles, isReportExist];
          });
        }
        setAllFilesBlob(location.state);
        setAllFilesUrl(allFiles);
        setPdf(allFiles[renderedFileIndex]);
      } else {
        setImportError("Please import/select valid Spandan ECG report(s) for interpretation.");
      }
    }
    return;
  };

  const handleGenerateReport = async (e) => {
    setLoading(true);
    const genReport = await generateReport(
      allFilesUrl[renderedFileIndex],
      allFilesData[renderedFileIndex] || {},
      true
    ); //true false on server upload
    if (genReport && genReport["reportPdf"]) {
      const reportUrl = await blobToURL(genReport["reportPdf"]);
      setPdf(reportUrl);
      allFilesUrl[renderedFileIndex] = reportUrl;
      setAllFilesUrl(allFilesUrl);
      allFilesBlob[renderedFileIndex] = genReport["reportPdf"];
      setAllFilesBlob(allFilesBlob);
      setPageNum(0);
      setLoading(false);
      return genReport["reportPdf"];
    } else setLoading(false);
  };

  //Handle form submit
  const handleReportInputFormSubmit = async (event, formInput) => {
    event.preventDefault();
    const dataToUpdate = {
      ...allFilesData[renderedFileIndex],
      ...formInput,
      Name: `${formInput["FirstName"]} ${formInput["LastName"]}`,
      CompletedAt: allFilesData[renderedFileIndex]["CompletedAt"] || new Date(),
      Status: "completed",
    };
    const updatedResult = await updateData(storeDB, dataToUpdate);
    if (updatedResult && updatedResult["data"]) {
      allFilesData[renderedFileIndex] = dataToUpdate;
      setAllFilesData(allFilesData);
      return handleGenerateReport(event);
    } else {
      console.error(updatedResult["error"]);
    }
  };

  const handleShareClick = async (e) => {
    if (allFilesData[renderedFileIndex].id) {
      const reportPdf = await handleReportInputFormSubmit(e, allFilesData[renderedFileIndex]);
      setLoading(true);
      const reportLink = await generateLink(allFilesData[renderedFileIndex]["id"], reportPdf, allFilesData[renderedFileIndex]["ReportType"]);
      if (reportLink["url"]) {
        const dataToUpdate = {
          ...allFilesData[renderedFileIndex],
          ReportLink: reportLink["url"],
          CompletedAt: allFilesData[renderedFileIndex]["CompletedAt"] || new Date(),
          Status: "completed",
        };
        const updatedResult = await updateData(storeDB, dataToUpdate);
        if (updatedResult && updatedResult["data"]) {
          allFilesData[renderedFileIndex] = dataToUpdate;
          setAllFilesData(allFilesData);
        } else {
          console.error(updatedResult["error"]);
        }

        const pdfLink = reportLink["url"];
        // Create the WhatsApp message with the PDF link
        const message = encodeURIComponent(
          `Dear ${allFilesData[renderedFileIndex]?.FirstName},\n\nYour ECG reports is now generated.\nClick here to download - \n${pdfLink}\n\n*Thanks*,\n*Team Spandan ECG*.\nwww.spandan.in`
        );

        // Open WhatsApp with the pre-filled message
        window.open("https://wa.me/?text=" + message, "_balnk");
      } else console.error(reportLink["error"]);
    }
    setLoading(false);
  };

  function downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handlePatientListClick = (e, index) => {
    (allFilesData || []).length &&
      allFilesData[index] &&
      allFilesData[index]["Status"] !== "completed"
      ? setPageNum(1)
      : setPageNum(0);
    setRenderedFile(index);
    setPdf(allFilesUrl[index]);
  };

  const handleBackClick = (e) => {
    setTextInputVisible(false);
    setPdf(null);
    setTotalPages(0);
    setPageNum(0);
    setPageDetails(null);
    setAllFilesData([]);
    setImportError("");
    setLoading(false);
    setRenderedFile(0);
    navigate(-1);
  };
  return (
    <Grid mt={1}>
      <Grid item xs={12} lg={12}>
        {pdf ? (
          <PanelGroup direction="horizontal" className={panelStyles.PanelGroup}>
            <Panel defaultSize={18} minSize={15} maxSize={30} className={panelStyles.Panel}>
              {allFilesData.length ? (
                <PatientList
                  title="Patient List"
                  profiles={allFilesData}
                  handlePatientListClick={handlePatientListClick}
                />
              ) : (
                ""
              )}
            </Panel>
            <PanelResizeHandle />
            <Panel defaultSize={55} minSize={48} maxSize={60} className={panelStyles.PanelPdf}>
              <div>
                <div style={styles.controls}>
                  <ButtonGroup variant="outlined" aria-label="outlined button group">
                    <Button size="small"
                      onClick={handleBackClick}
                      startIcon={<ArrowBackIcon />}>
                      Back
                    </Button>
                    {pdf ? (
                      (allFilesData || []).length &&
                        allFilesData[renderedFileIndex]["id"] ? (
                        <>
                          <Button size="small"
                            onClick={() => {
                              downloadURI(
                                pdf,
                                `${allFilesData[renderedFileIndex]["id"]}${allFilesData[renderedFileIndex]["ReportType"] ? "_" + allFilesData[renderedFileIndex]["ReportType"] : ""}.pdf`
                              );
                            }}
                            startIcon={<DownloadIcon />}>
                            Download
                          </Button>

                        </>
                      ) : (
                        ""
                      )
                    ) : null}
                    {(allFilesData || []).length &&
                      allFilesData[renderedFileIndex]["Status"] === "completed" ? (
                      <Button size="small"
                        onClick={handleShareClick}
                        startIcon={<ShareIcon />}
                      >
                        Share Report
                      </Button>
                    ) : (
                      ""
                    )}

                  </ButtonGroup>
                  <div>
                    {!renderAllPages ? (
                      <PagingControl
                        className={panelStyles.PanelControl}
                        pageNum={pageNum}
                        setPageNum={setPageNum}
                        totalPages={totalPages}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div ref={documentRef} style={styles.documentBlock}>
                  {textInputVisible ? (
                    <DraggableText
                      onCancel={() => setTextInputVisible(false)}
                      onEnd={setPosition}
                      onSet={async (text) => {
                        const { originalHeight, originalWidth } = pageDetails;
                        const scale = originalWidth / documentRef.current.clientWidth;

                        const y =
                          documentRef.current.clientHeight -
                          (position.y +
                            12 * scale +
                            (renderAllPages ? 220 : -20) -
                            position.offsetY -
                            documentRef.current.offsetTop);
                        const x =
                          position.x -
                          166 -
                          position.offsetX -
                          documentRef.current.offsetLeft;

                        // new XY in relation to actual document size
                        const newY = (y * originalHeight) / documentRef.current.clientHeight;
                        const newX = (x * originalWidth) / documentRef.current.clientWidth;

                        const pdfDoc = await PDFDocument.load(pdf);

                        const pages = pdfDoc.getPages();
                        const firstPage = pages[pageNum];

                        firstPage.drawText(text, {
                          x: newX,
                          y: newY,
                          size: 20 * scale,
                        });

                        const pdfBytes = await pdfDoc.save();
                        const blob = new Blob([new Uint8Array(pdfBytes)]);

                        const URL = await blobToURL(blob);
                        allFilesBlob[renderedFileIndex] = blob;
                        setAllFilesBlob(allFilesBlob);
                        allFilesUrl[renderedFileIndex] = URL;
                        setAllFilesUrl(allFilesUrl);
                        setPdf(URL);
                        setPosition(null);
                        setTextInputVisible(false);
                      }}
                    />
                  ) : null}

                  <TransformWrapper initialScale={1} smooth={true}>
                    <TransformComponent>
                      <Document
                        file={pdf}
                        onLoadSuccess={(data) => {
                          setTotalPages(data.numPages);
                        }}
                      >
                        <Page
                          renderAnnotationLayer={false}
                          renderTextLayer={false}
                          pageNumber={pageNum + 1}
                          width={650}
                          onLoadSuccess={(data) => {
                            setPageDetails(data);
                          }}
                        />
                      </Document>
                    </TransformComponent>
                  </TransformWrapper>
                </div>
              </div>
            </Panel>
            <PanelResizeHandle />
            <Panel defaultSize={30} minSize={20} className={panelStyles.Panel} >
              {allFilesData.length ? (
                <div >
                  <ReportInputForm
                    formKey={renderedFileIndex}
                    inputData={allFilesData[renderedFileIndex]}
                    handleReportInputFormSubmit={handleReportInputFormSubmit}
                  ></ReportInputForm>
                </div>
              ) : (
                <Stack sx={{ width: '100%' }} spacing={2}>
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    This is an error alert — <strong>check it out!</strong>
                  </Alert>
                </Stack>
              )}
            </Panel>
          </PanelGroup>
        ) : importError ? (
          <div className={panelStyles.invalidReportBlock}>
            <p>{importError}</p>
            <Button size="medium"
              variant="contained"
              onClick={handleBackClick}
              startIcon={<ArrowBackIcon />}>
              Back to Home
            </Button>
            {/* <Button
              margin={10}
              variant="gradient"
              circular="true"
              color="info"
              onClick={handleBackClick}
            >
              <Icon>arrow_back</Icon>&nbsp; Back to Home
            </Button> */}
          </div>
        ) : (
          ""
        )
        }
      </Grid >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid >
  );
}

export default Interpretation;

// {/* &nbsp;
//                             <Button
//                               variant="gradient"
//                               circular="true"
//                               color="info"
//                               className="hide"
//                               onClick={() => setTextInputVisible(true)}
//                             >
//                               <Icon>edit</Icon>&nbsp; Add Text
//                             </Button> */}
// {/* &nbsp;
//                             <Button
//                               margin={10}
//                               variant="gradient"
//                               circular="true"
//                               color="info"
//                               className="hide"
//                               title={"Generate Report"}
//                               onClick={handleGenerateReport}
//                             >
//   { " "}
//   < Icon > design_services</Icon >& nbsp; Generate Report
//                             </Button > * /}