import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Download} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Stack,
  Typography,
  Tooltip,
} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { useSnackbar } from '../components/snackbar';
import { getDeviceReports } from 'api/device';
import { downloadReportInJson } from 'api/device';
import {  styled } from '@mui/material/styles';

//------------------------------------Data Grid-------------------------//

import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';

const StyledDataGrid = styled(DataGrid)({
  '.super-app-theme--Open': {
    backgroundColor: '#FFEEBA',
  },
});



//-----------------------------------------------------------------------


export default function DeviceReports() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const isSmall = useResponsive('', 'xs');
  const isMd = useResponsive('between', 'xs', 'md');
  const { searchStatus, searchStDate, searchEnDate, searchResult } = JSON.parse(
    window.sessionStorage.getItem('searchDetails') || '{}'
  );
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [data, setData] = useState([]);
  const [status, setstatus] = useState(searchStatus || 'requested');
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(searchStDate ? dayjs(searchStDate) : dayjs());
  const [interpretationStatusFilter, setInterpretationStatusFilter] = useState('all');
  const [rowLoading, setRowLoading] = useState(null);
  const [loadingRows, setLoadingRows] = useState({}); // State to track loading status of each row


 
  const TABLE_HEAD = [
    { field: 'deviceId', headerName: 'Device ID', width: 250, align: 'left', filterable: true },
    { field: 'deviceType', headerName: 'Device Type (Neo/Pro)', width: 200, align: 'left', filterable: true },
    { field: 'date', headerName: 'Date', width: 250, align: 'left', filterable: true },
    { field: 'reportType', headerName: 'Report Type', width: 200, align: 'left', filterable: true },
    {
      field: 'download',
      headerName: 'Download',
      width: 200,
      align: 'left',
      renderCell: (params) => (
        <Tooltip title="Download" arrow>
         <LoadingButton variant="contained"   loading={loadingRows[params.row.id]} disabled={rowLoading === params.row.id} onClick={() => handleRowClick(params.row.id)}>
            <Download />
          </LoadingButton>
        </Tooltip>
      ),
    },
  ];



  useEffect(() => {
    fetchData();
  }, [status, startDate]);


  const fetchData = async () => {
    try {
      //to fetch all the reports related to a specific device id 
      const response = await getDeviceReports({
        device_id: id,
      });

      setLoading(false);
      if (response && response?.success) {
        const filteredData = response?.data?.filter(
          (row) =>
            !row.interpretation_request_status ||
            row.interpretation_request_status === 'requested' ||
            row.interpretation_request_status === 'in progress' ||
            row.interpretation_request_status === 'completed'
        );
        setData(filteredData || []);

        const searchDetails = {
          searchStatus: status,
          searchStDate: startDate,
          searchResult: filteredData,
          
        };
        window.sessionStorage.setItem('searchDetails', JSON.stringify(searchDetails));
      } else {
        if (response) {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };


  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }


  const handleRowClick = async (id) => {
    try {
     
      //to downlaod the leads data related to the specific id 
      setLoadingRows((prev) => ({ ...prev, [id]: true }));
      const response = await downloadReportInJson({ id });
     
      const fileName = `report_${id}.json`;
      const jsonBlob = new Blob([JSON.stringify(response)], { type: 'application/json' });
      const url = URL.createObjectURL(jsonBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      enqueueSnackbar("File downloaded successfully", { variant: 'success' })
    } catch (error) {
      enqueueSnackbar( "File not found", { variant: 'error' })
    } finally {
      setLoadingRows((prev) => ({ ...prev, [id]: false })); // Reset loading state after download completes
    }
  }; 


    //--------------------------------------------//
    let filteredUsers;
    if (status === 'completed') {
      filteredUsers = applySortFilter(
        data.filter((row) => {
          if (interpretationStatusFilter === 'all') {
            return true;
          } else {
            return row?.interpretation_report_status === interpretationStatusFilter;
          }
        }),
        getComparator(order, orderBy),
        
      );
    } else {
      filteredUsers = applySortFilter(data, getComparator(order, orderBy));
    }
    
    
    const rows = filteredUsers.map((row, index) => {
      return {
        id: `${row?.id}`,
        deviceId: row?.device_id,
        deviceType: row?.device_type === 'Neo' ? 'Neo' : 'Pro',
        date: row?.report_timestamp ? dayjs(parseInt(row?.report_timestamp)).format('DD MMM, YYYY') : '',
        reportType: row?.report_type?.replace(/REPORT|_/g, ' '),
       
      };
    });
  
    //--------------------------------------------//

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end', marginTop: '30px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={data ? { height: 600, width: '100%' } : { height: 100, ...width }}>
          <StyledDataGrid
            sx={{ m: 3, p: 1 }}
            rows={rows}
            filterMode="client"
            columns={TABLE_HEAD}
            disableVirtualization={true}
            getRowId={(row) => row?.id}
            localeText={{ noRowsLabel: 'No interpretation request found.' }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            pageSizeOptions={[5, 10, 15, 25]}
            slots={{
              toolbar: () => (
                <>
                  <GridToolbarContainer>
                    <GridToolbarQuickFilter />
                    <Stack spacing={3} flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end">
                      <GridToolbarColumnsButton />
                      <GridToolbarFilterButton />
                      <GridToolbarExport />
                    </Stack>
                  </GridToolbarContainer>
                </>
              ),
            }}
          />
        </Box>
      )}
    </>
  );
}
