
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { pdfjs } from "react-pdf";
import { useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Drop from "../../components/drop";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function DropZoneUtility() {
  const navigate = useNavigate();
  const [pdf, setPdf] = useState(null);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={12}>
        {!pdf ? (
          // 
            <Drop onLoaded={async (files) => {
              navigate(`${process.env.REACT_APP_HOMEPAGE}interpretation`, { state:files });
            }}
            />
        ) : null}
      </Grid>
    </Grid>
  );
}

export default DropZoneUtility;
