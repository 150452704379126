import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import Label from 'components/label/Label';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';

// @mui
import {
  Box,
  Grid,
  Checkbox,
  FormGroup,
  FormControl,
  Button,
  Card,
  Container,
  Dialog,
  FormControlLabel,
  DialogActions,
  DialogTitle,
  Divider,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { useTheme } from '@mui/material/styles';

import Scrollbar from '../components/scrollbar';

import { styled } from '@mui/system';
import {useSnackbar} from '../components/snackbar';
import { Add, Close, Edit } from '@mui/icons-material'
import { getB2BAssociatedUser, addB2BAssociatedUser, removeB2BAssociatedUser } from 'api/users';
import { copyToClipboard, downloadFromURI } from 'utils/Utils';
import { useAuthDispatch } from 'components/AuthStateProvider';
import { VerifyPhoneNo } from 'sections/auth/login';

import FormProvider from 'hooks/form/form-provider';
import { useForm, Controller } from 'react-hook-form'; 
import { countries } from '../hooks/form/countries';
import { RHFAutocomplete } from '../hooks/form/rhf-autocomplete';
import Iconify from '../components/iconify';

//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)(() => ({
  maxWidth: 150, // percentage also works
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export default function ManageAssociatedUsers() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar(); 
  const isSmall = useResponsive('', 'xs');
  const isLg = useResponsive('up', 'md');
  const { account } = useAuthDispatch();

  const [openAddUser, setOpenAddUser] = useState(null);
  const [deleteData, setDeleteData] = useState(null);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getB2BAssociatedUser();
      setLoading(false);
      if (response?.success) {
        setData(response?.data || []);
      } else {
        enqueueSnackbar(response.message, { variant: 'error' })
      }
    } catch (e) {
      setLoading(false);
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const onDeleteDataClick = (data) => async (e) => {
    try {
        if (deleteData) {
          setIsSubmitting(true);
          const response = await removeB2BAssociatedUser({ phone_numbers: [deleteData?.phone_number] });
          setIsSubmitting(false);
          if (response?.success) {
            setDeleteData(null);
            fetchData();
          } else {
            enqueueSnackbar(response.message, { variant: 'error' })
          }
        } else {
          setIsSubmitting(false);
          enqueueSnackbar("Please select user to delete.", { variant: 'error' })
        }
      } catch (e) {
        setIsSubmitting(false);
        enqueueSnackbar(e.message || "Unable to delete the user.", { variant: 'error' })
        console.log(e)
      }
  }

  const onAddNewDone = (e, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") return false;
    setOpenAddUser(false);
  }

  const onCopyClick = (text) => (e) => {
    copyToClipboard(text);
    enqueueSnackbar('Copied to clipboard.', { variant: 'success' })
  };

  const handleCloseDialog = async (isAdded, isClose) => {
    if (isClose || isAdded) setOpenAddUser(false);
  };

  // const handleCloseDialog = async (isVerified) => {
  //   if (isVerified) {
  //     const response= await addB2BAssociatedUser({ phone_numbers: [`${phoneNumber}`] });
  //     if(response.success){
  //         enqueueSnackbar(response.message || 'Added sucessfully.', { variant: 'success' })
  //         fetchData();
  //     } else{
  //         enqueueSnackbar(response.message || 'Unable to add user.', { variant: 'error' })
  //     }
  //   }
  //   setOpenAddUser(false);
  // };

  return (
    <>
      <Helmet>
        <title> Manage Your User | Sunfox Experts</title>
      </Helmet>

      <Container sx={{ marginTop: (isLg ? 0 : 1) }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Manage associated users
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={() => { setOpenAddUser(true); }}>
              <Add sx={{ mr: 1 }} />
                Add User
            </Button> 
          </Stack>
        </Stack>
        <Card>
          <Box>
            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Scrollbar>
                  {/* <TableContainer sx={{ minWidth: 800 }}> */}
                  <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ minWidth: 150 }}>Name</TableCell>
                            <TableCell sx={{ minWidth: 100 }}>Phone No.</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                    {data.length ? (
                      <TableBody>
                        {data?.map((row, index) => {
                          return (
                            <TableRow hover key={index} tabIndex={-1}>
                              <TableCell>
                                <OverflowTypography variant="subtitle2" noWrap>
                                    {row?.name}
                                </OverflowTypography>
                              </TableCell>
                              <TableCell>
                                <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                                  {row?.phone_number}
                                </OverflowTypography>
                              </TableCell>
                              <TableCell>
                                  <Stack direction="row"  spacing={2}>
                                    <Tooltip title="Edit" arrow>
                                        <LoadingButton
                                            onClick={ () => setDeleteData(row) }
                                            loading={false}
                                            variant="contained"
                                            color='error'
                                            disabled={ deleteData?.phone_number === row?.phone_number }
                                            >
                                            Delete
                                        </LoadingButton>
                                    </Tooltip>
                                  </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <TableFooter>
                        <TableRow>
                          <TableCell style={{ width: '100%' }} align="center" colSpan={6} v>
                            <Typography mt={2}>No user found.</Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                  {/* </TableContainer> */}
                </Scrollbar>
              )}
            </Box>
          </Box>
        </Card>
      </Container>
      <Dialog
        fullWidth
        maxWidth="xs"
        scroll='body'
        // disableEscapeKeyDown
        disableBackdropClick={true}
        open={ openAddUser }
        onClose={ onAddNewDone }
        sx={{padding:'20px'}}
      >
        <AddUserCompnent onFormSubmit={handleCloseDialog}/>
        {/* <VerifyPhoneNo onVerify={ handleCloseDialog } /> */}
        
      </Dialog>

      { deleteData && <Dialog
        fullWidth
        maxWidth="xs"
        scroll='body'
        disableEscapeKeyDown={()=> setDeleteData(null)}
        open={deleteData ? true : false}
        onClose={()=> setDeleteData(null)}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
          Confirmation
        </DialogTitle>
        <Box sx={{ px: 3, pb: 4 }}>Do you want to delete user <b>{deleteData?.name}</b>?</Box>
        <Divider />
        <DialogActions>
          <Stack direction='row' spacing={4} sx={{ px: 2, py: 1 }}>
            <Button variant="outlined" color="error" onClick={ () => setDeleteData(null) }>
              Cancel
            </Button>

            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              onClick={ onDeleteDataClick(deleteData) }
            >
              Confirm
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog> }
    </>
  );
}


function AddUserCompnent({ onFormSubmit }) {
  const { enqueueSnackbar } = useSnackbar();
  const defaultValues = {
      country_code: '91',
      phone_number: '',
  };

  let methods = useForm({
      defaultValues,
      mode: "onChange",
  });

  const { control, register, handleSubmit, formState: { errors, isSubmitting } } = methods;

  const onSubmit = handleSubmit(async (formData) => {
      try {
          formData.country_code = `+${formData?.country_code}`;
          formData.phone_numbers = [`${formData?.country_code}${formData?.phone_number}`];
          const response= await addB2BAssociatedUser({ ...formData });
          if (response.success) {
              enqueueSnackbar(response.message || 'Added successfully.', { variant: 'success' });
              onFormSubmit(response)
          } else {
              enqueueSnackbar(response.message || 'Unable to add user.', { variant: 'error' });
              onFormSubmit()
          }
          
      } catch (error) {
          enqueueSnackbar('Failed to add user.', { variant: 'error' });
          console.error(error);
      } finally {
          onFormSubmit()
      }
  })

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={2} justifyContent='center' sx={{ px: 2, py: 3 }}>
        <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
            <Controller
              name="country_code"
              control={control}
              render={({ field }) => (
                  <RHFAutocomplete
                      {...field}
                      label="Country"
                      sx={{ mt: '16px' }}
                      value={ field?.value || (defaultValues?.country_code || '91') }
                      options={countries.map((country) => country.label + "/" + country.phone)}
                      getOptionLabel={(option) => option}
                      isOptionEqualToValue={(option, value) =>
                          option?.split('/')[0] === value || option?.split('/')[1] === value
                      }
                      renderOption={(props, option) => {
                          const { code, label, phone } = countries.find(
                              (country) => country.label === option?.split('/')[0]
                          ) || {};

                          if (!label) return null;
                          return (
                              <li {...props} key={label}>
                                  <Iconify
                                      icon={`circle-flags:${code?.toLowerCase()}`}
                                      width={28}
                                      sx={{ mr: 1 }}
                                  />
                                  {label} ({code}) +{phone}
                              </li>
                          );
                      }}
                  />
              )} />
            </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth>
            <Controller
              name="phone_number"
              control={control}
              sx={{mt: '14px'}}
              rules={{
                  required: "Enter the phone number.",
                  pattern: {
                      value: /^[0-9]{10}$/i,
                      message: "Invalid phone number",
                  },
              }}
              render={({ field }) => (
                  <TextField
                      {...field}
                      label="Phone Number"
                      variant="outlined"
                      fullWidth
                      placeholder="9999999999"
                      margin="normal"
                      error={!!errors.phone_number}
                      helperText={errors.phone_number?.message}
                      onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                  />
              )} />
            </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={12} textAlign='center'>
          <Divider sx={{ mt: 2 }} />
          <Stack direction='row' justifyContent='right' spacing={4} sx={{ px: 2, py: 2 }}>
            <Button variant="outlined" color="error" onClick={ () => onFormSubmit(null, true) }>
                Cancel
            </Button>
            <LoadingButton
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
              sx={{
                  alignSelf: 'flex-end',
                  color: '#ffffff',
                  bgcolor: '#1976d2',
                  '&:hover': {
                      bgcolor: '#1565c0',
                  },
                  mt: 2
              }}
              variant='primary'>
                Add
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
