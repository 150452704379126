import React, { startTransition, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Divider, Card, Link, InputLabel, Stack, Typography, TextField, Button, Box, DialogContent, Dialog, TableCell, TableRow } from '@mui/material';
import { DatePicker, } from '@mui/x-date-pickers/DatePicker';
import { useSnackbar } from '../components/snackbar';
import { useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useAuthDispatch } from '../components/AuthStateProvider'
import SearchIcon from '@mui/icons-material/Search';
import { getAllReports } from 'api/reports';
import { getInvoicePdf, saveInvoiceDetails, toCheckInvoiceNumber } from 'api/users';
import Label from 'components/label/Label';
import { downloadFromURI } from 'utils/Utils';

function InvoiceDetails({ data, isAdmin, onSubmitDone, handleCloseDialog}) {
    const { enqueueSnackbar } = useSnackbar();
    const { updateLocalStorage } = useAuthDispatch();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [numberOfReports, setNumberOfReports] = useState(0);
    const [invoiceNumber, setInvoiceNumber] = useState();
    const [loading, setLoading] = useState(false);
    const [addDiscount, setAddDiscount] = useState(0);

    const defaultValues = data ? {
        ...{ ...data, ...data?.company_data }
    } : {
        phone_number: '',
        name: '',
        email: '',
        business_name: '',
        gst_number: '',
        billing_address: '',
        state: '',

    };

    const methods = useForm({
        defaultValues,
        mode: "onChange",
    });

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;

    const handleSearch = async () => {
        setLoading(true)

        if (!startDate || !endDate) {
            enqueueSnackbar("Please select a date", { variant: 'error' })
            return;

        }
        const response = await getAllReports({
            startDate: startDate,
            endDate: endDate,
            business_id: defaultValues?.api_key,
        });
        if (response.status == 200) {
            enqueueSnackbar(response.message, { variant: 'success' })
            setNumberOfReports(response.data?.length)
            setLoading(false)

        }
    }

    const handleGenerateInvoice = async () => {
        if(!numberOfReports)
        {
            enqueueSnackbar("Please click on calculate first ", { variant: 'error' })
            return;
        }      


        //to check if invoice number already exists in db
        const res = await toCheckInvoiceNumber({
            invoice_number: invoiceNumber,
            business_id: defaultValues?.api_key,
        })
        if (res.status == 200) {
            //to generate pdf and store in s3
            const response = await getInvoicePdf({
                phone_number: defaultValues?.phone_number,
                business_id: defaultValues?.api_key,
                startDate: startDate,
                endDate: endDate,
                number_of_reports: numberOfReports,
                invoice_number: invoiceNumber,
                discount_amount: addDiscount
            })

            if (response.status == 200) {
                //to save invoice details 
                const invoice = response?.data
                const result = await saveInvoiceDetails({
                    phone_number: defaultValues?.phone_number,
                    date: invoice?.InvoiceDetails.date,
                    amount: invoice?.InvoiceDetails.amount,
                    business_id: defaultValues?.api_key,
                    invoice_url: invoice?.InvoiceDetails.invoice_url,
                    invoice_timestamp: invoice?.InvoiceDetails.invoice_timestamp,
                    startDate: startDate,
                    endDate: endDate,
                    invoice_number: invoiceNumber,
                    business_name: defaultValues?.business_name,
                    email: defaultValues?.email,
                    charges: defaultValues?.interpretation_charges,
                    number_of_reports: numberOfReports,
                    discount_amount: addDiscount
                })
                downloadFromURI(`data:application/pdf;base64,${invoice?.InvoicePdf}`, `${defaultValues?.business_name}-${invoiceNumber}`)
                handleCloseDialog();
            }
        }
        else {
            enqueueSnackbar(res.message, { variant: 'error' })
        }
    }

    return (
        <>
            <DialogContent dividers>
                <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <DatePicker
                        label="Start Date"
                        value={startDate}
                        onChange={(data) => setStartDate(data)}
                        sx={{ marginRight: 1 }}
                    />
                    <DatePicker
                        label="End Date"
                        value={endDate}
                        onChange={(data) => { setEndDate(data) }}
                        sx={{ marginRight: 1 }}
                    />

                    <LoadingButton
                        variant="contained"
                        onClick={handleSearch}
                        startIcon={<SearchIcon />}
                        loading={loading}
                    >
                        Calculate
                    </LoadingButton>
                </Box>
                <TableRow style={{ height: '15px' }}>
                    <TableCell>
                        <Typography variant="body2" color="text.secondary">
                            Number Of Reports: <b>{numberOfReports}</b>
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="text.secondary">
                            Charge per Interpretation: <b>{defaultValues.interpretation_charges}</b>
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="text.secondary">
                            Total Charges: <b>{numberOfReports * defaultValues.interpretation_charges}</b>
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="text.secondary">
                            GST: <b>{"18 %"}</b>
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableCell>
                    <InputLabel htmlFor="invoiceNumber">Invoice Number *</InputLabel>
                    <TextField
                        onChange={(e) => { setInvoiceNumber(e.target.value) }}
                        variant="outlined"
                        size="small"
                        required
                    />
                </TableCell>
                <TableCell>
                    <InputLabel htmlFor="invoiceNumber">Discount Amount</InputLabel>
                    <TextField
                        onChange={(e) => { setAddDiscount(parseFloat(e.target.value)) }}
                        variant="outlined"
                        size="small"
                    />
                </TableCell>
                <Label><Link
                    disabled
                    component="button"
                    color='black'
                    underline='none'
                    variant="button">
                   Net Payment: {
    <b>{Math.max(((18 * numberOfReports * defaultValues.interpretation_charges) / 100) + (numberOfReports * defaultValues.interpretation_charges) - addDiscount, 0)}</b>
}


                </Link></Label>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 4 }}>
                    <LoadingButton
                        sx={{ minWidth: 100 }}
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        onClick={handleSubmit(handleGenerateInvoice)}
                        disabled={!invoiceNumber || isSubmitting}
                    >
                        Generate Invoice Pdf
                    </LoadingButton>
                </Box>

            </DialogContent>

        </>
    )
}

export default InvoiceDetails;