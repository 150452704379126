import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import Label from 'components/label/Label';
// @mui
import {
  Card,
  Box,
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableFooter,
  Tooltip,
  Link,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { useTheme } from '@mui/material/styles';

// components
import Scrollbar from '../components/scrollbar';

import { styled } from '@mui/system';
import { useSnackbar } from '../components/snackbar';

import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import { Add, Close, Edit } from '@mui/icons-material';
import { getB2Busers, saveB2BProfile } from 'api/users';
import { copyToClipboard } from 'utils/Utils';
import InvoiceDetails from './InvoiceDetails';
import InvoiceHistory from './InvoiceHistory';
import { useNavigate } from 'react-router-dom';

//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)(() => ({
  maxWidth: 150, // percentage also works
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export default function Finance() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  const isLg = useResponsive('up', 'md');
  const account =
    localStorage.getItem('TokenDetails') && JSON.parse(localStorage.getItem('TokenDetails'))?.authorization
      ? JSON.parse(localStorage.getItem('TokenDetails'))
      : {};

  //   const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [B2BUserdata, setB2BUserdata] = useState([]);
  //   const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshList, setRefreshList]=useState(false);
  useEffect(() => {
    fetchData();
  }, [refreshList]);

  const fetchData = async () => {
    const response = await getB2Busers();
    setLoading(false);
    if (response?.success) {
      setB2BUserdata(response.data);
    } else {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const handleSavedEvent = (success, error) => {
    if (success) {
      setSelectedData(null);
      fetchData();
    }
  };
  const handleCloseDialog = () => {
    setSelectedData(null);
    setRefreshList(true);
};
  return (
    <>
      <Helmet>
        <title>Finance : Sunfox Expert Portal</title>
      </Helmet>

      <Container sx={{ marginTop: isLg ? -2 : 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Finance
          </Typography>
        </Stack>
        <Card>
          <Box>
            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Scrollbar>
                  {/* <TableContainer sx={{ minWidth: 800 }}> */}
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ minWidth: 150 }}>Business Name</TableCell>
                        <TableCell sx={{ minWidth: 150 }}>Business Owner</TableCell>
                        <TableCell sx={{ minWidth: 100 }}>Last Invoice Generated</TableCell>
                        <TableCell>Last Bill Payment</TableCell>
                        <TableCell>Invoice History</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    {B2BUserdata.length ? (
                      <TableBody>
                        {B2BUserdata?.map((row, index) => {
                          return (
                            <TableRow hover key={index} tabIndex={-1}>
                              <TableCell>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <OverflowTypography variant="subtitle2" noWrap>
                                    {row?.business_name}
                                  </OverflowTypography>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                  <Tooltip title={row?.phone_number}>
                                    <OverflowTypography variant="subtitle2" noWrap>
                                      {row?.name}
                                    </OverflowTypography>
                                  </Tooltip>
                                </Stack>
                              </TableCell>
                              <TableCell>
                                <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                                  {row?.invoice_details?.last_generated_invoice}
                                </OverflowTypography>
                              </TableCell>

                              <TableCell>
                                <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                                  {row?.invoice_details?.last_amount_payable}
                                </OverflowTypography>
                              </TableCell>

                              <TableCell>
                                <Stack direction="row" spacing={2}>
                                  <Tooltip title="Edit" arrow>
                                    <LoadingButton
                                      onClick={() => {
                                        setHistoryData(row);
                                        navigate(`${process.env.REACT_APP_HOMEPAGE}dashboard/finance/invoiceHistory`, {
                                          state: { data: row },
                                        });
                                      }}
                                      loading={false}
                                      variant="contained"
                                    >
                                      Invoice History
                                    </LoadingButton>
                                  </Tooltip>
                                </Stack>
                              </TableCell>

                              <TableCell>
                                <Stack direction="row" spacing={2}>
                                  <Tooltip title="Edit" arrow>
                                    <LoadingButton
                                      onClick={() => setSelectedData(row)}
                                      loading={false}
                                      variant="contained"
                                      // disabled={ selectedData?.phone_number === row?.phone_number }
                                    >
                                      Generate New Invoice
                                    </LoadingButton>
                                  </Tooltip>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <TableFooter>
                        <TableRow>
                          <TableCell style={{ width: '100%' }} align="center" colSpan={6} v>
                            <Typography mt={2}>No user found.</Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                  {/* </TableContainer> */}
                </Scrollbar>
              )}
            </Box>
          </Box>
        </Card>
      </Container>

      {selectedData && (
        <Dialog
          maxWidth="md"
          scroll="body"
          disableEscapeKeyDown={() => setSelectedData(null)}
          open={selectedData ? true : false}
          onClose={() => setSelectedData(null)}
          transitionDuration={{
            enter: theme.transitions.duration.shortest,
            exit: theme.transitions.duration.shortest - 80,
          }}
        >
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <span> {selectedData.business_name} </span>
            <Label>
              <Link
                onClick={() => setSelectedData(null)}
                component="button"
                color="black"
                underline="none"
                variant="button"
              >
                Close
              </Link>
            </Label>
          </DialogTitle>
          <InvoiceDetails
            data={selectedData}
            isAdmin={account?.userType === 'superuser' || account?.userType === 'admin'}
            onSubmitDone={handleSavedEvent}
            handleCloseDialog={handleCloseDialog}
          />
        </Dialog>
      )}
    </>
  );
}
