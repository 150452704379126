import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip'

// @mui

import ClearIcon from '@mui/icons-material/Clear';
import { styled, alpha } from '@mui/material/styles';
import { 
  FormControl,InputLabel,Select, MenuItem, Grid, Toolbar, Button, Box, Tooltip, Typography, OutlinedInput, InputAdornment, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// component
import Iconify from '../../../components/iconify';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// ----------------------------------------------------------------------


const StyledRoot = styled(Toolbar)(({ theme }) => ({
  // height: 86,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: "center",
  padding: theme.spacing(2, 1, 1),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 300,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  // filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({ filterName, onFilterName, newStatus, startDate, endDate, handleStartDateChange, selectedIds, completeSelectedIds, requestedSelectedReportClick, downloadSelectedReportClick, unselecteReports, ButtonLoading, DownloadButtonLoading, filterReportTypeData, isSmall, filteredDataCount }) {
  const [status, setstatus] = useState('');
  

  const [Loading, setLoading] = useState(true);

  const [buttonLoading, setbuttonLoading] = useState(true);

  const [interpretationStatusFilter, setInterpretationStatusFilter] = useState('all');

  const handleStatus = (newStatusValue, loadingValue) => {
    if (status === newStatusValue) {
      setstatus('');
      setLoading(false);
      newStatus('', false);
    } else {
      setstatus(newStatusValue);
      setLoading(loadingValue);
      newStatus(newStatusValue, true);
    }
  };
  const handleReportTypeStatusChange = (event) => {
    setInterpretationStatusFilter(event.target.value)
    filterReportTypeData(event.target.value);
  };

  return (
    <StyledRoot
      sx={{
        ...((selectedIds?.length || completeSelectedIds?.length) > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {(selectedIds?.length || completeSelectedIds?.length) > 0 ? (
        <>
          <Box display='flex' sx={{ gap: '1rem' }}>
            { completeSelectedIds?.length ? <LoadingButton
              startIcon={<Iconify icon="eva:download-fill" />}
              loading={DownloadButtonLoading}
              loadingPosition="start"
              variant="contained"
              onClick={(e) => downloadSelectedReportClick(e, completeSelectedIds)}
            >{completeSelectedIds?.length} Download</LoadingButton> : '' }
            {selectedIds?.length ? <LoadingButton
              startIcon={<Iconify icon="eva:plus-fill" />}
              loading={ButtonLoading}
              loadingPosition="start"
              variant="contained"
              onClick={(e) => requestedSelectedReportClick(e, selectedIds)}
            >{selectedIds?.length} Request</LoadingButton> : '' }
          </Box>
          <Tooltip title="Clear">
            <ClearIcon sx={{ cursor: 'pointer' }} onClick={(e) => unselecteReports(e, [...selectedIds, ...completeSelectedIds])}>
            </ClearIcon>
          </Tooltip>
        </>
      ) : (
        <Box sx={{ width: '100%' }}>
          <Grid sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginX: '20px',
            flexDirection: (isSmall ? 'column-reverse' : 'row'),
            gap: (isSmall ? '1rem' : 0)
          }}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={1} sx={{width:'100%'}}>
                  <Stack direction="row" spacing={1}>
                    <Chip
                      label={ filteredDataCount?.total ? `All Tests ${filteredDataCount?.total || ''}` : `All Tests` }
                      onClick={() => handleStatus('all')}
                      color={"default"}
                      variant={status === 'all' ? "filled" : "outlined"}
                    />
                    {filteredDataCount?.toRequest ? <Chip
                      label={ filteredDataCount?.toRequest ? `Yet to Request ${filteredDataCount?.toRequest || ''}` : `Yet to Request` }
                      onClick={() => handleStatus('')}
                      color={"default"}
                      variant={status === '' ? "filled" : "outlined"}
                    /> : null }
                    <Chip
                      label={ (filteredDataCount?.requested) ? `Requested ${filteredDataCount?.requested}` : 'Requested' }
                      onClick={() => handleStatus('requested')}
                      color="primary"
                      variant={status === 'requested' ? "filled" : "outlined"}
                    />
                    <Chip
                      label={ (filteredDataCount?.completed) ? `Completed ${filteredDataCount?.completed}` : 'Completed' }
                      onClick={() => handleStatus('completed')}
                      color="success"
                      variant={status === 'completed' ? "filled" : "outlined"}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }} >
                    <Stack direction="row" spacing={1}>
                    {/* <StyledSearch
                      value={filterName}
                      onChange={onFilterName}
                      placeholder="Search user..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    /> */}
                    {status === "completed" && (<FormControl>
                        <InputLabel id="report_findings">Findings</InputLabel>
                        <Select
                          labelId="report-findings-label"
                          id="report-status-select"
                          value={interpretationStatusFilter}
                          label="Findings"
                          onChange={handleReportTypeStatusChange}
                          sx={{ width: '120px' }}
                        >
                          <MenuItem value="all">All</MenuItem>
                          <MenuItem value="normal">Normal</MenuItem>
                          <MenuItem value="abnormal">Abnormal</MenuItem>
                          <MenuItem value="retake">Retake</MenuItem>
                        </Select>
                    </FormControl>) }
                    <DatePicker
                      sx={{ width: '150px' }}
                      label="Start Date"
                      value={startDate}
                      format='DD/MM/YYYY'
                      onChange={(date) => handleStartDateChange(date, 0)}
                    />
                    { endDate ? <DatePicker
                      sx={{ width: '150px' }}
                      label="End Date"
                      value={endDate || startDate}
                      format='DD/MM/YYYY'
                      onChange={(date) => handleStartDateChange(date, 1)}
                    /> : null }
                  </Stack>
                </Box>
              </Grid>
          </Grid>
        </Box>
      )}
    </StyledRoot>
  );
}
