import React, { createContext, useContext, useState, useEffect } from 'react';
import { verifyToken } from 'api/users';
import { generateFcmToken, deleteFcmToken } from 'api/login';
import { setSession } from "utils/axios";
import { refreshSocket } from 'utils/socket';

const AuthContext = createContext();

export const useAuthDispatch = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthDispatch must be used within a AuthStateProvider');
  }
  return context;
};

export const AuthStateProvider = ({ children }) => {
  const userAccount = localStorage.getItem('TokenDetails') ? JSON.parse(localStorage.getItem('TokenDetails')) : null;
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fcmToken, setFCMToken] = useState(true);
  const [socket, setSocket] = useState();


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await verifyToken({ token: userAccount?.authorization });
        
        if (response?.data) {
          setAccount(response?.data);
          setSession(response?.data?.authorization);
          await getSocketValue();
        }
      } catch (error) {
        setAccount(null);
      }
      setLoading(false);
    };

    fetchUser();
  }, []);

  const onUserLogin = async (user) => {
    localStorage.setItem('TokenDetails', JSON.stringify(user))
    setAccount(user);
    setSession(user?.authorization);
    await getSocketValue();
  }


  const getSocketValue = async () => {
    setSocket( await refreshSocket())

  }

  const userLogOut = async () => {
    localStorage.removeItem('TokenDetails');
    //calling logout api to delte the user fcm token 
    if (fcmToken) deleteFcmToken({ fcmToken })
    refreshSocket();
    setAccount(null);
    setSession(null);
    sessionStorage.clear();
  }

  const updateLocalStorage = (key, value) => {
    const updatedData = { ...account || {}, ...value };
    localStorage.setItem(key, JSON.stringify(updatedData));
    if (key === 'TokenDetails') {
      setAccount(updatedData);
    }
  }

  const setFcmPushToken = async (fcmToken) => {
    try {
      await generateFcmToken({ fcmToken });
      setFCMToken(fcmToken);
    } catch (e) {
      console.log("Failed to set the fcm token.")
    }
  }


  return (
    <AuthContext.Provider value={{ updateLocalStorage, loading, account, setAccount, onUserLogin, userLogOut, setFcmPushToken, socket,getSocketValue}}>
      {children}
    </AuthContext.Provider>
  );
};












