
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';

// @mui
import {
  Box,
  Stack,
  Container,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  CircularProgress,
  Paper
  
} from '@mui/material';
import dayjs from "dayjs";
import { DataGrid,GridToolbarContainer,GridToolbarExport, } from '@mui/x-data-grid';
import { styled } from '@mui/system';
import { useSnackbar } from '../components/snackbar';
import { getExisitingPlanUsers } from 'api/users';
import useResponsive from '../hooks/useResponsive';
import { useAuthDispatch } from 'components/AuthStateProvider';

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};
//-----------------------------------------------------------------------

export default function PlanUsers() {
  const { enqueueSnackbar } = useSnackbar();
  const isLg = useResponsive('up', 'md');
  const { account } = useAuthDispatch();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [subscriptionType, setSubscriptionType] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState('');


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (status = '', type = '') => {
    try {
      setLoading(true)
      status=status==='None'?'':status
      type=type==='None'?'':type
      const response = await getExisitingPlanUsers({ status, type });
      setLoading(false);
      if (response?.success) {
        setData(response?.data || []);
        setFilteredData(response?.data || []);
      } else {
        enqueueSnackbar(response?.message, { variant: 'error' });
      }
    } catch (e) {
      enqueueSnackbar("Unable to fetch B2B users.", { variant: 'error' });
    }
  };

  const handleSubTypeChange = (event) => {
    const value = event.target.value;
    setSubscriptionType(value);
    fetchData(subscriptionStatus, value);
  };

  const handleStatusChange = (event) => {
    const value = event.target.value;
    setSubscriptionStatus(value);
    fetchData(value, subscriptionType);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'plan_name', headerName: 'Plan Name', width: 150 },
    { field: 'phone_number', headerName: 'Phone Number', width: 150 },
    { field: 'created_at', headerName: 'Created At', width: 200 },
    { field: 'expiry_date', headerName: 'Expiry Date', width: 200 },
    { field: 'subscription_type', headerName: 'Subscription Type', width: 150 },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      renderCell: (params) => {
        return (
          <Typography color={params.row.status === 'active' ||params.row.status === 'created'  ? 'green' : 'red'}>
            {params.row.status} {/* This uses the status field directly */}
          </Typography>
        );
      },
    }
  ];

  const rows = filteredData.map((row, index) => ({
    id: index + 1,
    plan_name: row?.plan_name,
    phone_number: row?.phone_number,
    created_at: dayjs(row?.created_at).format('DD/MM/YYYY HH:mm:ss') ,
    expiry_date:dayjs(row?.expiry_date).format('DD/MM/YYYY HH:mm:ss') ,
    subscription_type: row?.subscription_type,
   
    last_payment_id: row?.last_payment?.id,
    status: row?.subscription_status 
  
  }));

  return (
    <>
      <Helmet>
        <title> Active Users</title>
      </Helmet>

      <Container sx={{ marginTop: isLg ? 0 : 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            Subscribed Users
          </Typography>

          <Stack direction="row" alignItems="center" spacing={2} >
            <FormControl sx={{ minWidth: 200 }} size="medium">
              <InputLabel id="subscription-type-label">Subscription Type</InputLabel>
              <Select
                labelId="subscription-type-label"
                id="subscription-type-select"
                value={subscriptionType}
                label="Subscription Type"
                onChange={handleSubTypeChange}
              >
                <MenuItem value={"trial"}>Trial</MenuItem>
                <MenuItem value={"paid"}>Paid</MenuItem>
                <MenuItem value={"None"}>None</MenuItem>
              </Select>
              <FormHelperText>*select subscription type</FormHelperText>
            </FormControl>

            <FormControl sx={{ minWidth: 200 }} size="medium">
              <InputLabel id="subscription-status-label">Subscription Status</InputLabel>
              <Select
                labelId="subscription-status-label"
                id="subscription-status-select"
                value={subscriptionStatus}
                label="Subscription Status"
                onChange={handleStatusChange}
              >
                <MenuItem value={"active"}>Active</MenuItem>
                <MenuItem value={"expired"}>Expired</MenuItem>
                <MenuItem value={"created"}>Created</MenuItem>
                <MenuItem value={"cancel"}>Cancelled</MenuItem>
                <MenuItem value={"None"}>None</MenuItem>
              </Select>
              <FormHelperText>*select subscription status</FormHelperText>
            </FormControl>
          </Stack>
        </Stack>

        <Paper sx={{ height: 550, width: '100%' }}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              getRowClassName={(params) =>
                params.row.last_payment_id ? 'highlighted-row' : ''
              }

              sx={{
                '& .MuiDataGrid-row.highlighted-row': {
                  backgroundColor: '#e0f7fa !important', // Apply color to rows with last_payment_id
                },
             
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 50,
                  },
                },
              }}
              components={{
                Toolbar: CustomToolbar // Add the custom toolbar with export
              }}
              pageSizeOptions={[50]}
              checkboxSelection
              
            />
          )}
        </Paper>
      </Container>
    </>
  );
}


