function PDFExtractor(pdfjs, pdfPath, pageNumber) {
    const extractTextFromCoords = (page) => {
        return page.getTextContent().then((textContent) => {
            const isOldReportFormat = textContent.items.find(item => item.str && item.str.match(new RegExp('^first name', 'ig')))
            const isLandscapeReportFormat = textContent.items.find(item => item.str && item.str.match(new RegExp('^device id', 'ig')))
            if (isLandscapeReportFormat) return newLandscapeReportDataExtraction(textContent);
            else if (isOldReportFormat) return oldReportDataExtraction(textContent);
            else return newReportDataExtraction(textContent);
        });
    };

    // const pdfPath = 'Ashok_Kumar_Jain_1694434125632_twelve_lead_1.pdf'; // Replace with the path to your PDF file
    return pdfjs.getDocument(pdfPath).promise.then((pdf) => {
        const promise = pdf.getPage(pageNumber).then((page) => {
            return extractTextFromCoords(page); // Replace with your desired coordinates
        })

        return Promise.resolve(promise).then((extractedTextArray) => {
            return extractedTextArray;
        });
    });
};

function oldReportDataExtraction(textContent) {
    const textFragments = {};
    let keysToExtract = ['date', 'report id', 'patient id', 'mobile', 'first name', 'last name', 'gender', 'age', 'height', 'weight'];
    let hWidht = 150, vWidth = 8;
    for (const key of keysToExtract) {
        const keyData = textContent.items.find(item => item.str && item.str.match(new RegExp('^' + key, 'ig')))
        if (keyData && keyData.width && (keyData.transform || []).length === 6) {
            const itemX = keyData.transform[4];
            const itemY = keyData.transform[5];

            const keyValues = textContent.items.filter(item => {
                return item.str.trim() && ((item.transform[4] >= (itemX + keyData.width)) && (item.transform[4] <= (itemX + keyData.width + hWidht))) && ((item.transform[5] >= (itemY - vWidth)) && (item.transform[5] <= (itemY + vWidth)));
            });

            const sortedKeyValues = keyValues.sort((a, b) => a.transform[4] - b.transform[4]).map(item => item.str);
            textFragments[keyData.str.replace(/\s|[:]/g, '')] = sortedKeyValues.join(' ');
        }
    }
    return textFragments;
};

function newReportDataExtraction(textContent) {
    const textFragments = { ReportFormat: "neo" };
    let keysToExtract = ['date', 'id', 'report id', 'patient id', 'mobile', 'full name', 'name', 'age/gender', 'height/weight'];
    let hWidht = 120, vWidth = 0;
    for (const key of keysToExtract) {
        const keyData = textContent.items.find(item => item.str && item.str.match(new RegExp('^' + key, 'ig')))
        if (keyData && keyData.width && (keyData.transform || []).length === 6) {
            const itemX = keyData.transform[4];
            const itemY = keyData.transform[5];

            const keyValues = textContent.items.filter(item => {
                return item.str.trim() && ((item.transform[4] >= (itemX + keyData.width)) && (item.transform[4] <= (itemX + keyData.width + hWidht))) && ((item.transform[5] >= (itemY - vWidth)) && (item.transform[5] <= (itemY + vWidth)));
            });
            const sortedKeyValues = keyValues.sort((a, b) => a.transform[4] - b.transform[4]).map(item => item);

            let uniqueValues = [];
            for(const n of sortedKeyValues){
                if(uniqueValues.indexOf(n.str) === -1){
                    uniqueValues.push(n.str);
                }
            }
            const dataKey = keyData.str.replace(/\s|[:]/g, '');
            let dataValue = uniqueValues.join(' ');
            if (dataKey === "Id" || dataKey === "ID") textFragments["ReportId"] = dataValue;
            if (dataKey === "Fullname" || dataKey === "name") {
                const splittedData = dataValue.split(' ');
                textFragments["FirstName"] = "";
                textFragments["LastName"] = "";
                if (splittedData.length) {
                    if (splittedData.length >= 2) {
                        textFragments["LastName"] = splittedData[splittedData.length - 1];
                        splittedData.pop();
                        textFragments["FirstName"] = splittedData.join(" ");
                    } else {
                        textFragments["FirstName"] = splittedData.join(" ");
                        textFragments["LastName"] = "";
                    }
                }
            } else if (dataKey === 'Height/Weight') {
                const splittedData = dataValue.split("/");
                textFragments["Height"] = "";
                textFragments["Weight"] = "";
                if (splittedData.length) {
                    if (splittedData.length === 1) textFragments["Height"] = splittedData[0];
                    if (splittedData.length === 2) {
                        textFragments["Height"] = splittedData[0];
                        textFragments["Weight"] = splittedData[1];
                    }
                }
            } else if (dataKey === 'Age/Gender') {
                const splittedData = dataValue.split("/");
                textFragments["Age"] = "";
                textFragments["Gender"] = "";
                if (splittedData.length) {
                    if (splittedData.length === 1) textFragments["Age"] = splittedData[0];
                    if (splittedData.length === 2) {
                        textFragments["Age"] = splittedData[0];
                        textFragments["Gender"] = splittedData[1];
                    }
                }
            } else {
                textFragments[dataKey] = dataValue;
            }
        }
    }
    return textFragments;

};

function newLandscapeReportDataExtraction(textContent) {
    const isClinic = textContent.items.find(item => item.str && item.str.match(new RegExp('^powered by', 'ig')))
    const textFragments = { ReportFormat: "pro", Clinic: !!isClinic };
    let keysToExtract = [{
        title: 'date',
        width: 100,
        key: 'Date'
     }, {
        title: 'report id',
        width: 100,
        key: 'ReportId'
    }, {
        title: 'patient id',
        width: 100,
        key: 'PatientId'
    }, {
        title: 'mobile',
        width: 100,
        key: 'PhoneNo'
    }, {
        title: 'full name',
        width: 100,
        key: 'FullName'
    }, {
        title: 'name',
        width: 80,
        key: 'Name'
    }, {
        title: 'age',
        width: 50,
        key: 'Age'
    }, {
        title: 'gender',
        width: 50,
        key: 'Gender'
    }, {
        title: 'age/gender',
        width: 50,
        key: 'Age/Gender'
    }, { 
        title: 'height',
        width: 50,
        key: 'Height'
    }, {
        title: 'weight',
        width: 50,
        key: 'Weight'
    }, {
        title: 'height/weight',
        width: 50,
        key: 'Height/Weight'
    }];
    let hWidht = 60, vWidth = 20;
    for (const key of keysToExtract) {
        const keyData = textContent.items.find(item => item.str && item.str.match(new RegExp('^' + key?.title, 'ig')))
        if (keyData && keyData.width && (keyData.transform || []).length === 6) {
            const itemX = keyData.transform[4];
            const itemY = keyData.transform[5];

            const keyValues = textContent.items.filter(item => {
                return item.str.trim() && ((item.transform[4] >= itemX - 0.5) && (item.transform[4] <= itemX - 0.5 + key?.width)) && ((item.transform[5] >= (itemY - vWidth)) && (item.transform[5] <= (itemY - vWidth + 10)));
            });
            const sortedKeyValues = keyValues.sort((a, b) => a.transform[4] - b.transform[4]).map(item => item);

            let uniqueValues = [], valuePosition;
            for(const n of sortedKeyValues){
                if(uniqueValues.indexOf(n.str) === -1){
                    uniqueValues.push(n.str);
                    valuePosition = n;
                }
            }
            const dataKey = keyData?.str?.match(/date:/ig) ? keyData.str : keyData.str.replace(/\s|[:]/g, '');
            let dataValue = uniqueValues.join(' ');
            if (dataKey?.toLowerCase() === "id" || dataKey?.toLowerCase() === "report id") textFragments["ReportId"] = dataValue;
            if (dataKey?.toLowerCase() === "fullname" || dataKey?.toLowerCase() === "name") {
                const splittedData = dataValue.split(' ');
                textFragments["NamePosition"] = valuePosition;
                textFragments["FirstName"] = "";
                textFragments["LastName"] = "";
                if (splittedData.length) {
                    if (splittedData.length >= 2) {
                        textFragments["LastName"] = splittedData[splittedData.length - 1];
                        splittedData.pop();
                        textFragments["FirstName"] = splittedData.join(" ");
                    } else {
                        textFragments["FirstName"] = splittedData.join(" ");
                        textFragments["LastName"] = "";
                    }
                }
            } else if (dataKey?.toLowerCase() === 'height/weight') {
                const splittedData = dataValue.split("/");
                textFragments["Height"] = "";
                textFragments["Weight"] = "";
                if (splittedData.length) {
                    if (splittedData.length === 1) textFragments["Height"] = splittedData[0];
                    if (splittedData.length === 2) {
                        textFragments["Height"] = splittedData[0];
                        textFragments["Weight"] = splittedData[1];
                    }
                }
            } else if (dataKey?.toLowerCase() === 'age/gender') {
                const splittedData = dataValue.split("/");
                textFragments["Age"] = "";
                textFragments["Gender"] = "";
                if (splittedData.length) {
                    if (splittedData.length === 1) textFragments["Age"] = splittedData[0];
                    if (splittedData.length === 2) {
                        textFragments["Age"] = splittedData[0];
                        textFragments["Gender"] = splittedData[1];
                    }
                }
            } else if (dataKey?.match(/date:/ig)) {
                textFragments["DatePosition"] = keyData
                textFragments["Date"] = dataKey?.replace('Date: ', '')
            } else {
                textFragments[key?.key] = dataValue;
            }
        }
    }
    return textFragments;

};

export default PDFExtractor;

//*******************************************************//
//                                                       //
//              Backup Old Report Extraction             //
//                                                       //
//*******************************************************//

// function PDFExtractor(pdfjs, pdfPath, pageNumber) {
//     const keysToExtract = ['date', 'report id', 'patient id', 'mobile', 'first name', 'last name', 'gender', 'age', 'height', 'weight']
//     const extractTextFromCoords = (page) => {
//         return page.getTextContent().then((textContent) => {
//             const textFragments = {};
//             for (const key of keysToExtract) {
//                 const keyData = textContent.items.find(item => item.str && item.str.match(new RegExp('^' + key, 'ig')))
//                 if (keyData && keyData.str && keyData.width && (keyData.transform || []).length === 6) {
//                     const itemX = keyData.transform[4];
//                     const itemY = keyData.transform[5];

//                     const keyValues = textContent.items.filter(item => {
//                         return item.str.trim() && ((item.transform[4] >= (itemX + keyData.width)) && (item.transform[4] <= (itemX + keyData.width + 150))) && ((item.transform[5] >= (itemY - 8)) && (item.transform[5] <= (itemY + 8)));
//                     })

//                     const sortedKeyValues = keyValues.sort((a, b) => a.transform[4] - b.transform[4]).map(item => item.str);
//                     textFragments[keyData.str.replace(/\s|[:]/g, '')] = sortedKeyValues.join(' ');
//                 }
//             }

//             return Object.keys(textFragments).length ? textFragments : null;
//         });
//     };

//     // const pdfPath = 'Ashok_Kumar_Jain_1694434125632_twelve_lead_1.pdf'; // Replace with the path to your PDF file
//     return pdfjs.getDocument(pdfPath).promise.then((pdf) => {
//         const promise = pdf.getPage(pageNumber).then((page) => {
//             return extractTextFromCoords(page); // Replace with your desired coordinates
//         })

//         return Promise.resolve(promise).then((extractedTextArray) => {
//             return extractedTextArray;
//         });
//     });
// }

// export default PDFExtractor;