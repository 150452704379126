
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import Label from 'components/label/Label';

// @mui
import {
  Card,
  Box,
  Table,
  Stack,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableFooter,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper

} from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { useTheme } from '@mui/material/styles';
import Scrollbar from '../components/scrollbar';
import { styled } from '@mui/system';
import { useSnackbar } from '../components/snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import { Add } from '@mui/icons-material';
import { getB2Busers } from 'api/users';
import { useAuthDispatch } from 'components/AuthStateProvider';
import { useNavigate } from 'react-router-dom';

//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)(() => ({
  maxWidth: 150, // percentage also works
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export default function BusinessClients({ title }) {
  // const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const isSmall = useResponsive('', 'xs');
  const isLg = useResponsive('up', 'md');
  
  const [selectedData, setSelectedData] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const{account}=useAuthDispatch();
  const navigate = useNavigate();

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.default,
  }));

  // const account =
  // localStorage.getItem('TokenDetails') && JSON.parse(localStorage.getItem('TokenDetails'))?.authorization
  //   ? JSON.parse(localStorage.getItem('TokenDetails'))
  //   : {};


  useEffect(() => {
   
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getB2Busers();
      setLoading(false);
      if (response?.success) {
        setData(response?.data || []);
      } else {
        enqueueSnackbar(response?.message, { variant: 'error' });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Unable to fetch B2B users.", { variant: 'error' });
    }
  };

  const handleRowClick = (row) => {
    const userType=account.userType

    if(userType==='r&dteam'){
      navigate(`${process.env.REACT_APP_HOMEPAGE}dashboard/reports/${row.api_key}`);
    }
    
    else if(userType==='managementteam'){
      setSelectedData(row);
      setIsDialogOpen(true);
    }
    
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedData(null);
  };

  return (
    <>
      <Helmet>
        <title>Business Clients | Sunfox Experts</title>
      </Helmet>

      <Container sx={{ marginTop: isLg ? 0 : 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" gutterBottom>
            { title || 'Manage clients' }
          </Typography>
        </Stack>
        <Card>
          <Box>
            <Box>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <Scrollbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ minWidth: 150 }}>Client</TableCell>
                        <TableCell sx={{ minWidth: 150 }}>User Name</TableCell>
                        <TableCell sx={{ minWidth: 100 }}>Phone No.</TableCell>
                        <TableCell sx={{ minWidth: 100 }}>Email Id</TableCell>
                        <TableCell>Business Id</TableCell>
                        <TableCell> Action </TableCell>

                      </TableRow>
                    </TableHead>
                    {data.length ? (
                      <TableBody>
                        {data?.map((row, index) => (
                          <TableRow hover key={index} tabIndex={-1} onClick={() => handleRowClick(row)} style={{ cursor: 'pointer' }}>
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Tooltip title={row?.name}>
                                  <OverflowTypography variant="subtitle2" noWrap>
                                    {row?.business_name}
                                  </OverflowTypography>
                                </Tooltip>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Tooltip title={row?.name}>
                                  <OverflowTypography variant="subtitle2" noWrap>
                                    {row?.name}
                                  </OverflowTypography>
                                </Tooltip>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
                                {row?.phone_number}
                              </OverflowTypography>
                            </TableCell>
                            <TableCell>
                              <OverflowTypography variant="subtitle2" noWrap>
                                {row?.email}
                              </OverflowTypography>
                            </TableCell>
                            <TableCell>
                              <Label color='success'>
                                {row?.api_key}
                              </Label>
                            </TableCell>
                            <TableCell>
                              <Button variant="contained" onClick={() =>  handleRowClick(row)}>
                                View Details
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableFooter>
                        <TableRow>
                          <TableCell style={{ width: '100%' }} align="center" colSpan={6}>
                            <Typography mt={2}>No user found.</Typography>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </Scrollbar>
              )}
            </Box>
          </Box>
        </Card>
      </Container>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth sx={{ '& .MuiDialog-paper': { height: '50vh' } }}>
        <DialogTitle>Details</DialogTitle>
        <DialogContent>
          {selectedData && (
            <>
              <Box sx={{ width: '100%', padding: 2 }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={6}>
                    <Item>
                      <Typography variant="subtitle2">
                        <strong>Business Name:</strong> <span style={{ color: 'grey' }}>{selectedData?.business_name}</span>
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Typography variant="subtitle2">
                        <strong>User Name: </strong><span style={{ color: 'grey' }}>{selectedData?.name}</span>
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Typography variant="subtitle2">
                        <strong>Phone Number:</strong> <span style={{ color: 'grey' }}>{selectedData?.phone_number}</span>
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Typography variant="subtitle2">
                        <strong>Email: </strong><span style={{ color: 'grey' }}>{selectedData?.email}</span>
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Typography variant="subtitle2">
                        <strong>Billing Address: </strong><span style={{ color: 'grey' }}>{selectedData?.company_data.billing_address}</span>
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Typography variant="subtitle2">
                        <strong>Pin: </strong><span style={{ color: 'grey' }}>{selectedData?.company_data.pin}</span>
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Typography variant="subtitle2">
                        <strong>State: </strong><span style={{ color: 'grey' }}>{selectedData?.company_data.state}</span>
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Typography variant="subtitle2">
                        <strong>Status: </strong><span style={{ color: 'grey' }}>{selectedData?.status}</span>
                      </Typography>
                    </Item>
                  </Grid>
                  <Grid item xs={6}>
                    <Item>
                      <Typography variant="subtitle2">
                        <strong>Test Charges: </strong><Label color="default">{selectedData?.test_charges}</Label>
                      </Typography>
                    </Item>
                  </Grid>
                 
                </Grid>
              </Box>
            </>
          )}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
