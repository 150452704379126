import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { cleanBorder, primary45 } from "utils/colors";
import { Snackbar, Alert } from "@mui/material";
import { useState } from "react";
import {useSnackbar} from '../snackbar'

const Drop = function ({ onLoaded }) {
  const { enqueueSnackbar } = useSnackbar(); 
 

  const styles = {
    container: {
      textAlign: "center",
      border: cleanBorder,
      padding: 100,
      color: primary45,
      fontSize: 18,
      fontWeight: 600,
      borderRadius: 4,
      userSelect: "none",
      outline: 0,
      cursor: "pointer",
      width: "100%"

    },
  };

  const onDrop = useCallback((acceptedFiles) => {
    const pdfFiles = acceptedFiles.filter((file) => file.type === "application/pdf");
    if (pdfFiles.length) {
      onLoaded(pdfFiles);
    } else {
      enqueueSnackbar("insert a valid pdf", { variant: 'error' })

    }
  }, [onLoaded]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": ['.pdf'],
    },
  });
 

  return (
    <Card >
      <Grid item xs={12} sm={12} md={12}>
        <Card display="flex" flexDirection="column" height="100%">
          <Card pt={1} mb={0.5}>
            <div {...getRootProps()} style={styles.container}>
              <input {...getInputProps()} />
              {isDragActive ? <p>Drop a PDF here</p> : <p>Drag a PDF here</p>}
            </div>
          </Card>
        </Card>
      </Grid>
    </Card>
  );
  // Drop.propTypes = {
  //   onLoaded: PropTypes.oneOf(["onLoaded"]),
  // };
}

export default Drop;
