import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Grid, Stack, Typography, Tooltip, Switch } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import { refreshSocket } from '../utils/socket';
import { useSnackbar } from '../components/snackbar';
import { getAllDevices } from 'api/device';
import { disableOrEnableDevice } from 'api/device';
import { darken, lighten, styled } from '@mui/material/styles';

//------------------------------------Data Grid-------------------------//

import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';
//import { DataGrid, GridToolbar } from '@mui/x-data-grid';

//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)((maxWidth) => ({
  maxWidth: `${maxWidth}px`, // percentage also works
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export default function ProcuredDevices() {
  const { enqueueSnackbar } = useSnackbar();
  const isSmall = useResponsive('', 'xs');
  const isMd = useResponsive('between', 'xs', 'md');
  const account =
    localStorage.getItem('TokenDetails') && JSON.parse(localStorage.getItem('TokenDetails'))?.authorization
      ? JSON.parse(localStorage.getItem('TokenDetails'))
      : {};
  const { searchStatus, searchStDate, searchEnDate, searchResult } = JSON.parse(
    window.sessionStorage.getItem('searchDetails') || '{}'
  );
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [data, setData] = useState([]);
  const [status, setstatus] = useState(searchStatus || 'requested');
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(searchStDate ? dayjs(searchStDate) : dayjs());
  const [interpretationStatusFilter, setInterpretationStatusFilter] = useState('all');
  const [socket, setSocket] = useState();


  useEffect(() => {
    fetchData();
  }, [status, startDate]);

  useEffect(() => {
    console.log(data);
    const storedSwitchStates = JSON.parse(sessionStorage.getItem('switchStates')) || {};
    const initialSwitchStates = {};
    data.forEach((row) => {
      initialSwitchStates[row.deviceId] = storedSwitchStates[row.deviceId] ?? true; // Set to true by default
    });
  }, [data]);

  const fetchData = async () => {
    try {
      const response = await getAllDevices({
        startDate: formatDate(startDate),
        status: status,
      });

      setLoading(false);
      console.log("After fetching the data from mongo",response);
      if (response && response?.success) {
        const filteredData = response?.data?.filter(
          (row) =>
            !row.interpretation_request_status ||
            row.interpretation_request_status === 'requested' ||
            row.interpretation_request_status === 'in progress' ||
            row.interpretation_request_status === 'completed'
        );
        setData(filteredData || []);

        const searchDetails = {
          searchStatus: status,
          searchStDate: startDate,
          searchResult: filteredData,
        };
        window.sessionStorage.setItem('searchDetails', JSON.stringify(searchDetails));
      } else {
        if (response) {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };


  const disableClick = async (target, id) => {
    try {
      const response = await disableOrEnableDevice({ device_id: id, status: target.checked ? 'enable' : 'disable' });
      // 
      enqueueSnackbar(response.message, { variant: response.success ? 'success' : 'error' });
      console.log('Response:', response);
    } catch (error) {
      console.error('Error disabling device:', error);
      enqueueSnackbar('Failed to disable device.', { variant: 'error' });
    }
  };
  

  const navigate = useNavigate();

  const TABLE_HEAD = [
    { field: 'deviceId', headerName: 'Device ID', width: 230, align: 'left', filterable: true },
    { field: 'deviceType', headerName: 'Device Type', width: 210, align: 'left', filterable: true },
    { field: 'date', headerName: 'Last Used Date', width: 210, align: 'left', filterable: true },
    { field: 'reportType', headerName: 'Report Type', width: 210, align: 'left', filterable: true },
    {
      field: 'moreInformation',
      headerName: 'More Information',
      width: 210,
      align: 'left',
      renderCell: (params) => (
        <Tooltip title="More Information" arrow>
          <LoadingButton
            variant="contained"
            onClick={() => {
              navigate(`${process.env.REACT_APP_HOMEPAGE}dashboard/devicereports/${params.row.deviceId}`);
            }}
          >
            Get Information
          </LoadingButton>
        </Tooltip>
      ),
    },
    {
      field: 'switch',
      headerName: 'Disable/Enable',
      width: 210,
      align: 'left',
      renderCell: (params) => (
        <Switch
          defaultChecked={params.row.isDisabled ? false : true}
          onChange={(event) => disableClick(event.target, params.row.deviceId)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      ),
    },
  ];

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return array.filter((_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const formatDate = (date) => {
    return date ? dayjs(date).format('DD MMM, YYYY') : null;
  };

 
  //--------------------------------------------//
  let filteredUsers;
  if (status === 'completed') {
    filteredUsers = applySortFilter(
      data.filter((row) => {
        if (interpretationStatusFilter === 'all') {
          return true;
        } else {
          return row?.interpretation_report_status === interpretationStatusFilter;
        }
      }),
      getComparator(order, orderBy),
      filterName
    );
  } else {
    filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);
  }

  //--------------------------------------------//

 

  const StyledDataGrid = styled(DataGrid)({
    '.super-app-theme--Open': {
      backgroundColor: '#FFEEBA',
    },
  });

  const rows = filteredUsers.map((row, index) => {
    return {
      id: `${row?.id}`,
      deviceId: row?.device_id,
      isDisabled: row?.isDisabled,
      deviceType: row?.device_type === 'Neo' ? 'Neo' : 'Pro',
      date: row?.report_timestamp ? dayjs(parseInt(row?.report_timestamp)).format('DD MMM, YYYY') : '',
      reportType: row?.report_type?.replace(/REPORT|_/g, ' '),
    };
  });

  return (
    <>
      <Box>
        <Grid
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginY: '10px',
            marginX: '20px',
            flexDirection: isSmall ? 'column-reverse' : 'row',
            gap: isSmall ? '1rem' : 0,
          }}
        ></Grid>
      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end', marginTop: '30px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={data ? { height: 600, width: '100%' } : { height: 100, ...width }}>
          <StyledDataGrid
            sx={{ m: 3, p: 1 }}
            rows={rows}
            filterMode="client"
            columns={TABLE_HEAD}
            disableVirtualization={true}
            getRowId={(row) => row?.id}
            localeText={{ noRowsLabel: 'No interpretation request found.' }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            pageSizeOptions={[5, 10, 15, 25]}
            slots={{
              toolbar: () => (
                <>
                  <GridToolbarContainer>
                    <GridToolbarQuickFilter />
                    <Stack spacing={3} flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end">
                      <GridToolbarColumnsButton />
                      <GridToolbarFilterButton />
                      <GridToolbarExport />
                    </Stack>
                  </GridToolbarContainer>
                </>
              ),
            }}
          />
        </Box>
      )}
    </>
  );
}
