import io from 'socket.io-client'
import { BASE_URL } from '../client-config'
let socket;


export async function refreshSocket() {
    let auth = JSON.parse(localStorage.getItem('TokenDetails'));
    console.log(BASE_URL)
    if (auth) {
        if (!socket) {
            socket = io(BASE_URL, { path: `${process.env.REACT_APP_SOCKET_PATH || '/socket.io'}` });
            console.log(socket)
            socket.on('connect', async () => {
                console.log("Connected with server.")
                await socket.emit('event', { 'authToken': auth?.authorization, "socketId": socket.id })
            });
            socket.on('disconnect', async () => {
                console.log("Disconnected with server.")
            });
            socket.on('error', async (error) => {
                console.log("Connection Error: ", error)
            });
        }
    } else {
        if (socket) socket.disconnect()
            
        socket = null
    }
    return socket;
}






refreshSocket();