import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Link, Avatar, IconButton, Popover, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {useAuthDispatch} from 'components/AuthStateProvider'

// mocks_
// import account from '../../../_mock/account';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Home',
  //   icon: 'eva:home-fill',
  // },
  // {
  //   label: 'Profile',
  //   icon: 'eva:person-fill',
  // },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { account, userLogOut } = useAuthDispatch();
  const navigate = useNavigate();

  if (!account?.authorization){
    return navigate(`${process.env.REACT_APP_HOMEPAGE}`);
  }

  let MENU_OPTIONS_LIST =[]
  
  const [open, setOpen] = useState(null);
 

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogOut = async (event) => {
    await userLogOut()
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account?.profilePic} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account?.displayName}
          </Typography>
          { account?.email ? <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account?.email}
          </Typography> :null }
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        { MENU_OPTIONS_LIST?.length ? <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS_LIST.map((option) => (
            <MenuItem key={option.label} component={Link} to={ option?.to || '/' } onClick={ (e) => { 
              handleClose(2);
              navigate(option?.to || '/'); 
            }}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> : null }

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogOut} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
