export function blobToURL(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}

export async function base64ToBlob (base64data, contentType = 'application/pdf') {
  const base64Response = await fetch(`data:${contentType};base64,${base64data}`);
  const fileBlob = await base64Response.blob();
  return fileBlob;
}

export function blobToDataURL(blob) {
  return URL.createObjectURL(blob);
}

export function viewBlobFile(blob) {
  const fileURL = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = fileURL;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadBlobFile(blob, fileName) {
  const fileURL = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadFromURI(uri, fileName) {
  const link = document.createElement('a');
  link.href = uri;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function fileToBlob(file) {
  return new Promise((resolve) => {
    const { content, size } = file;
    let chunks = [];
    let i = 0;
    const totalCount = Math.round(size / 250000);

    for (const chunk of content) {
      chunks.push(chunk);
      i++;
    }
    // eslint-disable-next-line no-undef
    return resolve(new Blob(chunks));
  });
};

export function copyToClipboard(text) {
  // Create a text area element
  const textArea = document.createElement('textarea');

  // Set the value of the text area to the text you want to copy
  textArea.value = text;

  // Append the text area to the document
  document.body.appendChild(textArea);

  // Select the text in the text area
  textArea.select();

  try {
    if (!navigator?.clipboard){
      // Execute the 'copy' command to copy the selected text to the clipboard
      document.execCommand('copy');
    } else{
        navigator.clipboard.writeText(textArea?.value);
    } 
  } catch (err) {
    console.error('Unable to copy text to clipboard:', err);
  } finally {
    // Remove the text area from the document
    document.body.removeChild(textArea);
  }
}
