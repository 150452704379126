import { useEffect, useState, useRef } from 'react';
import { useForm, register } from 'react-hook-form';
import { userLogin, userVerify } from 'api/login';
import Label from 'components/label/Label';
// @mui
import { styled } from '@mui/system';
import {
    Link, Stack, InputAdornment, TextField, Box, Grid, Typography, Dialog,
    DialogTitle,
    DialogActions, DialogContent
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import FormProvider from '../../../hooks/form/form-provider';
import Iconify from '../../../components/iconify';
import { useSnackbar } from '../../../components/snackbar';
import { countries } from '../../../hooks/form/countries';
import { RHFAutocomplete } from '../../../hooks/form/rhf-autocomplete';

// ----------------------------------------------------------------------
const DisabledLink = styled(Link)(({ theme, isDisabled }) => ({
    '&:disabled': {
        color: theme.palette.text.disabled,
        pointerEvents: 'none',
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.text.disabled,
        },
    }
}));

const padWithZeros = (value) => {
    return value < 10 ? `0${value}` : value;
};

export default function VerifyPhoneNo({ onVerify }) {
    /*----------------SnackBar Defination--------------------------------*/
    const { enqueueSnackbar } = useSnackbar();
    /*----------------SnackBar Defination--------------------------------*/

    const RESEND_TIME = 89;
    const [phonenumber, setPhonenumber] = useState();
    const [showOTPField, setShowOTPField] = useState(false);
    // const [isOtpSent, setisOtpSent] = useState(false);
    const [isNextLoadingButton, setIsNextLoadingButton] = useState(false)
    const [isLoginLoadingButton, setIsLoginLoadingButton] = useState(false)
    const [timer, setTimer] = useState(RESEND_TIME); // Initial timer value in seconds
    const [isTimerRunning, setIsTimerRunning] = useState(false);

    useEffect(() => {
        let countdown;
        if (isTimerRunning) {
            countdown = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 1) {
                        clearInterval(countdown);
                        setIsTimerRunning(false);
                        // Handle timer expiration, e.g., show a "Resend" button
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }
        return () => clearInterval(countdown); // Cleanup the interval on component unmount
    }, [isTimerRunning]);


    const defaultValues = {
        PhoneNo: '',
        CountryCode: '91',
        otp: ['', '', '', '', '', ''],
    };

    const methods = useForm({
        defaultValues,
        mode: "onChange",
    });

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    } = methods;

    const onSubmit = handleSubmit(async (formData) => {

        if (!showOTPField) {
            setPhonenumber(`+${formData.CountryCode}${formData.PhoneNo}`);
            setIsNextLoadingButton(true);
            const response = await userLogin({ phoneNumber: encodeURI(`+${formData.CountryCode}${formData.PhoneNo}`) })
            if (response.success === true && response.status == 200) {
                setShowOTPField(true)
                enqueueSnackbar(response.message, { variant: 'success' })
                /*--------------new Snack alert defination------------------*/
                setIsTimerRunning(true)
                setTimeout(() => document.getElementById('otp-0').focus(), 0)

            } else if (response.success === false) {
                setIsNextLoadingButton(false)
                /*--------------new Snack alert defination------------------*/
                enqueueSnackbar(response.message, { variant: 'error' })
                /*--------------new Snack alert defination------------------*/
            }
        }
        else {
            setIsLoginLoadingButton(true)
            const response = await userVerify({ phoneNumber: encodeURI(`+${formData.CountryCode}${formData.PhoneNo}`), otp: formData?.otp.join('') })
            setIsLoginLoadingButton(false)
            if (response.success) onVerify(response.success, phonenumber)
            else enqueueSnackbar(response.message || 'Invalid OTP. Please try again.', { variant: 'error' })
        }
    })
    const handleMobileInput = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    };
    const handleResendOTP = async (event) => {
        clearAllBoxes();
        const response = await userLogin({ phoneNumber: encodeURI(phonenumber) })
        if (response.success === true && response.status == 200) {
            enqueueSnackbar('OTP resent successfully.', { variant: 'success' })
            setTimer(RESEND_TIME);
            setIsTimerRunning(true);
        } else {
            enqueueSnackbar(response?.message || "Unable to resend OTP.", { variant: 'error' })
        }
    }

    const clearAllBoxes = () => {
        setValue('otp', ['', '', '', '', '', '']);
        document.getElementById('otp-0').focus();
    };



    const handleInput = (index) => (e) => {
        if (!/^\d*$/.test(e.target.value)) return e.target.value = e.target.value.replace(/[^0-9]/g, '');

        if (e.target.value.length === 1) {
            // Move to the next box
            if (index < 5) {
                document.getElementById(`otp-${index + 1}`).focus();
            }
        } else if (e.target.value.length === 0) {
            // Move back to the previous box on backspace
            if (index > 0) {
                document.getElementById(`otp-${index - 1}`).focus();
                // Select the value in the previous box
                document.getElementById(`otp-${index - 1}`).select();
            }
        }
        setTimeout(() => {
            const otpValid = dirtyFields?.otp?.filter((digit) => digit);
            if (otpValid?.length === 6) handleSubmit(onSubmit)();
        }, 0)
    };

    const handleKeyDown = (index) => (e) => {
        // Move to the previous box on backspace
        if (e.key === 'Backspace' && index > 0) {
            if (e.target.value === '') {
                setTimeout(() => {
                    document.getElementById(`otp-${index - 1}`).focus();
                    // Select the value in the previous box
                    document.getElementById(`otp-${index - 1}`).select();
                }, 0)
            }
        }
        // Move to the next box on right arrow key
        else if (e.key === 'ArrowRight' && index < 5) {
            document.getElementById(`otp-${index + 1}`).focus();
        }
        // Move back to the previous box on left arrow key
        else if (e.key === 'ArrowLeft' && index > 0) {
            document.getElementById(`otp-${index - 1}`).focus();
        }
    };



    return (
        <Box>
            <Box sx={{ display: 'flex', padding: '12px', justifyContent: 'space-between' }}>
                <Typography sx={{ fontWeight: '700' }}>
                    {!showOTPField ? `Add new user` : `Verify otp & add user`}
                </Typography>
            </Box>
            <FormProvider methods={methods} onSubmit={onSubmit} >
                {/* to show country and phonenumber field   */}


                {!showOTPField && (
                    <Stack spacing={2} sx={{ my: 2, mb: 4, padding: '12px' }} direction='row' justifyContent='center' alignItems='center'>


                        <RHFAutocomplete
                            name="CountryCode"
                            label="Country"
                            options={countries.map((country) => country.label + "/" + country.phone)}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option?.split('/')[0] === value || option?.split('/')[1] === value}
                            renderOption={(props, option) => {
                                const { code, label, phone } = (countries.filter(
                                    (country) => country.label === option?.split('/')[0]
                                )[0] || {});
                                if (!label) {
                                    return null;
                                }
                                return (
                                    <li {...props} key={label}>
                                        <Iconify
                                            key={label}
                                            icon={`circle-flags:${code.toLowerCase()}`}
                                            width={28}
                                            sx={{ mr: 1 }}
                                        />
                                        {label} ({code}) +{phone}
                                    </li>
                                );
                            }}
                            // Set a width for the dropdown
                            sx={{ flex: 0.8 }}
                        />

                        <TextField
                            label="Phone Number"
                            variant="outlined"
                            fullWidth

                            {...methods.register('PhoneNo', {
                                required: true,
                                pattern: {
                                    value: /^[0-9]{10}$/
                                },
                            })}
                            error={!!errors.PhoneNo}
                            helperText={errors.PhoneNo?.message}
                            onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                            // Set a width for the input
                            sx={{ flex: 1 }}
                        />


                    </Stack>
                )
                }






                {/* to show otp field once phonenumber is verified  */}
                {showOTPField && (
                    <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 2, px: '12px' }}>
                        <Typography sx={{ fontWeight: '500' }}>
                            OTP has been sent on <b>{phonenumber}</b>
                        </Typography>
                        <Grid container spacing={2} justifyContent="center" sx={{ pt: '12px' }}>
                            {defaultValues?.otp.map((digit, index) => (
                                <Grid item key={index} style={{ width: '16.5%' }}>
                                    <TextField
                                        key={index}
                                        {...register(`otp[${index}]`, {
                                            required: true,
                                            pattern: {
                                                value: /^[0-9]$/
                                            },
                                        })}
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{
                                            maxLength: 1,
                                            style: { textAlign: 'center' },
                                        }}
                                        error={!!errors.otp?.[index]}
                                        helperText={errors.otp?.[index]?.message}
                                        onInput={handleInput(index)}
                                        onKeyDown={handleKeyDown(index)}
                                        id={`otp-${index}`}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                    </Stack>)
                }
                {showOTPField ?
                    <Stack direction="column" alignItems="center" justifyContent="space-between" sx={{ my: 3, mx: 2 }}>
                        <Grid container>
                            <Grid item xs={8}>
                                Didn't receive code? <b>{isTimerRunning ? `${padWithZeros(Math.floor(timer / 60))}:${padWithZeros(timer % 60)}` : ''}</b>
                            </Grid>
                            <Grid item xs={4} textAlign="right">
                                <DisabledLink component="button" variant="subtitle2" underline="hover" onClick={handleResendOTP} disabled={isTimerRunning}>
                                    Resend OTP
                                </DisabledLink>
                            </Grid>
                        </Grid>
                    </Stack> : null
                }

                {!showOTPField ?
                    <Stack direction='row' spacing={4} sx={{ px: 2, py: 1, float: 'right' }}>
                        <LoadingButton onClick={() => onVerify()}>Cancel</LoadingButton>
                        <LoadingButton
                            loading={isNextLoadingButton}
                            fullWidth size="small"
                            variant="contained"
                            type="submit"
                            disabled={!isDirty || !isValid}
                            sx={{ width: '100px' }}>
                            Send OTP
                        </LoadingButton>
                    </Stack> :
                    <Stack direction='row' spacing={4} sx={{ px: 2, py: 1, float: 'right' }}>
                        <LoadingButton onClick={() => onVerify()}>Cancel</LoadingButton>
                        <LoadingButton
                            loading={isLoginLoadingButton}
                            fullWidth size="large"
                            type="submit"
                            variant="contained"
                            disabled={!isDirty || !isValid}
                            sx={{ width: '100px' }}>
                            Add
                        </LoadingButton>
                    </Stack>}
            </FormProvider>
        </Box>
    );
}

